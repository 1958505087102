import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { TranslationCacheService } from '../translation-cache/translation-cache.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private url = 'https://translation.googleapis.com/language/translate/v2?model=nmt&key=' + environment.firebaseConfig.apiKey;

  private currentLang = new BehaviorSubject<string>('en');
  currentLang$ = this.currentLang.asObservable();

  /**
 *
 * @param {HttpClient} _http
 * @param {TranslationCacheService} _translationCacheService
 *
 */
  constructor(
    private _http: HttpClient,
    private _translationCacheService: TranslationCacheService
  ) { }

  changeLanguage(lang: string): void {
    this.currentLang.next(lang);
  }

  translateText(text: string, targetLang: string): Observable<any> {
    const body = {
      q: text,
      target: targetLang,
      model: 'nmt',
      format: 'text'
    };

    return this._http.post<any>(this.url, body);
  }

  getTranslation(text: string): Observable<any> {
    const lang = this.currentLang.value;
    return this.translateText(text, lang);
  }

  getCurrentLanguage(): string {
    return this.currentLang.value;
  }

  getTranslationString(text: string, forceUpdate: boolean = false): Observable<string> {
    const lang = this.currentLang.value;
    if (lang == 'en') {
      return of(text);
    }
    if (forceUpdate) {
      return this.translateText(text, lang).pipe(
        map(res => res?.data?.translations?.[0]?.translatedText || text)
      );
    }
    return this._translationCacheService.getCachedOrFetchTranslation(lang, text, () =>
      this.translateText(text, lang).pipe(
        map(res => res?.data?.translations?.[0]?.translatedText || text)
      )
    );
  }
}