import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditsComponent } from './credits.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxPayPalModule } from 'ngx-paypal';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    NgxPayPalModule,
    FuseSharedModule,
    MatButtonModule,
    RouterModule
  ],
  declarations: [CreditsComponent]
})
export class CreditsModule { }
