import { ScanTypes } from '../main/models/scan-types';

export const scanTypesDef: ScanTypes[] = [
    {
        slug: 'discovery',
        title: 'Discovery',
        subtitle: 'Asset Discovery',
        shortDescription: 'Enumeration',
        description: 'Scanner oriented to the complete discovery of a specific asset. Discover entry points, screenshots of all web interfaces and modules found. Discovery of old or deprecated web modules.',
        cssElevationClass: 'mat-elevation-z4',
        price: {
            currencyText: '$',
            currency: 'USD',
            value: 60,
            period: 'per use',
            hasFreeVersion: true,
            cssClass: 'primary-300'
        },
        versions: [
            {
                id: 9,
                type: 'free',
                features: ['Screenshot Discovery', 'OSCP Command Sugester', 'Node Explorer', 'SSL Certificate Testing']
            },
            {
                id: 11,
                type: 'pro',
                features: ['Everything in Free, plus:', 'Full Port Scanner', 'Brute Force Attacks', 'Vulnerability Scanner']
            }
        ],
        activationText: 'Immediate activation!'
    },
    {
        slug: 'subdomain',
        title: 'Surface',
        subtitle: 'Vulnerability Discovery',
        shortDescription: 'Subdomains',
        description: 'Scanner oriented to the discovery of assets of a company. Discover leaked email addresses, subdomains and screenshots of the main components.',
        cssElevationClass: 'mat-elevation-z12',
        price: {
            currencyText: '$',
            currency: 'USD',
            value: 80,
            period: 'per use',
            hasFreeVersion: true,
            cssClass: 'primary'
        },
        versions: [
            {
                id: 10,
                type: 'free',
                features: ['Fast Port Scanner', 'Screenshot Discovery', 'Node Explorer', 'OSCP Command Sugester']
            },
            {
                id: 12,
                type: 'pro',
                features: ['Everything in Free, plus:', 'Full Subdomains', 'Full Port Scanner', 'Emails Enumeration']
            }
        ],
        activationText: 'Immediate activation!'
    },
    {
        slug: 'enterprise',
        title: 'Enterprise',
        subtitle: 'Business needs',
        cssElevationClass: 'mat-elevation-z4',
        price: {
            currencyText: 'from $',
            currency: 'USD',
            value: 300,
            period: 'monthly',
            hasFreeVersion: false,
            cssClass: 'primary-300'
        },
        versions: [
            {
                id: 13,
                type: 'business',
                features: ['Monthly or annual plans', 'Exclusive VPS', 'Easy Control Panel', 'Custom Tools', 'Internal Networks', 'Custom Report Templates', 'Customer support']
            }
        ],
        activationText: 'Activation in 72 hours.'
    }
];
