import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
    declarations: [
        TranslatePipe,
        TruncatePipe
    ],
    exports: [
        TranslatePipe,
        TruncatePipe
    ]
})
export class PipesModule { }