<div id="subscription" class="p-24" fxLayout="row wrap">

  <div class="subscription-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">
    <!-- PRICE TABLES -->
    <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="center center" [@animateStagger]="{value:'50'}">
      <div class="price-table style-3 mat-elevation-z4" fxLayout="column" [@animate]="{value:'*',params:{y:'100%'}}">
        <div *ngIf="currentPlan.planID == 1" class="badge warn">{{ 'FREE' | translate | async }}</div>
        <div class="package-type">
          <div class="title">{{currentPlan.title}}</div>
          <div class="subtitle">{{currentPlan.subtitle}}</div>
        </div>
        <div class="price" [ngClass]="currentPlan.cssPriceClass" fxLayout="row" fxLayoutAlign="center start">
          <div class="currency">{{currentPlan.currencyText | translate | async }}</div>
          <div fxLayout="row" fxLayoutAlign="center end">
            <div class="value">{{currentPlan.monthlyValue}}</div>
            <div class="period">{{ 'monthly' | translate | async }}</div>
          </div>
        </div>
        <div class="terms" fxLayout="column" fxLayoutAlign="start center">
          <ng-container *ngFor="let feature of currentPlan.features">
            <div class="term">{{feature | translate | async }}</div>
          </ng-container>
        </div>
        <button mat-raised-button class="cta-button" color="accent" routerLink="/plans">{{ 'UPGRADE' | translate | async }}</button>
        <!-- <div *ngIf="currentPlan.planID == 1" class="note">{{currentPlan.activationText}}</div> -->
        <div class="note">{{currentPlan.activationText | translate | async }}</div>
      </div>
    </div>
    <!-- / PRICE TABLES -->
  </div>
</div>