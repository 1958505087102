<div id="new-scan-dialog">
  <div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
      <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span *ngIf="type == 'confirmUse'" class="title dialog-title">{{ 'Scan Confirmation' | translate | async }}</span>
        <span *ngIf="type == 'enoughCredits'" class="title dialog-title">{{ 'Not Enough Credits' | translate | async }}</span>
        <button mat-icon-button mat-dialog-close aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content id="dialogContentScrollbar" class="mat-typography p-16 pb-0 m-0">
      <p *ngIf="type == 'confirmUse'">{{ 'Are you sure you want to spend' | translate | async }} {{scanPrice}} {{ 'credits to perform the next scan?' | translate | async }}</p> 
      <p *ngIf="type == 'enoughCredits'">{{ 'You do not have enough credits to perform the scan. You need' | translate | async }} {{differenceCredits}} {{ 'more credits to carry out the operation.' | translate | async }}</p>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="type != 'enoughCredits'" mat-button (click)="onYesClick()" cdkFocusInitial>{{ 'Ok' | translate | async }}</button>
      <button *ngIf="type == 'enoughCredits'" mat-button (click)="navigateToCredits()" cdkFocusInitial>{{ 'Purchase api credits now' | translate | async }}</button>
      <button *ngIf="type != 'enoughCredits'" mat-button (click)="onCancelClick()">{{ 'Cancel' | translate | async }}</button>
    </div>
  </div>
</div>