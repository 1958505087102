import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation/translation.service';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { TranslationCacheService } from '../services/translation-cache/translation-cache.service';

@Pipe({
  name: 'translate',
  pure: true // Permite que Angular optimice el Pipe sin perder la caché
})
export class TranslatePipe implements PipeTransform {
  constructor(
    private _translationService: TranslationService,
    private _cacheService: TranslationCacheService
  ) {}

  transform(value: string): Observable<string> | string {
    if (!value) return '';

    return this._translationService.currentLang$.pipe(
      switchMap(lang => {
        if (lang === 'en') return of(value);

        // Verificar si la traducción ya está en caché
        const cachedTranslation = this._cacheService.getTranslation(lang, value);
        if (cachedTranslation) {
          return cachedTranslation.asObservable();
        }

        // Si no está en caché, se realiza la petición y se almacena el resultado
        this._cacheService.setTranslation(lang, value, value);

        return this._translationService.getTranslation(value).pipe(
          switchMap(res => {
            const translatedText = res?.data?.translations[0]?.translatedText?.replace(/&quot;/g, '"') || value;
            this._cacheService.setTranslation(lang, value, translatedText);
            return of(translatedText);
          }),
          catchError(err => {
            console.error('Error in translation: ', err);
            return of(value);
          })
        );
      })
    );
  }
}
