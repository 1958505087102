import { Component, ElementRef, OnInit, ViewChild, ViewChildren, ViewEncapsulation, OnDestroy, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatRipple } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { MyScansService } from '../../services/my-scans/my-scans.service';
import { takeUntil } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FirebaseService } from '../../services/firebase/firebase.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-my-scans',
  templateUrl: './my-scans.component.html',
  styleUrls: ['./my-scans.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class MyScansComponent implements OnInit, OnDestroy {

  dataSource: FilesDataSource | null;
  displayedColumns = ['date', 'image', 'url', 'type', 'plan', 'current-task', 'incidents', 'status', 'actions'];
  rippleRef: any;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  @ViewChild('filter', { static: true })
  filter: ElementRef;

  @ViewChildren(MatRipple) ripples: QueryList<MatRipple>;

  @BlockUI() blockUI: NgBlockUI;

  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;

  mobile: boolean = environment.mobile;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {MyScansService} _myScansService
   * @param {Router} _router
   * @param {FirebaseService} _firebaseService
   * @param {MatDialog} _dialog
   * @param {FuseProgressBarService} _fuseProgressBarService
   * @param {MatSnackBar} _snackBar
   */
  constructor(
    public _myScansService: MyScansService,
    public _router: Router,
    private _firebaseService: FirebaseService,
    protected _dialog: MatDialog,
    private _fuseProgressBarService: FuseProgressBarService,
    private _snackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * On init
   */
  ngOnInit(): void {

    this.dataSource = new FilesDataSource(this._myScansService, this.paginator, this.sort);

    if (this._myScansService.scans.length !== 0) {

      // Stops blockUI from login/register
      this.blockUI.stop();

      fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(150),
          distinctUntilChanged()
        )
        .subscribe(() => {
          if (!this.dataSource) {
            return;
          }

          this.dataSource.filter = this.filter.nativeElement.value;
        });
    }
  }

  ngOnDestroy(): void {
    // Dissmis the snackbar onDestroy
    this._snackBar.dismiss();
    
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Navigates to home page
  // goToHome(): void {
  //   this._router.navigateByUrl('/web-scanner', { state: { fromMyScans: true } });
  // }

  // Converts date from timestamp to date format
  convertDate(timestamp): any {
    return new Date(timestamp).toLocaleDateString('en-us');
  }

  // Ripple activation
  rippleActivation(i: number): void {
    this.rippleRef = this.ripples.toArray()[i].launch({ persistent: true, animation: { enterDuration: 400, exitDuration: 0 } });
  }

  // Ripple deactivation
  rippleDeactivation(): void {
    if (this.rippleRef) {
      this.rippleRef.fadeOut();
    }
  }

  // Deletes the scan
  deleteScan(event, id: string, status: string): void {
    event.stopPropagation();
    this.confirmationDialog = this._dialog.open(ConfirmationDialogComponent);
    this.confirmationDialog.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result === true) {
          this._fuseProgressBarService.show();
          this._firebaseService.deleteScan(id, status);
        }
      });
  }

  // Navigates to report
  goToReport(event, scan: any): void {
    event.stopPropagation();
    console.log(scan);
    let reportToken = scan.report_token;
    if (reportToken) {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `/reports/${scan.id}/`;
      form.target = this.mobile ? '_self' : '_blank';
  
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'sess';
      input.value = reportToken;
      form.appendChild(input);
  
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    } else {
      console.error('Invalid report token.');
    }
  }
}

export class FilesDataSource extends DataSource<any>
{
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  /**
   * Constructor
   *
   * @param {MyScansService} _myScansService
   * @param {MatPaginator} _matPaginator
   * @param {MatSort} _matSort
   */
  constructor(
    private _myScansService: MyScansService,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();

    if (this._myScansService.scans.length !== 0) {
      this.filteredData = this._myScansService.scans;
    } else {
      this.filteredData = [];
    }

  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._myScansService.onScansChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges)
      .pipe(
        map(() => {
          let data = [];
          if (this._myScansService.scans.length !== 0) {
            data = this._myScansService.scans.slice();
          }

          data = this.filterData(data);

          this.filteredData = [...data];

          data = this.sortData(data);

          // Grab the page's slice of data.
          const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
          return data.splice(startIndex, this._matPaginator.pageSize);
        }
        ));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter data
   *
   * @param data
   * @returns {any}
   */
  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }

  /**
   * Sort data
   *
   * @param data
   * @returns {any[]}
   */
  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      let transformValueA: number;
      let transformValueB: number;
      if (a.status === 'running') {
        transformValueA = 3;
      } else if (a.status === 'finished') {
        transformValueA = 2;
      } else if (a.status === 'stopped') {
        transformValueA = 1;
      } else {
        transformValueA = 0;
      }
      if (b.status === 'running') {
        transformValueB = 3;
      } else if (b.status === 'finished') {
        transformValueB = 2;
      } else if (b.status === 'stopped') {
        transformValueB = 1;
      } else {
        transformValueB = 0;
      }

      switch (this._matSort.active) {
        case 'date':
          [propertyA, propertyB] = [+a.init_datetime, +b.init_datetime];
          break;
        case 'url':
          [propertyA, propertyB] = [a.url, b.url];
          break;
        case 'type':
          [propertyA, propertyB] = [a.type, b.type];
          break;
        case 'plan':
          [propertyA, propertyB] = [a.plan, b.plan];
          break;
        case 'current-task':
          [propertyA, propertyB] = [a.current_task, b.current_task];
          break;
        case 'status':
          [propertyA, propertyB] = [transformValueA, transformValueB];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      if (this._matSort.active === 'status') {
        return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
      } else {
        if (this._matSort.active === 'date') {
          const dateA = new Date(+a.init_datetime * 1000);
          const dateB = new Date(+b.init_datetime * 1000);
        
          return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        }
        // if (this._matSort.active === 'date') {
        //   const dateA = new Date(+a.init_datetime * 1000);
        //   const dateB = new Date(+b.init_datetime * 1000);
        //   if (dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth() && dateA.getDate() === dateB.getDate()) {
        //     return transformValueB - transformValueA;
        //   } else {
        //     return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        //   }
        // } else {
        //   if (valueA === valueB) {
        //     return transformValueB - transformValueA;
        //   } else {
        //     return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        //   }
        // }
      }
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void {
    // console.log('disconnected');
    this._myScansService.scansSubs.unsubscribe();
    // this._myScansService.onScansChanged.complete();
    // this._filterChange.complete();
    // this._filteredDataChange.complete();
  }
}
