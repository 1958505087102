import { NgModule } from '@angular/core';
import { HomeSearchModule } from './home-search/home-search.module';
import { Error404Module } from './404/error-404.module';
import { PricingModule } from './pricing/pricing.module';
import { FaqModule } from './faq/faq.module';
import { ScanSelectModule } from './scan-select/scan-select.module';
import { ScanModule } from './scan/scan.module';
import { LogsDialogModule } from './scan/logs-dialog/logs-dialog.module';
import { StopScanDialogModule } from './scan/stop-scan-dialog/stop-scan-dialog.module';
import { TermsDialogModule } from '../ui-components/terms-dialog/terms-dialog.module';
import { ConfirmationDialogModule } from '../guards/confirmation-dialog/confirmation-dialog.module';
import { ConfirmationDialogModuleMyScans } from './my-scans/confirmation-dialog/confirmation-dialog.module';
import { ErrorDialogModule } from '../services/auth/error-dialog/error-dialog.module';
import { LogoutDialogModule } from '../services/auth/logout-dialog/logout-dialog.module';
import { NoLoginDialogModule } from './scan-select/no-login-dialog/no-login-dialog.module';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { MailConfirmModule } from './mail-confirm/mail-confirm.module';
import { MyScansModule } from './my-scans/my-scans.module';
import { ScanErrorModule } from './scan-error/scan-error.module';
import { CreditsModule } from './credits/credits.module';
import { ApiClientsModule } from './api-clients/api-clients.module';
import { AboutUsModule } from './about-us/about-us.module';
import { ServicesModule } from './services/services.module';
import { NewScanModule } from './new-scan/new-scan.module';
import { NewScanDialogModule } from './new-scan/new-scan-dialog/new-scan-dialog.module';
import { ProfileModule } from './profile/profile.module';

@NgModule({
    imports: [
        HomeSearchModule,
        Error404Module,
        PricingModule,
        FaqModule,
        ScanSelectModule,
        ScanModule,
        LogsDialogModule,
        StopScanDialogModule,
        TermsDialogModule,
        ConfirmationDialogModule,
        ConfirmationDialogModuleMyScans,
        ErrorDialogModule,
        LogoutDialogModule,
        NoLoginDialogModule,
        LoginModule,
        RegisterModule,
        ForgotPasswordModule,
        MailConfirmModule,
        MyScansModule,
        ScanErrorModule,
        CreditsModule,
        ApiClientsModule,
        AboutUsModule,
        ServicesModule,
        NewScanModule,
        NewScanDialogModule,
        ProfileModule
    ]
})
export class PagesModule
{

}
