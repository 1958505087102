import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-no-login-dialog',
  templateUrl: './no-login-dialog.component.html',
  styleUrls: ['./no-login-dialog.component.scss']
})
export class NoLoginDialogComponent {

  /**
   * Constructor
   * @param {MatDialogRef<NoLoginDialogComponent>} _dialogRef
   */
  constructor(
    private _dialogRef: MatDialogRef<NoLoginDialogComponent>
  ) { }
}

