<div id="stop-scan-dialog">
  <div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
      <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Stop Scan</span>
        <button mat-icon-button mat-dialog-close aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content id="dialogContentScrollbar" class="mat-typography p-16 pb-0 m-0">
      <p>Are you sure you want to stop this scan? Once stoped, it cannot be resumed.</p>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button (click)="onYesClick()" cdkFocusInitial>Stop</button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </div>
  </div>
</div>