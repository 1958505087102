import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { FaqComponent } from 'app/main/pages/faq/faq.component';

@NgModule({
    declarations: [
        FaqComponent
    ],
    imports     : [
        MatExpansionModule,
        MatIconModule,

        FuseSharedModule
    ],
    providers   : []
})
export class FaqModule
{
}
