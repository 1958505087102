import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiClientsComponent } from './api-clients.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FuseSharedModule
  ],
  declarations: [ApiClientsComponent]
})
export class ApiClientsModule { }
