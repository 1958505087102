import { Component, OnDestroy, OnInit, ViewChild, Renderer2, ElementRef, Inject, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TermsDialogComponent } from '../../ui-components/terms-dialog/terms-dialog.component';
import { NoLoginDialogComponent } from './no-login-dialog/no-login-dialog.component';
import { FirebaseService } from '../../services/firebase/firebase.service';
import { OpenbashService } from '../../services/openbash/openbash.service';
import { AuthService } from '../../services/auth/auth.service';
import { UiFunctionsService } from '../../services/ui-functions/ui-functions.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Target } from '../../models/target';
import { Subject } from 'rxjs';
import { takeUntil, finalize, take } from 'rxjs/operators';
import { ScanTypes } from '../../models/scan-types';
import { ICreateOrderRequest } from 'ngx-paypal';
import { AppComponent } from '../../../app.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from '../../../../environments/environment';
import { TranslationService } from 'app/main/services/translation/translation.service';

@Component({
  selector: 'app-scan-select',
  templateUrl: './scan-select.component.html',
  styleUrls: ['./scan-select.component.scss']
})
export class ScanSelectComponent implements OnDestroy, OnInit {

  @ViewChild('searchinput') searchinputField;
  @BlockUI() blockUI: NgBlockUI;

  searchData: any = { url: null, selectedscanType: null };
  currentScanType: ScanTypes;
  target: Target = null;
  reCaptchaToken: string = null;
  payPalConfig: any = null;
  termsAccepted = false;
  dialogRef: MatDialogRef<TermsDialogComponent>;
  dialogRefNoLogin: MatDialogRef<NoLoginDialogComponent>;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   * @param {Router} _router
   * @param {MatSnackBar} _snackBar
   * @param {MatDialog} _dialog
   * @param {OpenbashService} _openbashService
   * @param {UiFunctionsService} _uiFunctionsService
   * @param {ReCaptchaService} _recaptchaService
   * @param {Renderer2} _renderer2
   * @param {ElementRef} _elementRef
   * @param {AppComponent} _appComponent
   * @param {FuseProgressBarService} _fuseProgressBarService    
   * @param {FirebaseService} _firebaseService
   * @param {AuthService} _authService
   * @param {TranslationService} _translationService
   */
  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    protected _dialog: MatDialog,
    private _openbashService: OpenbashService,
    private _uiFunctionsService: UiFunctionsService,
    private _recaptchaService: ReCaptchaV3Service,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    @Inject(forwardRef(() => AppComponent))
    private _appComponent: AppComponent,
    private _fuseProgressBarService: FuseProgressBarService,
    private _firebaseService: FirebaseService,
    private _authService: AuthService,
    private _translationService: TranslationService
    // private _paymentsService: PaymentsService
  ) {
    // State from home-search page
    if (history.state.hasOwnProperty('url') && history.state.hasOwnProperty('selectedscanType')) {
      this.searchData = history.state;
      this.setScanType(this.searchData.selectedscanType);

      // If the user is not logged in shows a message with unlogged scan information
      if (!this._authService.isLoggedIn) {
        this.openNoLoginDialog();
      }
    } else {
      // Redirect to home if no parameters found in route state
      this._router.navigate(['web-scanner']);
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Generates Recaptcha Token
    this.generatereCaptcha();
  }

  ngOnDestroy(): void {
    // Dissmis the snackbar onDestroy
    this._snackBar.dismiss();

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Sets the current Scan Type
  setScanType(scanType: string): void {
    this.currentScanType = this._uiFunctionsService.setScanType(scanType);
    this.resetPayPalConfig();
    // console.log(this.payPalConfig);
  }

  // Perform scan
  performScan(idScanner: number): void {
    if (!this.termsAccepted) {
      this._translationService.getTranslationString('You must accept our terms and conditions first.')
        .pipe(take(1))
        .subscribe((translatedString) => {
          this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
        });
      this._appComponent.scrollToElement('.terms-conditions');
    } else {
      this._snackBar.dismiss();
      this._fuseProgressBarService.show();
      this.blockUI.start();
      this.setScan(idScanner, 'free');
    }
  }

  // Navigation Back
  back(): void {
    this._router.navigateByUrl('/web-scanner', { state: {} });
  }

  // Resets paypal config
  resetPayPalConfig(): void {
    this.payPalConfig = this.initPaypalConfig(
      this.currentScanType.price.currency,
      this.currentScanType.price.value.toString(),
      this.currentScanType.title,
      this.target
    );
  }

  // Paypal config
  initPaypalConfig(scanCurrency: string, scanValue: string, scanName: string, target: Target): void {
    this.payPalConfig = {
      currency: scanCurrency,
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: scanCurrency,
              value: scanValue,
              breakdown: {
                item_total: {
                  currency_code: scanCurrency,
                  value: scanValue
                }
              }
            },
            items: [
              {
                name: scanName,
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: scanCurrency,
                  value: scanValue,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'pay',
        layout: 'horizontal',
        tagline: false,
        height: 36
      },
      onInit: (data, actions) => {

        // Disable the buttons
        actions.disable();

        // Listen for changes to the checkbox
        this._renderer.listen(this._elementRef.nativeElement.querySelector('#termsConditions-input'), 'change', (event) => {
          if (event.target.checked) {
            if (this.reCaptchaToken) {
              actions.enable();
            }
          } else {
            actions.disable();
          }
        });
      },
      onApprove: (data, actions) => {
        // console.log(
        //   'onApprove - transaction was approved, but not authorized',
        //   data,
        //   actions
        // );
        // actions.order.get().then(details => {
        //   console.log(
        //     'onApprove - you can get full order details inside onApprove: ',
        //     details
        //   );
        // });

        this._snackBar.dismiss();
        this._fuseProgressBarService.show();
        this.blockUI.start();

        let idScanner = this.currentScanType.versions.find(version => version.type === 'pro').id;

        this.setScan(idScanner, 'pro', data.orderID);

      },
      onClientAuthorization: data => {
        // console.log(
        //   'onClientAuthorization - you should probably inform your server about completed transaction at this point',
        //   data
        // );

        this._snackBar.dismiss();
        this._fuseProgressBarService.show();
        this.blockUI.start();

        this._openbashService.paymentApproved(data.id)
          .pipe(
            takeUntil(this._unsubscribeAll),
            finalize(() => {
              this._fuseProgressBarService.hide();
            })
          ).subscribe(
            (response) => {
              if (response.status === 1) {
                this.blockUI.stop();
                this._router.navigateByUrl('/scan/' + response.info.hash);
              } else {
                this.blockUI.stop();
                // Error snackbar
                this._translationService.getTranslationString(response.error)
                  .pipe(take(1))
                  .subscribe((translatedString) => {
                    this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                  });
              }
            },
            (error) => {
              this.blockUI.stop();
              console.error('Error caught in component.');
              // Error snackbar
              this._translationService.getTranslationString('There was an error in your request. Please try again.')
                .pipe(take(1))
                .subscribe((translatedString) => {
                  this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                });
              throw error;
            }
          );

      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
        // Error snackbar
        this.blockUI.stop();
        this.generatereCaptcha();
        this._translationService.getTranslationString('The payment was cancelled. Please try again.')
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
      },
      onError: err => {
        // console.log('OnError', err);
        // Error snackbar
        this.blockUI.stop();
        this.generatereCaptcha();
        this._translationService.getTranslationString('There was an error with the payment. Please review it and contact us or try again.')
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
        // Checks if the terms and conditions are checked
        if (!this.termsAccepted) {
          this._translationService.getTranslationString('You must accept our terms and conditions first.')
            .pipe(take(1))
            .subscribe((translatedString) => {
              this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
            });
          this._appComponent.scrollToElement('.terms-conditions');
        } else {
          if (!this.reCaptchaToken) {
            this._translationService.getTranslationString('There was an error with reCAPTCHA. Please reload the page.')
              .pipe(take(1))
              .subscribe((translatedString) => {
                this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
              });
          }
        }
      }
    };
    return this.payPalConfig;
  }

  // Generates reCaptchaToken
  generatereCaptcha(): void {
    // this._recaptchaService.reset();
    this._recaptchaService.execute('performScan')
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(
        (token) => {
          // Validates reCaptcha
          this.reCaptchaToken = token;
        },
        (error) => {
          console.error('Error caught in component.');
          // Error snackbar
          this._translationService.getTranslationString('There was an error with reCAPTCHA. Please try again.')
            .pipe(take(1))
            .subscribe((translatedString) => {
              this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
            });
          throw error;
        }
      );
  }

  // Sets the scan for the user
  setScan(idScanner: number, version: string, orderID: string = null): void {

    this.target = {
      url: this.searchData.url,
      idScanner: idScanner,
      token: this.reCaptchaToken,
      orderID: orderID
    };

    this._openbashService.addTarget(this.target)
      .pipe(
        takeUntil(this._unsubscribeAll),
        finalize(() => {
          this._fuseProgressBarService.hide();
        })
      ).subscribe(
        (response) => {
          if (response.status === 1) {
            if (version == 'free') {
              this._firebaseService.setUserScan(response.info.hash, idScanner, response.info.url, false)
                .then(() => this._router.navigateByUrl('/scan/' + response.info.hash));
            } else {
              this._firebaseService.setUserScan(response.info.hash, idScanner, response.info.url, false);
            }
          } else if (response.status === 2) {
            this.blockUI.stop();
            // Error snackbar
            this._translationService.getTranslationString('Please enter a valid URL.')
              .pipe(take(1))
              .subscribe((translatedString) => {
                this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
              });
          } else {
            this.blockUI.stop();
            // Error snackbar
            this._translationService.getTranslationString(response.error)
              .pipe(take(1))
              .subscribe((translatedString) => {
                this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
              });
          }
        },
        (error) => {
          this.blockUI.stop();
          console.error('Error caught in component.');
          // Error snackbar
          this._translationService.getTranslationString('There was an error in your request. Please try again.')
            .pipe(take(1))
            .subscribe((translatedString) => {
              this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
            });
          throw error;
        }
      );
  }

  // Prevents checkbox label from being clicked
  preventClick(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  // Opens the Terms dialog
  openTermsDialog(): void {
    // Dissmis current snackbar
    this._snackBar.dismiss();
    this.dialogRef = this._dialog.open(TermsDialogComponent, {});
  }

  // Opens the no login dialog
  openNoLoginDialog(): void {
    // Dissmis current snackbar
    this._snackBar.dismiss();
    this.dialogRefNoLogin = this._dialog.open(NoLoginDialogComponent, {});
  }
}
