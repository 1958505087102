<div id="scan" class="page-layout simple fullwidth">
  <!-- HEADER -->
  <div class="header" fxLayout="row" fxLayoutAlign="start start">
    <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>
      <div class="h1">{{currentScanType.title}} Scan</div>
      <div class="h3">
        {{ currentScanType.description | translate | async }}
      </div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content" fxLayout="column">
    <!-- SCAN CONTAINER -->
    <div class="scan-container" fxLayout="row wrap" fxLayoutAlign="center center">
      <div class="fuse-card">
        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayout.sm="column">
          <div fxLayout="column" fxFlex="30" fxLayout.xs="row" fxFlex.xs="100" fxLayout.sm="row" fxFlex.sm="100">
            <circle-progress [percent]='currentPercentage' [backgroundOpacity]="1" [radius]="60" [space]="-12"
              [maxPercent]="100" [outerStrokeGradient]="true" [outerStrokeWidth]="12" [outerStrokeColor]="'#bf0d57'"
              [outerStrokeGradientStopColor]="'#bf0d57'" [outerStrokeLinecap]="'square'" [innerStrokeColor]="'#F5F5F5'"
              [innerStrokeWidth]="12" [subtitleColor]="'#000000'" [subtitleFontSize]="'30'" [subtitleFontWeight]="'500'"
              [animateTitle]="false" [animationDuration]="1000" [showTitle]="false" [showUnits]="false"
              [showBackground]="false" [showZeroOuterStroke]="false" [lazy]="true" [subtitle]='formatPercentage()'
              [startFromZero]="false">
            </circle-progress>
          </div>
          <div fxLayout="column" fxFlex="70" class="data-row">
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <div fxLayout="column" fxFlex="75" class="status-url-column" fxFlex.xs="62" fxFlex.sm="62">
                <div class="h1">{{ title | translate | async }}</div>
                <div class="h1">{{url}}</div>
              </div>
              <div *ngIf="currentPercentage != '100' && !loading" fxLayout="column" fxFlex="22" fxFlex.xs="35" fxShow fxHide.xs="true" fxFlex.sm="35" fxShow
                fxHide.sm="true">
                <button mat-raised-button class="cta-button" color="accent"
                  (click)="stopScan()" [disabled]="currentScanStatus == 'stopped'">
                  {{ 'Stop scan' | translate | async }}
                </button>
              </div>
              <div *ngIf="currentPercentage == '100' && reportToken != '' && reportToken != null" fxLayout="column" fxFlex="25" fxFlex.xs="35" fxShow fxHide.xs="true" fxFlex.sm="35" fxShow
                fxHide.sm="true">
                <form #form method="post" target="{{mobile == true ? '_self' : '_blank'}}" action="/reports/{{hash}}/">
                  <input type="hidden" name="sess" [value]="reportToken" />
                  <button type="submit" (click)="form.submit()" mat-raised-button class="cta-button" color="accent">
                    {{ 'View report' | translate | async }}
                  </button>
                </form>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start" fxLayout.xs="column"
              fxLayoutAlign.xs="space-between unset" fxLayout.sm="column" fxLayoutAlign.sm="space-between unset">
              <div fxLayout="column" fxFlex="75" class="last-line-column">
                <div class="input" class="last-line-log">
                  <div [@animateText]="currentStateAnimation" (@animateText.done)="lastLineAnimationFinished($event)">
                    <span>{{lastLine}}</span>
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFlex="22" fxHide fxShow.xs="true" fxHide fxShow.sm="true"
                class="mobile-stop-scan">
                <button *ngIf="currentPercentage != '100' && !loading" mat-raised-button class="cta-button" color="accent"
                  (click)="stopScan()" [disabled]="currentScanStatus == 'stopped'">
                  {{ 'Stop scan' | translate | async }}
                </button>
                <form #form method="post" target="_self" action="/reports/{{hash}}/" *ngIf="currentPercentage == '100'">
                  <input type="hidden" name="sess" [value]="reportToken" />
                  <button type="submit" (click)="form.submit()" mat-raised-button class="cta-button" color="accent">
                    {{ 'View report' | translate | async }}
                  </button>
                </form>
                <!-- <a href="/reports/{{hash}}" target="{{mobile == true ? '_self' : '_blank'}}" *ngIf="currentPercentage == '100'" mat-raised-button class="cta-button" color="accent">
                  View report
                </a> -->
              </div>
              <div fxLayout="column" fxFlex="22">
                <button mat-stroked-button class="cta-button" color="accent" (click)="openLogDialog()"
                  [disabled]="currentLog == null">
                  {{ 'View log' | translate | async }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- / SCAN CONTAINER -->
      </div>
    </div>
    <!-- <div class="scan-extra-info" fxLayout="column" fxLayoutAlign="center center">
      <div class="p mb-20">The scan can take between X to Xhs. wait or leave us your e-mail and will send a link with
        the results of your scan.</div>
      <div fxLayout="row">
        <input fxflex placeholder="Enter your email" class="mr-5">
        <button fxflex mat-raised-button class="cta-button" color="accent">
          Send email
        </button>
      </div>
    </div> -->
    <div class="scan-extra-info" fxLayout="column" fxLayoutAlign="center center">
      <div class="p mb-20">{{'You can also check the progress of this scan following this link.' | translate | async }}</div>
      <div fxLayout="row">
        <input fxflex value="{{getHashURL()}}" readonly>
        <button fxflex mat-raised-button class="cta-button" color="accent" (click)="copyHashURL(getHashURL())">
          {{ 'Copy link' | translate | async }}
        </button>
      </div>
    </div>
  </div>
  <!-- / CONTENT -->