<div id="logs-dialog">
  <div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
      <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">{{ 'Scan Log' | translate | async }}</span>
        <button mat-icon-button mat-dialog-close aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content id="dialogContentScrollbar" fusePerfectScrollbar
      [fusePerfectScrollbarOptions]="{suppressScrollX: true, wheelSpeed: 2}" class="mat-typography p-16 pb-0 m-0">
      <p class="log-data">{{currentLog}}</p>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button cdkFocusInitial (click)="downloadLogFile(hash, currentLog)">
        {{ 'Download' | translate | async }}
      </button>
    </div>
  </div>
</div>