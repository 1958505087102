import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id      : 'web-scanner',
        title   : 'Home',
        type    : 'item',
        icon    : 'home',
        url  : '/web-scanner'
    },
    {
        id      : 'credits',
        title   : 'Purchase API Credits',
        type    : 'item',
        icon    : 'shopping_cart',
        url  : '/credits'
    },
    {
        id      : 'api-clients',
        title   : 'API Clients',
        type    : 'item',
        icon    : 'settings_ethernet',
        url  : '/api-clients'
    },
    {
        id      : 'my-scans',
        title   : 'My Scans',
        type    : 'item',
        icon    : 'view_timeline',
        url  : '/my-scans'
    },
    {
        id      : 'services',
        title   : 'Services',
        type    : 'item',
        icon    : 'info',
        url  : '/services'
    },
    {
        id      : 'plans',
        title   : 'Plans',
        type    : 'item',
        icon    : 'payments',
        url  : '/plans'
    },
    {
        id      : 'faq',
        title   : 'FAQ',
        type    : 'item',
        icon    : 'help',
        url  : '/faq'
    },
    {
        id      : 'about-us',
        title   : 'About Us',
        type    : 'item',
        icon    : 'group',
        url  : '/about-us'
    },
];
