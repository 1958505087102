import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-redirect',
  template: '',
})
export class HomeRedirectComponent implements OnInit {

  /**
     * Constructor
     *
     * @param {AuthService} _authService
     * @param {Router} _router
     */
  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    if (this._authService.isLoggedIn === true) {
      this._router.navigate(['/new-scan']);
    } else {
      this._router.navigate(['/web-scanner']);
    }
  }

}
