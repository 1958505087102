<div id="scan-select" class="page-layout simple fullwidth">
  <!-- HEADER -->
  <div class="header" fxLayout="row" fxLayoutAlign="start start">
    <mat-icon class="back" fxHide.gt-md (click)="back()">chevron_left</mat-icon>
    <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>
      <div class="h1">{{ 'Select Plan' | translate | async }}</div>
      <div class="h3">
        {{ 'Immediate activation. The difference between FREE and PRO is the number of requests, ports and third-party APIs
        used.' | translate | async }}
      </div>
      <!-- <div fxLayout="column" fxLayoutAlign="center"> -->
      <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
        fxLayoutAlign.gt-xs="space-between center">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'Select site' | translate | async }}</mat-label>
          <input disabled matInput placeholder="https://example.com" [(ngModel)]="searchData.url" #searchinput>
        </mat-form-field>
        <mat-form-field appearance="outline" class="category-selector">
          <mat-label>{{ 'Select scan type' | translate | async }}</mat-label>
          <mat-select [value]="currentScanType.slug" (selectionChange)="setScanType($event.value)">
            <mat-option [value]="'subdomain'">
              Subdomain
            </mat-option>
            <mat-option [value]="'discovery'">
              Discovery
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- </div> -->
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content" fxLayout="column">
    <!-- PRICE TABLES -->
    <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="center center">
      <div *ngFor="let version of currentScanType.versions; first as isFirst"
        class="price-table style-3 mat-elevation-z4" fxLayout="column">
        <div *ngIf="version.type == 'free'" class="badge warn">FREE TRIAL</div>
        <div class="package-type">
          <div class="title">{{currentScanType.title}}</div>
          <div class="subtitle">{{currentScanType.subtitle}}</div>
        </div>
        <div class="price" [ngClass]="{'primary-300' : isFirst, 'primary' : !isFirst }" fxLayout="row"
          fxLayoutAlign="center start">
          <div *ngIf="version.type == 'free'" fxLayout="row" fxLayoutAlign="center end">
            <div class="value">{{ 'Free' | translate | async }}</div>
          </div>
          <ng-container *ngIf="version.type != 'free'">
            <div class="currency">{{currentScanType.price.currencyText}}</div>
            <div fxLayout="row" fxLayoutAlign="center end">
              <div class="value">{{currentScanType.price.value}}</div>
              <div class="period">{{ currentScanType.price.period | translate | async }}</div>
            </div>
          </ng-container>
        </div>
        <div class="terms" fxLayout="column" fxLayoutAlign="start center">
          <div *ngFor="let feature of version.features" class="term">{{ feature | translate | async }}</div>
        </div>
        <button *ngIf="version.type != 'pro'" mat-raised-button class="cta-button" color="accent"
          (click)="performScan(version.id)">
          {{ 'PERFORM SCAN' | translate | async }}
        </button>
        <ng-container *ngIf="version.type == 'pro'">
          <ngx-paypal [config]="payPalConfig" enableStandardCardFields></ngx-paypal>
        </ng-container>
        <div class="note">{{ currentScanType.activationText | translate | async }}</div>
      </div>
    </div>
    <!-- / PRICE TABLES -->
    <!-- Terms and conditions -->
    <div class="terms-conditions" fxLayout="row wrap" fxLayoutAlign="center center">
      <mat-checkbox id="termsConditions" class="mt-24" [(ngModel)]="termsAccepted">
        <span (mousedown)="$event.stopPropagation()" (click)="preventClick($event)">
          {{ 'I accept the' | translate | async }} <a class="nav-link" (click)="openTermsDialog()">{{ 'Terms and Conditions' | translate | async }}</a>.
        </span>
      </mat-checkbox>
    </div>
  </div>
  <!-- / CONTENT -->
</div>