<div id="personal-information" class="p-24" fxLayout="row wrap">

  <div class="personal-information-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">

    <!-- Personal Information -->
    <div class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">Personal Information</div>
      </header>
      <div class="content">
        <form name="updatePersonalInformationForm" [formGroup]="updatePersonalInformationForm" novalidate
          (ngSubmit)="submitUpdatePersonalInformationForm()" fxLayout="column" fxFlex="100">
          <div class="personal-information-container" fxLayout="row" fxFlex="100">
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">Display Name</div>
                <mat-form-field color="accent" floatLabel="never">
                  <input matInput formControlName="displayName" placeholder="Please enter display name">
                  <mat-error *ngIf="updatePersonalInformationForm.get('displayName').hasError('required')">
                    Display Name is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="info-line">
                <div class="title">Email</div>
                <mat-form-field color="accent">
                  <input matInput value="{{user.email}}" disabled>
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">Telegram Nickname (Optional)</div>
                <mat-form-field color="accent" floatLabel="never">
                  <input matInput formControlName="telegramNick" placeholder="Please enter Telegram Nick">
                </mat-form-field>
              </div>
              <div class="info-line">
                <div class="title">Phone (Optional)</div>
                <mat-form-field color="accent" floatLabel="never">
                  <input matInput formControlName="phone" placeholder="Please enter phone">
                  <mat-error *ngIf="updatePersonalInformationForm.get('phone').hasError('pattern')">
                    No blank spaces, letters or special characters allowed
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="buttons-container">
            <button mat-raised-button color="accent" class="submit-button mr-16" aria-label="SAVE"
              [disabled]="updatePersonalInformationForm.invalid || !updatePersonalInformationForm.dirty">
              SAVE
            </button>
            <button type="button" mat-raised-button class="password-reset" color="accent" aria-label="RESET PASSWORD"
              *ngIf="checkProvider()" (click)="resetPassword()">
              RESET PASSWORD
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Fin Personal Information -->

    <!-- Report Information -->
    <div class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">Report Information</div>
      </header>
      <div class="content">
        <form name="updateReportInformationForm" [formGroup]="updateReportInformationForm" novalidate fxLayout="column"
          fxFlex="100">
          <div class="info-line">
            <div class="title">Company Logo</div>
            <mat-form-field color="accent">
              <button mat-icon-button matPrefix (click)="fileInput.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
              <input type="text" readonly matInput formControlName="reportImage" />
              <input type="file" hidden #fileInput accept="image/png, image/jpeg"
                (change)="onFileSelected(fileInput.files[0])" />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutGap="16px" class="colors-row">
            <div class="info-line" fxFlex="100">
              <div class="title">Primary Color</div>
              <mat-form-field color="accent" floatLabel="never">
                <input matInput [ngxMatColorPicker]="picker1" [formControl]="colorCtr1" formControlName="reportColor1"
                  [readonly]="true" placeholder="Please select color">
                <ngx-mat-color-toggle matPrefix [for]="picker1"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #picker1 touchUi="{{mobile == true ? true : false}}"></ngx-mat-color-picker>
              </mat-form-field>
            </div>
            <div class="info-line" fxFlex="100">
              <div class="title">Secondary Color</div>
              <mat-form-field color="accent" floatLabel="never">
                <input matInput [ngxMatColorPicker]="picker2" [formControl]="colorCtr2" formControlName="reportColor2"
                  [readonly]="true" placeholder="Please select color">
                <ngx-mat-color-toggle matPrefix [for]="picker2"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #picker2 touchUi="{{mobile == true ? true : false}}"></ngx-mat-color-picker>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutGap="16px" class="report-info-row">
            <div class="info-line mb-0" fxFlex="100">
              <div class="title">Default Authentication</div>
              <mat-form-field color="accent" floatLabel="never">
                <mat-label>Pleas select authentication type</mat-label>
                <mat-select formControlName="defaultAuth">
                  <mat-option value="private">Private</mat-option>
                  <mat-option value="public">Public</mat-option>
                  <mat-option value="password">Password</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="info-line" fxFlex="100">
              <mat-checkbox formControlName="newScanEnabled">
                New Scan Enabled
              </mat-checkbox>
            </div>
          </div>
          <div class="buttons-container">
            <button mat-raised-button color="accent" class="submit-button mr-16" aria-label="SAVE"
              [disabled]="updateReportInformationForm.invalid || !updateReportInformationForm.dirty"
              (click)="submitUpdateReportInformationForm(fileInput.files[0])">
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Fin Report Information -->

    <!-- Advanced Information -->
    <div class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">Advanced Information</div>
      </header>
      <div class="content">
        <form name="updateAdvancedInformationForm" [formGroup]="updateAdvancedInformationForm" novalidate
          (ngSubmit)="submitUpdateAdvancedInformationForm()" fxLayout="column" fxFlex="100">
          <div class="advanced-information-container" fxLayout="column" fxFlex="100">
            <div class="info-line">
              <div class="title">API Endpoint</div>
              <mat-form-field color="accent" floatLabel="never">
                <input matInput formControlName="apiEndpoint" placeholder="Please enter custom API endpoint">
                <mat-error *ngIf="updateAdvancedInformationForm.get('apiEndpoint').hasError('pattern')">
                  API endpoint must be in URL format (https://***.***)
                </mat-error>
              </mat-form-field>
            </div>
            <div class="info-line notifications" fxLayout="column">
              <div class="title">Notifications</div>
              <div fxLayout="row" fxLayoutGap="16px" class="first-row">
                <div fxLayout="column">
                  <mat-checkbox formControlName="emailNotifications">
                    Email Notifications
                  </mat-checkbox>
                  <mat-checkbox formControlName="telegramNotifications">
                    Telegram Notifications
                  </mat-checkbox>
                </div>
                <div fxLayout="column">
                  <mat-checkbox formControlName="whatsappNotifications">
                    Whatsapp Notifications
                  </mat-checkbox>
                  <mat-checkbox formControlName="apiNotifications">
                    API Notifications
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-container">
            <button mat-raised-button color="accent" class="submit-button mr-16" aria-label="SAVE"
              [disabled]="updateAdvancedInformationForm.invalid || !updateAdvancedInformationForm.dirty">
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Fin Advanced Information -->
  </div>

  <div class="personal-information-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">
    <!-- Account Information -->
    <div class="profile-box info-box account-info" fxLayout="column">
      <header class="accent">
        <div class="title">Account Information</div>
      </header>
      <div class="content">
        <div class="info-line">
          <div class="title">Api Key</div>
          <div class="info">{{user.apiToken}}<mat-icon (click)="copyApiKey(user.apiToken)">file_copy</mat-icon><mat-icon
              (click)="regenerateApiKey()">refresh</mat-icon></div>
        </div>
        <div class="info-line">
          <div class="title">Created At</div>
          <div class="info">{{user.createdAt | date:'MMM d, YYYY'}}</div>
        </div>
        <div class="info-line">
          <div class="title">Last Login</div>
          <div class="info">{{user.lastLoginAt | date:'h:mm:ss a - MMM d, YYYY'}}</div>
        </div>
        <div class="info-line">
          <div class="title">Auth Providers</div>
          <a class="info" target="{{mobile == true ? '_self' : '_blank'}}" *ngFor="let provider of user.providerData"
            href="{{provider.providerId == 'password' ? 'http://openbash.com' : 'http://'+provider.providerId}}">{{provider.providerId
            == 'password' ? 'openbash.com' : provider.providerId}}</a>
        </div>
        <div class="info-line">
          <div class="title">Total Scans</div>
          <div class="info">{{totalScans}}</div>
        </div>
      </div>
    </div>
    <!-- Fin Account Information -->


    <!-- Recent Scans -->
    <div class="profile-box groups" fxLayout="column">
      <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">Last Scans</div>
        <div class="more secondary-text">
          <a routerLink="/my-scans" target="{{mobile == true ? '_self' : '_blank'}}" class="view-all">View all...</a>
        </div>
      </header>
      <div class="content">
        <div *ngIf="myScans.length == 0">No scans have been performed. To perform scans and get results, please go to
          <a routerLink="/new-scan">NEW SCAN</a>.
        </div>
        <div class="group" *ngFor="let myScan of myScans.slice(0, 5)" fxLayout="row"
          fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <!-- <div class="logo"><img [src]="myScan.image ? myScan.image : '/assets/images/openbash-white.svg'"></div> -->
            <div class="logo"><img src="/assets/images/openbash-white.svg"></div>
            <div>
              <div class="name">{{myScan.url}}</div>
              <div class="category">{{myScan.type}}</div>
              <div class="members">{{+myScan.init_datetime*1000 | date:'MMM d'}}</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-icon *ngIf="myScan.status == 'finished'" class="active-icon green-600 s-16">check</mat-icon>
            <mat-icon *ngIf="myScan.status == 'stopped'" class="active-icon red-500 s-16">close</mat-icon>
            <mat-icon *ngIf="myScan.status != 'finished' && myScan.status != 'stopped'"
              class="active-icon amber-500 s-16">
              hourglass_empty
            </mat-icon>
            <mat-menu class="recent-scans-menu" #menu="matMenu" xPosition="before">
              <a class="mat-button" [routerLink]="'/scan/' + myScan.scan_uid + '/'"
                target="{{mobile == true ? '_self' : '_blank'}}" mat-menu-item>View</a>
              <button mat-menu-item (click)="deleteScan($event, myScan.scan_uid, myScan.status)">Delete</button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Recent Scans -->
  </div>
</div>