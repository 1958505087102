import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
// import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
// import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
// import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
// import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { navigation } from './navigation/navigation';
// import { locale as navigationEnglish } from 'app/navigation/i18n/en';
// import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
// import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcInitializeEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { BlockUiComponent } from './main/ui-components/blockUI/block-ui.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
    title = 'openbash-app';
    fuseConfig: any;
    navigation: any;
    blockTemplate: BlockUiComponent;
    // Cookie consent
    // private popupOpenSubscription: Subscription;
    // private popupCloseSubscription: Subscription;
    // private initializeSubscription: Subscription;
    // private statusChangeSubscription: Subscription;
    // private revokeChoiceSubscription: Subscription;
    // private noCookieLawSubscription: Subscription;

    // Scrollbar directive
    @ViewChild(FusePerfectScrollbarDirective)
    directiveScroll: FusePerfectScrollbarDirective;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        // private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        // private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        // private _translateService: TranslateService,
        private _platform: Platform,
        private ccService: NgcCookieConsentService
    ) {
        // Get default navigation
        this.navigation = navigation;

        // // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // // Add languages
        // this._translateService.addLangs(['en', 'tr']);

        // // Set the default language
        // this._translateService.setDefaultLang('en');

        // // Set the navigation translations
        // this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // // Use a language
        // this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         */

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         * setTimeout(() => {
         * this._translateService.setDefaultLang('en');
         * this._translateService.setDefaultLang('tr');
         * });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        // Cookie consent
        // subscribe to cookieconsent observables to react to main events
        // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
        //     () => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });

        // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        //     () => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });

        // this.initializeSubscription = this.ccService.initialize$.subscribe(
        //     (event: NgcInitializeEvent) => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });

        // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        //     (event: NgcStatusChangeEvent) => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });

        // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        //     () => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });

        // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        //     (event: NgcNoCookieLawEvent) => {
        //         // you can use this.ccService.getConfig() to do stuff...
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe to cookieconsent observables to prevent memory leaks
        // this.popupOpenSubscription.unsubscribe();
        // this.popupCloseSubscription.unsubscribe();
        // this.initializeSubscription.unsubscribe();
        // this.statusChangeSubscription.unsubscribe();
        // this.revokeChoiceSubscription.unsubscribe();
        // this.noCookieLawSubscription.unsubscribe();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * Toggle sidebar open
    //  *
    //  * @param key
    //  */
    // toggleSidebarOpen(key): void
    // {
    //     this._fuseSidebarService.getSidebar(key).toggleOpen();
    // }

    // /**
    // * Scroll to the top
    // *
    // * @param {number} speed
    // */
    // scrollToTop(speed?: number): void {
    //     speed = speed || 400;
    //     if (this.directiveScroll) {
    //         this.directiveScroll.update();

    //         setTimeout(() => {
    //             this.directiveScroll.scrollToTop(0, speed);
    //         });
    //     }
    // }

    // Scroll to bottom content
    // scrollToBottom(): void {
    //     setTimeout(() => {
    //         if (this.directiveScroll) {
    //             this.directiveScroll.update();

    //             setTimeout(() => {
    //                 this.directiveScroll.scrollToBottom(0, 400);
    //             });
    //         }
    //     });
    // }

    // Scrolls to element, recieves a query string, example: '.price-tables'
    scrollToElement(element: string, offset: number = 0): void {
        setTimeout(() => {
            if (this.directiveScroll) {
                this.directiveScroll.update();
    
                setTimeout(() => {
                    this.directiveScroll.scrollToElement(element, offset, 400);
                });
            }
        });
    }
}
