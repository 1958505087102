import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// import { OpenbashService } from '../openbash/openbash.service';
import { FirebaseService } from '../../services/firebase/firebase.service';
// import { takeUntil } from 'rxjs/operators';

@Injectable()
export class MyScansService implements Resolve<any> {
  scans: any[];
  onScansChanged: BehaviorSubject<any>;
  scansSubs: any;
  // public _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {OpenbashService} _openbashService
   */
  constructor(
    // private _openbashService: OpenbashService,
    private _firebaseService: FirebaseService
  ) {

    // Set the defaults
    this.onScansChanged = new BehaviorSubject({});
    // this._unsubscribeAll = new Subject();
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getScans()
      ]).then(
        () => {
          resolve(null);
        },
        reject
      );
    });
  }

  /**
   * Get scans
   *
   * @returns {Promise<any>}
   */
  getScans(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.scansSubs = this._firebaseService.getUserScans()
        // .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((myScans) => {
          // console.log(myScans);
          let scansArray = [];
          if (myScans.length !== 0) {
            this.scans = myScans;
            scansArray = myScans;
            this.onScansChanged.next(this.scans);
            resolve(scansArray);
          } else {
            this.scans = scansArray;
            this.onScansChanged.next(this.scans);
            resolve(scansArray);
          }
        }, reject);
    });
  }

    /**
   * Get Last scans
   *
   * @returns {Promise<any>}
   */
    getLastScans(): Promise<any> {
      return new Promise((resolve, reject) => {
        this._firebaseService.getUserLastScans()
          // .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((myScans) => {
            // console.log(myScans);
            let scansArray = [];
            if (myScans.length !== 0) {
              scansArray = myScans;
              resolve(scansArray);
            } else {
              this.scans = scansArray;
              resolve(scansArray);
            }
          }, reject);
      });
    }
}
