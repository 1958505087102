import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutUsComponent } from '../about-us/about-us.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FuseSharedModule
  ],
  declarations: [AboutUsComponent]
})
export class AboutUsModule { }
