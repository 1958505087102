<div id="terms-dialog">
  <div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
      <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">{{ 'Terms and Conditions of Service (Service Agreement)' | translate | async }}</span>
        <button mat-icon-button mat-dialog-close aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content id="dialogContentScrollbar" fusePerfectScrollbar
      [fusePerfectScrollbarOptions]="{ suppressScrollX: true, wheelSpeed: 2 }" class="mat-typography p-16 pb-0 m-0"
      cdk-focus-start>
      <p class="terms-data">
        <strong>{{ 'By ticking the “I agree on the Service Terms and Conditions” checkbox, the entity You represent,
          hereinafter referred to as “CUSTOMER”, whether an individual or a legal entity, means that you agree with' | translate | async }}
          <strong>OPENBASH.COM</strong>{{ ', hereinafter referred to as “PROVIDER”, on the terms and conditions of the Service, which are
          expressly mentioned in this invitation to request the services detailed herein, denominated "THE TERMS AND
          CONDITIONS OF THE SERVICE".' | translate | async }}</strong>
      </p>
      <ol>
        <li>
          <strong class="terms-title">{{ 'Definitions or Glossary' | translate | async }}</strong><br />
          <br />
          {{ '“Customer”, “Subscriber” or “End User” means any individual or legal entity or other statutory body involving
          a center of interests with legal personality that requests the use of the service to PROVIDER, in accordance
          with the meaning of the glossary herein.' | translate | async }}<br />
          <br />
          {{ '“Service” means the Service offered by' | translate | async }} <strong>OPENBASH.COM</strong>, {{ 'including all tools and functionalities that PROVIDER
          will provide access to through the web interface or the API (called from Customer\ss computer or from a
          third-party provider).' | translate | async }}<br />
          <br />
          {{ '“Service Infrastructure” means all the' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'systems that facilitate, provide or describe PROVIDER\ss
          “Service” nature and particular features.' | translate | async }}<br />
          <br />
          {{ '“Network Security Audits” are audits conducted by PROVIDER to ascertain the compliance of network devices with
          certain required and published security standards and that may expose security vulnerabilities and may
          include, but are not limited to, port scanning and port connections, as well as service evaluation by checking
          versions and responses to certain requests.' | translate | async }}<br />
          <br />
          {{ '“Web Security Audits” means the crawling of a website to perform testing of forms, application responses,
          and/or to confirm the existence of certain files.' | translate | async }}<br />
          <br />
          {{ '“Device” or “Devices” means computer hardware, network, storage, input/output or electronic control devices,
          or software installed on such devices.' | translate | async }}<br />
          <br />
          {{ '“Hostnames” means the name used to identify each Device.' | translate | async }}<br />
          <br />
          {{ '“URLs” is the address of a web site.' | translate | async }}<br />
          <br />
          {{ '“IPs” or “IP Addresses” refer to the address of a Device.' | translate | async }}<br />
          <br />
          {{ '“Support": means furnishing technical assistance and resources, provided on a best effort basis, via any
          telematic means chosen by PROVIDER.' | translate | async }}<br />
          <br />
          {{ '“Confidential Information” means any piece of information disclosed by one party to another that is defined by
          accepted basic standards as “non-public”, and which is protected and so defined in the Terms of this Service
          Agreement.' | translate | async }}<br />
          <br />
          {{ '“Intellectual Property Rights” covers all intellectual property rights recognized by the legislation of
          PROVIDER\ss location, including trademarks, design rights, copyrights, database rights, trade secrets and all
          rights of equivalent nature in the site of the legislation applicable to the jurisdiction and legislation that
          the parties agree, for the case that CUSTOMER is considered within the scope of consumer law, the same
          criterion will apply, if the applicable law provides sufficient guarantees.' | translate | async }}<br />
          {{ '“Computer Violation Complaint” refers to a report of abuse or imminent threat of violation of computer
          security policies.' | translate | async }}<br />
          <br />
          {{ '“Confidential Information” means: (a) information that PROVIDER, any member of its Group or any partner
          collects in the course of processing and handling your SERVICE orders, including information about the
          manufacture, model, operating system and other identifying information about your Device, the name of your
          internet service provider and your IP Address, and (b) information relating to your installation and use of
          the SERVICE. “Confidential Information” also means any information disclosed by one party to another that is
          defined by accepted basic standards as “non-public”, and which is protected and so defined in the Terms of
          this Service Agreement.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Ineligible Parties' | translate | async }}</strong><br />
          <br />
          {{ 'Provider will not be able to access the service of “PROVIDER”, in accordance with the law in force, in the
          cases described below:' | translate | async }}<br />
          <br />
          {{ '(a) If You or your employees have been convicted of any computer or internet-related crime, with final
          judgment, PROVIDER reserves the right, depending on the nature of the crime, to suspend the Service when it
          involves the protection of third party rights; or' | translate | async }}<br />
          <br />
          {{ '(b) if you are more than sixty (60) days in arrears in the payment established by any means, owed to PROVIDER' | translate | async }}
          (<strong>OPENBASH.COM</strong>); {{ 'or' | translate | async }}<br />
          <br />
          {{ '(c) if You are a competitor of' | translate | async }} <strong>OPENBASH.COM</strong>; {{ 'or' | translate | async }}<br />
          <br />
          {{ '(d) if the legislation where CUSTOMER is located, whether occasionally or permanently, considers PROVIDER\'s
          service to be unlawful; or' | translate | async }}<br />
          <br />
          {{ '(e) if PROVIDER has already previously been refused the Service.com by PROVIDER in the past' | translate | async }}<br />
          <br />
          {{ '(f) CUSTOMER agrees that, without invoking any cause whatsoever,' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'reserves the right to deny access
          to any potential subscriber of the Service in the event that' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'deems such refusal necessary, in
          accordance with the rules of good faith in the area in which PROVIDER operates and the accepted business
          practices according to the good judgment and uses in the specific area of the Service.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'CUSTOMER\ss Identity and Authority' | translate | async }}</strong><br />
          <br />
          {{ 'You agree to provide current, accurate and true information in all electronic or hardcopy registration forms
          submitted in connection with the Service, on a sworn statement basis.' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER assumes the obligation to be truthful about its enabling data and powers in the event that CUSTOMER
          acts on behalf of any person, company or other entity. By subscribing to the Service or accepting these Terms,
          all the personnel using the Service or accepting these Terms, certify that they are authorized to act on Your
          behalf, on a sworn statement basis, and are authorized by You as a representative of an individual, business,
          or other legal entity having contractual usage rights granted by an ISP or Web Host Provider, and they own or
          are licensed to use any or all IPs and the associated Devices to which You direct the Service to be performed.
          You agree to cooperate with' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'within reasonable diligence, to verify the identity and authority of
          the persons using the Service, and also verify that they act within the powers conferred by CUSTOMER.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Uses expressly prohibited by PROVIDER' | translate | async }}</strong><br />
          <br />
          {{ '(A) Scanning of Third Party Devices' | translate | async }}<br />
          <br />
          {{ 'Under no circumstances shall CUSTOMER use or direct the Service to interact with IPs or Devices for which
          CUSTOMER is not expressly authorized to do so. CUSTOMER must not use the Service in such a way as to create
          unreasonable load on IPs or Devices to which CUSTOMER has directed the Service to interact.' | translate | async }}<br />
          <br />
          {{ 'PROVIDER prohibits the use of any Service Infrastructure, directly or indirectly to initiate, propagate,
          participate, direct or attempt any attack, hack, or send bandwidth saturation, malicious or potentially
          damaging network messages to any Device, whether owned by' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'or by third parties.' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER agrees not to provide access to the Service in the following instances:' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER is prohibited from creating an account for someone who is not authorized to perform the role or view
          the information for which CUSTOMER has granted access; or' | translate | async }}<br />
          <br />
          {{ 'Creating an account for a party whose access has been restricted by PROVIDER (according to paragraph 2
          above).' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER shall immediately notify' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'of any unauthorized access from CUSTOMER\'s account or the
          accounts of authorized third parties, including the use of accounts, passwords or any other breach of
          security. CUSTOMER will not request another party\'s password for any reason. CUSTOMER will not access another
          person\'s account, or interrupt, interfere with, or limit the operation of the Services or another person\'s
          enjoyment of the Service.' | translate | async }}<br />
          <br />
          {{ 'It is expressly prohibited to reverse engineer, disassemble, decompile, translate, reconstruct, transform or
          extract any element that forms part of the SERVICE or any part thereof. As well as changing, modifying or
          altering the SERVICE in any manner whatsoever. It is forbidden to use any code, function, or device that is
          intended or designed automatically so that, upon the occurrence of any event or when you do or fail to do any
          action or at the direction or under the control of any individual or legal entity: (a) disrupt the operation
          of any software, service, device, property, network, or data; (b) cause any software, service, device,
          property, network, or data to be destroyed, altered, deleted, damaged, or otherwise disrupted or degraded; or
          (c) allow any individual or legal entity to access, take control of, or destroy, damage the operation of any
          portion of any software, service, device, or any computer virus of any kind whatsoever.' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER may not contain any unlawful, harmful, threatening, abusive, defamatory or inappropriate material; or
          harm other persons, or attempt to do so; or engage in conduct that is unlawful, harmful, threatening, abusive,
          harassing, tortious, defamatory, libelous, abusive, vulgar, obscene, invasive of another person\'s privacy,
          racially, ethnically, religiously or sexually discriminatory, or otherwise inappropriate; or test or compare,
          or disclose or publish the results of such tests and comparisons, with respect to any SERVICE, without the
          prior written consent of PROVIDER; or; override, circumvent, attempt to, or authorize or assist any third
          party to override or circumvent controls over the use of copies of any SERVICE;' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER agrees not to violate clauses 3 and 4 of PROVIDER\'s policy governing acceptable use of the
          SERVICE.' | translate | async }}<br />
          <br />
          {{ '(B) Reasonable usage of PROVIDER\ss service' | translate | async }} (<strong>OPENBASH.COM</strong>)<br />
          <br />
          {{ 'CUSTOMER must not, through the use of the Service or by any other means, create unreasonable load on the
          PROVIDER\ss Service Infrastructure.' | translate | async }}<br />
          <br />
          {{ '(C) Unlawful Activities' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER must not use the Service to perform any unlawful activity, including but not limited to, computer
          crimes according to the legislation of PROVIDER\ss location, as well as to the place where CUSTOMER uses the
          Service, either temporarily or permanently., transmission or storage of illegal content, or content or
          software in violation of intellectual property and copyright laws and criminal law that CUSTOMER claims to
          know.' | translate | async }}<br />
          <br />
          {{ '(D) Unauthorized Access' | translate | async }}<br />
          <br />
          {{ '(i) CUSTOMER must not access information on the Service Infrastructure for which CUSTOMER is not authorized,
          or which is not made available as determined by PROVIDER, publicly and in accordance with' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'Privacy
          Policy.' | translate | async }}<br />
          <br />
          {{ '(ii) Privacy Policy. If You obtain access to any information for which you are not authorized, by any
          mechanism, or by any other circumstance, you must inform previously and by the most efficient means to
          PROVIDER, such access and destroy all electronic or printed copies of such information. You must report
          incidents by e-mail with return receipt requested to:' | translate | async }} <a href="mailto:support@openbash.zendesk.com">support@openbash.zendesk.com</a><br />
          <br />
          {{ '(iii) CUSTOMER agrees not to provide access to' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'Service by:' | translate | async }}<br />
          {{ '(a) Using third parties to use Your account' | translate | async }};<br />
          {{ '(b) creating an account for someone who is not authorized to perform the role or view the information for
          which you have granted access;' | translate | async }}<br />
          {{ '(c) creating an account for a party whose access has been restricted by PROVIDER (according to paragraph 2
          above); or' | translate | async }}<br />
          {{ '(d) shall revoke access to those persons who are no longer authorized to access the Service for any
          reason.' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER shall immediately notify' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'of any unauthorized access from CUSTOMER\'s account or the
          accounts of authorized third parties, including the use of accounts, passwords or any other breach of
          security. CUSTOMER will not request another party\'s password for any reason. CUSTOMER will not access another
          person\'s account, or interrupt, interfere with, or limit the operation of the Services or another person\'s
          enjoyment of the Service.' | translate | async }}<br />
          <br />
          {{ 'Any breach of the above covenants will result in immediate termination of the Service and, if applicable,
          referral to the relevant administrative and/or judicial authorities.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Disclaimer of Responsibility and Guarantee' | translate | async }}</strong><br />
          <br />
          {{ 'PROVIDER supplies the Service “as is”. Consequently' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'does not warrant that the functions contained
          in the Service will meet CUSTOMER\ss requirements or expectations that are not foreseen by PROVIDER.' | translate | async }}
          <br />
          {{ 'PROVIDER has no responsibility, nor offers any guarantee, in the event that the Service is interrupted, or
          that errors may occur, given the nature of the object of the Service, or in the event that the defects cannot
          be corrected by PROVIDER, in accordance with the obligation of diligent behavior to which it commits
          itself.' | translate | async }}<br />
          <br />
          <strong>OPENBASH.COM</strong> {{ 'does not warrant or make any representations regarding the use or the results of the Service or
          any documentation provided to CUSTOMER. Any information in the nature of “advice” or suggestion, whether oral
          or in any form whatsoever, shall not create any liability in the nature of a warranty, except as expressly
          provided for in this Agreement. The parties expressly disclaim to file against PROVIDER or a third party, any
          warranty, express or implied, of merchantability, title, fitness for a particular purpose, or for the accuracy
          or completeness of responses, results, workmanship effort, lack of viruses, or negligence on the part of
          SUBSCRIBER.' | translate | async }}<br />
          <br />
          <strong>OPENBASH.COM</strong> {{ 'does not guarantee on the legitimacy by eviction, or intrusion of third parties, including the
          use of the Service in a peaceful way. Upon execution of this Agreement, CUSTOMER is responsible for the
          legality of the use of the Service in the place of CUSTOMER\ss habitual or temporary residence, and is the only
          responsible in case of infringement of the rules that regulate the Service in the place where it is used. To
          the extent permitted by applicable law, in no event shall' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'be liable for any special, incidental,
          indirect or consequential damages whatsoever (including, but not limited to, damages for loss of profits or
          confidential or other information, for business interruption, for personal injury, for loss or violation of
          privacy, for failure to meet any duty including of good faith or reasonable care, for negligence in the use of
          the Service, for any other pecuniary or other loss) arising out or in any way related to the use of or
          inability to use the Service, the provision of adequate support or in connection with any obligations
          specified in this Agreement, including breach of contract, particularly when' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'has not been advised
          about the possibility of such damages sufficiently in advance, by a reliable means and PROVIDER has acted to
          the extent that its technical role reasonably allows it to act as set forth in this agreement.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Copyright and Intellectual Property' | translate | async }}</strong><br />
          <br />
          {{ 'PROVIDER\ss Service is protected by copyright and all other intellectual property laws and treaties applicable
          to PROVIDER\ss or CUSTOMER\ss address, unless specifically excluded herein, unless Provider expressly excludes
          them in this document and no rules of public order are violated. PROVIDER does not assign any title,
          copyrights or other intellectual property rights. The structure, organization and computer code of any
          Software and Firmware are valuable trade secrets and constitute PROVIDER\ss “confidential information”.' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER does not assign any title, copyrights or other Intellectual Property Rights that correspond to
          PROVIDER of the Service. This Service Agreement does not convey to CUSTOMER a limited right of revocable use
          PURSUANT TO the Terms of this Service Agreement. No license or other right in or to the Service is being
          granted to CUSTOMER except for the rights Expressly outlined in this Service Agreement. Subscriber hereby
          agrees to abide by all applicable laws and international treaties and undertakes to inform' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'of any
          suspected breach of Intellectual Property Rights belonging to PROVIDER' | translate | async }} (<strong>OPENBASH.COM</strong>).
        </li>
        <li>
          <strong class="terms-title">{{ 'Privacy' | translate | async }}</strong><br />
          <br />
          {{ 'CUSTOMER agrees that PROVIDER' | translate | async }} (<strong>OPENBASH.COM</strong>) {{ 'may collect and use technical information that user provides or
          which is acquired by' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'as part of Your use of the Service.' | translate | async }}<br />
          <br />
          <strong>OPENBASH.COM</strong> {{ 'agrees not to use this information in a form that personally identifies You. Each party shall
          comply with its respective obligations under applicable data protection laws (“DPL”).' | translate | async }}<br />
          <br />
          {{ 'CUSTOMER authorizes' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'to refer to the name of CUSTOMER\ss corporation as one of its customers, both
          internally and in externally published media, unless CUSTOMER expressly objects in writing or any mechanism
          that ensures this restriction to PROVIDER. Any additional disclosure by' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'to CUSTOMER, either an
          individual or legal entity, shall be subject to its prior written consent.' | translate | async }}<br />
          <br />
          {{ 'In case' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'receives a Computer Violation Complaint, by any means, CUSTOMER expressly authorizes' | translate | async }}
          <strong>OPENBASH.COM</strong> {{ 'to inform the complainant, the CUSTOMER\ss email address who has used the service, who will be
          responsible for all legal purposes and for those derived from the analysis or “network audits” carried out
          through the Service as stated above.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Confidentiality' | translate | async }}</strong><br />
          <br />
          {{ 'Subscriber acknowledges that the information furnished by PROVIDER, as well any other data provided by
          PROVIDER are confidential as provided herein. Confidential information includes any and all information
          related to the Service and/or business of' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'that is treated as confidential or secret by' | translate | async }}
          <strong>OPENBASH.COM</strong> {{ '(i.e., that is the subject of processing or collection by' | translate | async }} <strong>OPENBASH.COM</strong>, {{ 'that should be reasonably
          kept undisclosed and should not be disseminated by any means). This includes expressly and without limitation
          of any kind:' | translate | async }}<br />
          <br />
          {{ 'The Service, or any and all other information which is disclosed by' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'to CUSTOMER orally,
          electronically, visually, or in a document or other tangible form which is either identified as or should be
          reasonably understood to be confidential and/or proprietary; and; and Any notes, extracts, analysis, or
          materials prepared by CUSTOMER or Subscriber which are copies of or derivative works of' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'the
          substance of which (the “Confidential Information”) can be inferred or otherwise understood from it.
          Confidential information includes the name, billing address (including zip code), e-mail address, telephone
          number, payment method, if applicable, credit card or account number opened with PROVIDER.' | translate | async }}<br />
          <br />
          {{ 'During delivery of Support, it will be necessary for confidential information to be exchanged between CUSTOMER
          (or SUBSCRIBER of the Service and PROVIDER). The Recipient may use such confidential information only for the
          purposes for which it was provided and may disclose it only to employees, contractors or partners, obligated
          to the Recipient under similar confidentiality restrictions and only for the purposes it was provided.' | translate | async }}<br />
          <br />
          {{ 'Confidential information shall not include information which the Recipient can establish by written evidence
          that:' | translate | async }}<br />
          <br />
          {{ 'Is already lawfully known to or independently developed by the Recipient without access to the confidential
          information;' | translate | async }}<br />
          <br />
          {{ 'Information disclosed in non-confidential published materials; is of public knowledge; or is legitimately
          obtained from any third party without any obligation of confidentiality.' | translate | async }}<br />
          <br />
          {{ 'SUBSCRIBER agrees not to disclose confidential information to any third party and will protect and treat all
          confidential information with the highest degree of care. Except as otherwise expressly provided in this
          Service Agreement, the Recipient will not use or make any copies of confidential information, in whole or in
          part, without the prior written authorization of the other party. The Recipient may disclose confidential
          information if required by statute, regulation or order of a court of competent jurisdiction provided that the
          Recipient provides the other party with prior notice, discloses only the minimum confidential information
          required to be disclosed, and cooperates with the other party in taking appropriate protective measures. These
          obligations shall continue to survive indefinitely following the termination of this Service Agreement
          concerning confidential information.' | translate | async }}<br />
          <br />
          {{ 'PROVIDER' | translate | async }} (<strong>OPENBASH.COM</strong>) {{ 'will not be obliged to respect the confidentiality in the case of termination of the
          Service Agreement due to breach of the Service Agreement conditions by CUSTOMER or SUBSCRIBER, insofar as such
          information is required for' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'to safeguard their own rights and legal interests.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Changes in Service' | translate | async }}</strong><br />
          <br />
          {{ 'CUSTOMER acknowledges and agrees that PROVIDER may—in its sole and absolute discretion, and within good faith
          parameters and the Service need—modify or remove the Service as reasonably necessary. Scans, verifications and
          authentications performed for the Service may also be modified, removed or updated by' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'at any time
          without notice.' | translate | async }}<br />
          <br />
          {{ 'You hereby authorize' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'to perform Web and/or Network Security Audits on any Devices, IPs, Hostnames
          and URLs specified by CUSTOMER. Web and/or Network Security Audits are performed with the assistance of' | translate | async }}
          <strong>OPENBASH.COM</strong> {{ 'employees or its outsourcing services or appointed contractors, and whenever' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'deems
          technically necessary to conduct additional probing and validation beyond the scope of' | translate | async }} <strong>OPENBASH.COM</strong>,  {{ 'as
          automated scanning system. In certain cases, the exploitation of a vulnerability and/or minimal extraction of
          data from the target server or web application may be conducted to support' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'security audit
          findings or to illustrate a vulnerability for the benefit of CUSTOMER.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Suspension of Accounts or Audits' | translate | async }}</strong><br />
          <br />
          <strong>OPENBASH.COM</strong> {{ 'reserves the right to suspend the Service being given at any time, should' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'in its
          sole
          discretion, deems such suspension necessary.' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'reserves the right not to commence or to suspend an
          audit at any stage, should it deem it necessary, in its sole discretion, to do so, and according to good faith
          standards.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Storage of Scan Data' | translate | async }}</strong><br />
          <br />
          <strong>OPENBASH.COM</strong> {{ 'is only bound to retain all stored data originating from users for a limited period of fifteen
          (15) days. This, with the exception that by means of a competent judicial authority according to the
          legislation of PROVIDERS\ss jurisdiction, storage is required in accordance with the laws and regulations, and
          the request of the judge or competent administrative body shall be reasonably justified.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Indemnity Clause' | translate | async }}</strong><br />
          <br />
          {{ 'You agree to indemnify, defend, and hold' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'harmless from any claim, loss, demand or damage,
          including reasonable attorneys\s fees and costs, asserted by any third party due to or arising out of
          CUSTOMER\ss breach of any provision of this Service Agreement, CUSTOMER\ss negligent or wrongful acts, and/or
          CUSTOMER\ss violation of any laws applicable to the services provided by' | translate | async }} <strong>OPENBASH.COM</strong>.
        </li>
        <li>
          <strong class="terms-title">{{ 'Term and Termination' | translate | async }}</strong><br />
          <br />
          {{ 'Without prejudice to any other rights,' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'may revoke this Service Agreement when:' | translate | async }}<br />
          <br />
          {{ 'SUBSCRIBER does not abide by the terms and conditions of this Service Agreement.' | translate | async }}<br />
          <br />
          {{ 'An Account reaches its expiry date, in which case the Service will be automatically terminated.' | translate | async }}<br />
          <br />
          {{ 'By revocation of the Subscription by PROVIDER. In this case CUSTOMER must immediately cease its use and desist
          from further use of the Service.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Entire Agreement' | translate | async }}</strong><br />
          <br />
          {{ 'These Terms and Conditions of Service Agreement (and support, if any) is the entire agreement between CUSTOMER
          and' | translate | async }} <strong>OPENBASH.COM</strong>, {{ 'and it supersedes all prior or contemporaneous oral or written communications, proposals,
          and representations regarding this Agreement, whether they have been made orally, in writing, or by any other
          telematic means. In the event that these communications exist, and they conflict with or their interpretation
          or scope differs from those set forth in this Agreement, conditions or policies expressed herein, those set
          forth in these Terms and Conditions of Service Agreement shall prevail.' | translate | async }}<br />
          <br />
          {{ 'The same criterion will be used if there are differences between these Terms and Conditions established by
          PROVIDER and any document, in any media whatsoever, in possession of CUSTOMER, whether set out in a purchase
          order or alternative agreement, which shall be void and shall have no effect against' | translate | async }} <strong>OPENBASH.COM</strong>.<br />
          <br />
          {{ 'If this Service Agreement is translated into a language other than English and there are conflicts between the
          translations, the English version shall prevail and control.' | translate | async }}<br />
          <br />
          {{ 'This Service Agreement may not be provided by SUBSCRIBER nor assigned to any third parties in any form; in
          case of breach of this prohibition, any subscription to third parties and any assignment, rights, benefits or
          legal or equivalent remedies of any nature under or by reason of this Service Agreement shall be null and
          void. In case of non-compliance, the “indemnity clause” proposed in this Agreement will be triggered, in
          addition to the pecuniary or non-pecuniary damages originated to the Service PROVIDER' | translate | async }} (<strong>OPENBASH.COM</strong>).<br />
          <br />
          {{ 'This Agreement may not be modified by SUBSCRIBER; but' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'may make modifications in order to provide
          the latest version available to optimize the Service, which will be available at:' | translate | async }} <a href="https://openbash.com">https://openbash.com</a> {{ 'and
          will be provided upon request.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Principle of the Agreement Validity - Severability' | translate | async }}</strong><br />
          <br />
          {{ 'If any part of any provision of this Service Agreement is found to be illegal, invalid or unenforceable, that
          provision shall apply with the minimum modification necessary to make it legal, valid and enforceable, and all
          other terms shall remain in force.' | translate | async }}<br />
          <br />
          {{ 'Paragraph headings are merely for convenience and shall have no legal effect for the purpose of interpreting
          their contents.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Governing Law' | translate | async }}</strong><br />
          <br />
          {{ 'This Service Agreement shall be governed by and construed under the laws of Chile, without regard to conflict
          of law provisions thereto. You submit to the jurisdiction of any court sitting in Chile, in any action or
          proceeding arising out of or relating to this Service Agreement and agree that all claims in respect of the
          action or proceeding may be heard and determined in any such court. There shall be no class action,
          arbitration or litigation under this Service Agreement.' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'may seek injunctive relief in any venue
          of its choice. You hereby submit to personal jurisdiction in such courts. The parties hereto specifically
          exclude the United Nations Convention on Contracts for the International Sale of Good and the Uniform Computer
          Information Transactions Act from this Service Agreement and any transaction between them that may be
          implemented in connection with this Service Agreement. The original of this Service Agreement has been written
          in English. The parties hereto waive any statute, law or regulation that might provide an alternative law or
          forum or have this Service Agreement written in any language other than English.' | translate | async }}
        </li>
        <li>
          <strong>OPENBASH.COM</strong> {{ 'may seek injunctive relief in any venue of its choice. You hereby submit to personal jurisdiction
          in such courts. The parties hereto specifically exclude the Uniform Computer Information Transactions Act from
          this Service Agreement and any transaction between them that may be implemented in connection with this
          Service Agreement. The original of this Service Agreement has been written in English. The parties hereto
          waive any statute, law, or regulation that might provide an alternative law or forum or to resolve disputes
          arising out of this Service Agreement written in any language other than English.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Equitable Relief' | translate | async }}</strong><br />
          <br />
          {{ 'It is agreed that because of the proprietary nature of the Service,' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'remedies at law for a breach
          by You of your obligations under this Service Agreement will be inadequate and that' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'shall, in the
          event of such breach, be entitled to, in addition to any other remedy available to it, equitable relief,
          including injunctive relief, without the posting of any bond and in addition to all other remedies provided
          under the applicable law.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'No Waiver or Delay' | translate | async }}</strong><br />
          <br />
          {{ 'The delay or failure of' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'to exercise any right provided in this agreement shall not be deemed a
          waiver of such right, with the exception of the statute of limitations provided for in the law applicable to
          this Agreement.' | translate | async }} <strong>OPENBASH.COM</strong> {{ 'anticipated resignation is not valid, which implies the duty to act immediately
          before any breach or delay, with the exception foreseen above.' | translate | async }}
        </li>
        <li>
          <strong class="terms-title">{{ 'Force Majeure' | translate | async }}</strong><br />
          <br />
          {{ 'Any party is not liable under this Service Agreement for non-performance caused by events or conditions beyond
          that party\ss control if that party takes all reasonable steps to overcome such events. This provision does not
          relieve you of your obligation to make all payments due.' | translate | async }}
        </li>
      </ol>
    </div>
  </div>
</div>