import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent {

  /**
   * Constructor
   * @param {MatDialogRef<LogoutDialogComponent>} _dialogRef
   */
  constructor(
    private _dialogRef: MatDialogRef<LogoutDialogComponent>
  ) { }

  // Dialog confirm action
  onYesClick(): void {
    this._dialogRef.close(true);
  }

  // Dialog cancel action
  onCancelClick(): void {
    this._dialogRef.close(false);
  }

}
