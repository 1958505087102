import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class PasswordConfirmationComponent {

  title: string;
  description: string;

  /**
   * Constructor
   * @param {MatDialogRef<PasswordConfirmationComponent>} _dialogRef
   */
  constructor(
    private _dialogRef: MatDialogRef<PasswordConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    // Brings data from new scan page
    this.title = data.title;
    this.description = data.description;
  }

  // Dialog confirm action
  onYesClick(): void {
    this._dialogRef.close(true);
  }

  // Dialog cancel action
  onCancelClick(): void {
    this._dialogRef.close(false);
  }
}
