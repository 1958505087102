import { Component, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { scanTypesDef } from '../../../scan-types/scan-types-def';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { FirebaseService } from 'app/main/services/firebase/firebase.service';
import { Subject } from 'rxjs';
import { takeUntil, finalize, take } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { UiFunctionsService } from '../../services/ui-functions/ui-functions.service';
import { environment } from '../../../../environments/environment';
import { ICreateOrderRequest } from 'ngx-paypal';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
// import { AppComponent } from '../../../app.component';
import { AuthService } from '../../services/auth/auth.service';
import { OpenbashService } from '../../services/openbash/openbash.service';
import { TranslationService } from 'app/main/services/translation/translation.service';

@Component({
    selector: 'pricing-style-3',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PricingStyle3Component implements OnInit, OnDestroy {

    //scanTypesDef: any;
    plans = [];
    loadedPlans = false;
    selectedPlan: number;
    payPalConfig: any = null;
    reCaptchaToken: string = null;
    newScanNavigationBypass = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    @BlockUI() blockUI: NgBlockUI;

    /**
     * Constructor
     * @param {Router} _router
     * @param {MatSnackBar} _snackBar
     * @param {FuseProgressBarService} _fuseProgressBarService
     * @param {FirebaseService} _firebaseService
     * @param {ReCaptchaService} _recaptchaService
     * @param {UiFunctionsService} _uiFunctionsService
     * @param {AppComponent} _appComponent
     * @param {AuthService} _authService
     * @param {OpenbashService} _openbashService
     * @param {TranslationService} _translationService
     */
    constructor(
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _fuseProgressBarService: FuseProgressBarService,
        private _firebaseService: FirebaseService,
        private _recaptchaService: ReCaptchaV3Service,
        private _uiFunctionsService: UiFunctionsService,
        private _authService: AuthService,
        private _openbashService: OpenbashService,
        private _translationService: TranslationService
        // private _appComponent: AppComponent
    ) {
        // Sets the scanTypesDef
        // this.scanTypesDef = scanTypesDef;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._router.onSameUrlNavigation = 'reload';
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;

        // Gets the plans from Firebase
        this.getPlans();
    }

    ngOnDestroy(): void {
        // Dissmis the snackbar onDestroy
        if (!this.newScanNavigationBypass) {
            this._snackBar.dismiss();
        }

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // Resets paypal config
    resetPayPalConfig(): void {
        this.payPalConfig = this.setPaypalConfig(this.selectedPlan);
    }

    // Select Plan
    selectPlan(planID): void {
        this.selectedPlan = planID;
        this.resetPayPalConfig();
        // setTimeout(() => {
        //   this._appComponent.scrollToElement('ngx-paypal', -400);
        // }, 400);
    }

    // Gets the plans from Firebase
    getPlans(): void {
        this._fuseProgressBarService.show();
        this._firebaseService.getPlansFirebase()
            .pipe(
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(plans => {
                this.plans = plans;
                this.loadedPlans = true;
                this.plans.sort((a, b) => {
                    return a.DisplayPosition - b.DisplayPosition;
                });
                // this.selectedPlan = 2;
                // this.resetPayPalConfig();
                this._fuseProgressBarService.hide();
            });
    }

    getPaypalConfig(configArray: Array<any>, planID): any {
        console.log(configArray.find(config => config.planID === planID).paypalConfig);
        return configArray.find(config => config.planID === planID).paypalConfig;
    }

    setPaypalConfig(planID): any {
        let currentPlan = this.plans.find(plan => plan.planID === planID);
        let monthlyValue = currentPlan.monthlyValue;
        let planName = currentPlan.title;
        return {
            currency: 'USD',
            clientId: environment.paypalClientId,
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                intent: 'CAPTURE',
                purchase_units: [
                    {
                        amount: {
                            currency_code: 'USD',
                            value: monthlyValue.toString(),
                            breakdown: {
                                item_total: {
                                    currency_code: 'USD',
                                    value: monthlyValue.toString()
                                }
                            }
                        },
                        items: [
                            {
                                name: 'OpenBash ' + planName + ' Plan Monthly Subscription.',
                                quantity: '1',
                                category: 'DIGITAL_GOODS',
                                unit_amount: {
                                    currency_code: 'USD',
                                    value: monthlyValue.toString(),
                                },
                            }
                        ]
                    }
                ]
            },
            advanced: {
                commit: 'true'
            },
            style: {
                label: 'pay',
                layout: 'horizontal',
                tagline: false,
                height: 36
            },
            onInit: (data, actions) => {
            },
            onApprove: (data, actions) => {
                // console.log(
                //   'onApprove - transaction was approved, but not authorized',
                //   data,
                //   actions
                // );
                // actions.order.get().then(details => {
                //   console.log(
                //     'onApprove - you can get full order details inside onApprove: ',
                //     details
                //   );
                // });

                this._snackBar.dismiss();
                this._fuseProgressBarService.show();
                this.blockUI.start();
                this.setSubscription(this._authService.userData.uid, this.selectedPlan, data.orderID);

            },
            onClientAuthorization: data => {
                // console.log(
                //   'onClientAuthorization - you should probably inform your server about completed transaction at this point',
                //   data
                // );

                this._snackBar.dismiss();

                this._openbashService.paymentApprovedSubscription(data.id)
                    .pipe(
                        takeUntil(this._unsubscribeAll),
                        finalize(() => {
                            this._fuseProgressBarService.hide();
                        })
                    ).subscribe(
                        (response) => {
                            if (response.status === 1) {
                                // console.log('subscription consumed');
                                // Updates Subscription
                                this._firebaseService.updateSubscription(this.selectedPlan)
                                    .then(() => {
                                        let currentPlan = this.plans.find(plan => plan.planID === planID);
                                        let credits = currentPlan.credits;
                                        let concurrence = currentPlan.concurrency;
                                        this._firebaseService.setUserSubscriptionCredits(credits, concurrence)
                                            .then(() => {
                                                this.blockUI.stop();
                                                // Success snackbar
                                                this._translationService.getTranslationString('Your subscription was successful. The credits were successfully added to your account.')
                                                    .pipe(take(1))
                                                    .subscribe((translatedString) => {
                                                        this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                                                    });

                                                // Navigates to new scan
                                                this.newScanNavigationBypass = true;
                                                this._router.navigate(['new-scan']);
                                            });
                                    });
                            } else {
                                this.blockUI.stop();
                                // Error snackbar
                                this._translationService.getTranslationString(response.error)
                                    .pipe(take(1))
                                    .subscribe((translatedString) => {
                                        this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                                    });
                            }
                        },
                        (error) => {
                            this.blockUI.stop();
                            console.error('Error caught in component.');
                            // Error snackbar
                            this._translationService.getTranslationString('There was an error in your request. Please try again. If your payment was charged please contact support.')
                                .pipe(take(1))
                                .subscribe((translatedString) => {
                                    this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                                });
                            throw error;
                        }
                    );

            },
            onCancel: (data, actions) => {
                // console.log('OnCancel', data, actions);
                // Error snackbar
                this.blockUI.stop();
                this._translationService.getTranslationString('The payment was cancelled. Please try again.')
                    .pipe(take(1))
                    .subscribe((translatedString) => {
                        this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                    });
            },
            onError: err => {
                // console.log('OnError', err);
                // Error snackbar
                this.blockUI.stop();
                this._translationService.getTranslationString('There was an error with the payment. Please review it and contact us or try again.')
                    .pipe(take(1))
                    .subscribe((translatedString) => {
                        this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                    });
            },
            onClick: (data, actions) => {
                // console.log('onClick', data, actions);
                // Generates Recaptcha Token
                this.generatereCaptcha();
                // Dismiss the snackbar
                this._snackBar.dismiss();
                // if (!this.reCaptchaToken) {
                //     this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error with reCAPTCHA. Please reload the page.', undefined);
                // }
            }
        };
    }

    // Goes home and focus the search input
    // selectScan(scanType: string): void {
    //     this._router.navigateByUrl('/web-scanner', { state: { selectedscanType: scanType } });
    // }

    // Sets the subscription order in the openbash database
    setSubscription(userID: string, planID: number, orderID: string): void {

        let subscriptionObject = {
            userID: userID,
            planID: planID,
            orderID: orderID,
            token: this.reCaptchaToken
        }

        this._openbashService.addSubscription(subscriptionObject)
            .pipe(
                takeUntil(this._unsubscribeAll)
            ).subscribe(
                (response) => {
                    if (response.status !== 1) {
                        // this.blockUI.stop();
                        // this._fuseProgressBarService.hide();
                        // // Error snackbar
                        this._translationService.getTranslationString(response.error)
                            .pipe(take(1))
                            .subscribe((translatedString) => {
                                this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                            });
                    }
                },
                (error) => {
                    // this.blockUI.stop();
                    // this._fuseProgressBarService.hide();
                    console.error('Error caught in component.');
                    // Error snackbar
                    this._translationService.getTranslationString('There was an error in your request. Please try again. If your payment was charged please contact support.')
                        .pipe(take(1))
                        .subscribe((translatedString) => {
                            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                        });
                    throw error;
                }
            );
    }

    // Generates reCaptchaToken
    generatereCaptcha(): void {
        // this._recaptchaService.reset();
        this._recaptchaService.execute('performScan')
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(
                (token) => {
                    // Validates reCaptcha
                    this.reCaptchaToken = token;
                },
                (error) => {
                    console.error('Error caught in component.');
                    // Error snackbar
                    this._translationService.getTranslationString('There was an error with reCAPTCHA. Please try again.')
                        .pipe(take(1))
                        .subscribe((translatedString) => {
                            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                        });
                    throw error;
                }
            );
    }

    // Checks with localstorage current subscription
    currentSubscription(planID: number): Boolean {
        const user = JSON.parse(localStorage.getItem('user')!);
        if (user.subscription[0].planId == planID && user.subscription[0].isPaid == true) {
            const today = new Date();
            if (planID == 1) {
                return true;
            }
            const nextRechargeDate = new Date(user.subscription[0].nextRechargeDate);
            // console.log(user.subscription[0].nextRechargeDate);
            if (today <= nextRechargeDate) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
