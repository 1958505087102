<div id="profile" class="page-layout simple tabbed">

  <!-- HEADER -->
  <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between end">

    <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">
      <div class="profile-container">
        <img class="profile-image avatar huge" [src]="user.photoURL ? user.photoURL : '/assets/images/profile.jpg'"
          [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
        <button mat-icon-button class="camera-button" (click)="fileInput.click()">
          <mat-icon>photo_camera</mat-icon>
        </button>
        <input type="file" hidden #fileInput accept="image/png, image/jpeg" (change)="onFileSelected(fileInput.files[0])" />
      </div>
      <div class="name" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        {{user.displayName}}
      </div>
    </div>

    <div class="actions" fxLayout="row" fxLayoutAlign="end center" [@animate]="{value:'*',params:{delay:'200ms'}}">
      <button mat-raised-button color="accent" aria-label="New Scan" routerLink="/new-scan">{{ 'NEW SCAN' | translate | async }}</button>
      <button mat-raised-button color="primary" aria-label="Purchase API Credits" routerLink="/credits">{{ 'PURCHASE API CREDITS' | translate | async }}</button>
    </div>

  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content">

    <mat-tab-group dynamicHeight="true" mat-align-tabs="center">

      <mat-tab label="{{ 'General Information' | translate | async }}">
        <profile-personal-information></profile-personal-information>
      </mat-tab>

      <mat-tab label="{{ 'Subscription' | translate | async }}">
        <profile-subscription></profile-subscription>
      </mat-tab>

      <!-- <mat-tab label="Orders">
        <profile-about></profile-about>
      </mat-tab>

      <mat-tab label="Advanced">
        <profile-photos-videos></profile-photos-videos>
      </mat-tab> -->

    </mat-tab-group>

  </div>
  <!-- / CONTENT -->

</div>