import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { NgxPayPalModule } from 'ngx-paypal';
import { ScanSelectComponent } from './scan-select.component';

@NgModule({
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    FuseSharedModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    NgxPayPalModule
  ],
  declarations: [ScanSelectComponent]
})
export class ScanSelectModule { }
