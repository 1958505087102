import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-api-clients',
  templateUrl: './api-clients.component.html',
  styleUrls: ['./api-clients.component.scss']
})
export class ApiClientsComponent implements OnInit {

  mobile: boolean = environment.mobile;
  
  constructor() { }

  ngOnInit() {
  }

}
