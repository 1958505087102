import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MatSnackBar } from '@angular/material/snack-bar';
import { scanTypesDef } from '../../../scan-types/scan-types-def';
import { ScanTypes } from '../../models/scan-types';

@Injectable({
  providedIn: 'root'
})
export class UiFunctionsService {

  /**
   * Constructor
   * @param {Platform} _platform
   */
  constructor(
    private _platform: Platform
  ) { }

  // Presents Snackbar with a message
  presentSnackbar(currentSnackbar: MatSnackBar, message: string, buttonLabel: string): any {
    // Snackbar enter url (check if validation is done here)
    let snackBarData = {};
    if (this._platform.ANDROID || this._platform.IOS) {
      snackBarData = { duration: 10000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'custom-snackbar-mobile' };
    } else {
      snackBarData = { duration: 20000, verticalPosition: 'top', horizontalPosition: 'right', panelClass: 'custom-snackbar' };
    }
    const snackbar = currentSnackbar.open(message, buttonLabel, snackBarData);
    return snackbar;
  }

  // Validates an URL/IP
  isValidUrl(urlString: string): boolean {
    const patternURL = new RegExp('(www.)?' + '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' + '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)');
    const patternIP = new RegExp('^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
    const validURL = patternURL.test(urlString);
    const validIP = patternIP.test(urlString);
    if (validURL || validIP) {
      return true;
    } else {
      return false;
    }
  }

  // Sets the current Scan Type by slug
  setScanType(scanType: string): ScanTypes {
    return scanTypesDef.find(scanTypeDef => scanTypeDef.slug === scanType);
  }

  // Sets the current Scan Type by ID
  setScanTypeByID(id: number): ScanTypes {
    return scanTypesDef.find(scanTypeDef => scanTypeDef.versions.some(version => version.id === id));
  }

  // Transforms HEX to RGB
  hexToRgb(hex: string): any {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }  
}
