import { Component, OnInit, ViewChild, OnDestroy, Inject, forwardRef } from '@angular/core';
import { FirebaseService } from 'app/main/services/firebase/firebase.service';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil, finalize, take } from 'rxjs/operators';
import { UiFunctionsService } from '../../services/ui-functions/ui-functions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../../app.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { OpenbashService } from '../../services/openbash/openbash.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NewScanDialogComponent } from './new-scan-dialog/new-scan-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Target } from '../../models/target';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TranslationService } from 'app/main/services/translation/translation.service';

@Component({
  selector: 'app-new-scan',
  templateUrl: './new-scan.component.html',
  styleUrls: ['./new-scan.component.scss'],
  animations: fuseAnimations
})

export class NewScanComponent implements OnInit, OnDestroy {

  @ViewChild('searchinput') searchinputField;
  @BlockUI() blockUI: NgBlockUI;
  searchInput: string = null;
  // Private
  private _unsubscribeAll: Subject<any>;

  mobile: boolean = environment.mobile;
  newScanDialog: MatDialogRef<NewScanDialogComponent>;
  // user: any = JSON.parse(localStorage.getItem('user')!);
  scanTypes = [];
  reCaptchaToken: string = null;

  /**
   * Constructor
   * @param {FirebaseService} _firebaseService
   * @param {UiFunctionsService} _uiFunctionsService
   * @param {MatSnackBar} _snackBar
   * @param {AppComponent} _appComponent
   * @param {FuseProgressBarService} _fuseProgressBarService
   * @param {OpenbashService} _openbashService
   * @param {ActivatedRoute} _activatedRoute
   * @param {Router} _router
   * @param {MatDialog} _dialog
   * @param {ReCaptchaService} _recaptchaService
   * @param {TranslationService} _translationService
   */
  constructor(
    private _firebaseService: FirebaseService,
    private _uiFunctionsService: UiFunctionsService,
    private _snackBar: MatSnackBar,
    @Inject(forwardRef(() => AppComponent))
    private _appComponent: AppComponent,
    private _fuseProgressBarService: FuseProgressBarService,
    private _openbashService: OpenbashService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    protected _dialog: MatDialog,
    private _recaptchaService: ReCaptchaV3Service,
    private _translationService: TranslationService
  ) {
    // Stops blockUI from login/register
    this.blockUI.stop();

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // State from URL
    if (this._activatedRoute.snapshot.paramMap.get('url')) {
      this.searchInput = this._activatedRoute.snapshot.paramMap.get('url');
    }
  }

  ngOnInit() {
    // Gets the scan types from Firebase
    this.getScanTypes();
  }

  ngOnDestroy(): void {
    this._snackBar.dismiss();

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Gets the scan types from Firebase
  getScanTypes(): void {
    this._fuseProgressBarService.show();
    this._firebaseService.getScanTypesFirebase()
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(scanTypes => {
        this.scanTypes = scanTypes;
        this.scanTypes.sort((a, b) => {
          return a.DisplayPosition - b.DisplayPosition;
        });
        this._fuseProgressBarService.hide();
      });
  }

  // Prevents Default and go to URL
  goToURL(event: any, url: string): void {
    event.stopPropagation();
    window.open(url, '_blank');
  }

  // Starts a scan
  startScan(scanType: any): void {
    // Genrates recaptcha
    this.generatereCaptcha();
    let user: any = JSON.parse(localStorage.getItem('user')!);

    const validSearchField = this.validateSearchField(this.searchInput, 400);
    // const scanPrice = 1000;
    const scanPrice = this.scanTypes.filter(function (response) { return response.ID === scanType.ID; })[0].PriceCredits;
    const userCredits = user.purchasedCredits + user.subscriptionCredits;

    if (validSearchField) {
      this._snackBar.dismiss();

      // Valida si le alcanzan los creditos para el scan
      if (userCredits < scanPrice) {
        // Muestra el modal de que no le alcanzan los creditos
        this.newScanDialog = this._dialog.open(NewScanDialogComponent, {
          data: {
            type: 'enoughCredits',
            scanPrice: scanPrice,
            differenceCredits: scanPrice - userCredits
          }
        });
        this.newScanDialog.afterClosed()
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe();
      } else {
        // Muestra el modal para confirmar el uso de creditos
        this.newScanDialog = this._dialog.open(NewScanDialogComponent, {
          data: {
            type: 'confirmUse',
            scanPrice: scanPrice,
            differenceCredits: 0
          }
        });
        this.newScanDialog.afterClosed()
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(result => {
            if (result === true) {
              this._snackBar.dismiss();
              this._fuseProgressBarService.show();
              this.blockUI.start();

              this.setScan(scanType, user.uid);
            }
          });
      }
    }
  }

  // Validates and focus the search field
  validateSearchField(searchField: string, focusSpeed: number): boolean {
    // console.log(this._uiFunctionsService.isValidUrl(searchField));
    if (searchField && this._uiFunctionsService.isValidUrl(searchField)) {
      return true;
    } else {
      // Focus search field
      this.scrollFocusSearchField(focusSpeed);

      // Snackbar enter url (check if validation is done here)
      this._translationService.getTranslationString('Please enter a valid URL.')
        .pipe(take(1))
        .subscribe((translatedString) => {
          this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
        });
    }
  }

  // Scrolls and focus search field
  scrollFocusSearchField(focusSpeed: number): void {
    this._appComponent.scrollToElement('.content');
    setTimeout(() => {
      this.searchinputField.nativeElement.focus();
    }, focusSpeed);
  }

  // Dismisses the snackbar
  dismissSnackbar(): void {
    this._snackBar.dismiss();
  }

  // Generates reCaptchaToken
  generatereCaptcha(): void {
    // this._recaptchaService.reset();
    this._recaptchaService.execute('performScan')
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(
        (token) => {
          // Validates reCaptcha
          this.reCaptchaToken = token;
        },
        (error) => {
          console.error('Error caught in component.');
          // Error snackbar
          this._translationService.getTranslationString('There was an error with reCAPTCHA. Please try again.')
            .pipe(take(1))
            .subscribe((translatedString) => {
              this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
            });
          throw error;
        }
      );
  }

  // Sets the scan with credits for the user
  setScan(scanType: any, userID: string): void {

    let target: Target = {
      url: this.searchInput,
      idScanner: scanType.ID,
      token: this.reCaptchaToken
    };

    this._openbashService.addTargetCredits(target, userID)
      .pipe(
        takeUntil(this._unsubscribeAll),
        finalize(() => {
          this._fuseProgressBarService.hide();
        })
      ).subscribe(
        (response) => {
          if (response.status === 1) {
            this._firebaseService.setUserScan(response.info.hash, scanType.ID, response.info.url, true, scanType.Name)
              .then(() => {
                this._router.navigateByUrl('/scan/' + response.info.hash);
              });
          } else if (response.status === 2) {
            this.blockUI.stop();
            // Error snackbar
            this._translationService.getTranslationString('Please enter a valid URL.')
              .pipe(take(1))
              .subscribe((translatedString) => {
                this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
              });
          } else {
            this.blockUI.stop();
            // Error snackbar
            this._translationService.getTranslationString(response.error)
              .pipe(take(1))
              .subscribe((translatedString) => {
                this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
              });
          }
        },
        (error) => {
          this.blockUI.stop();
          console.error('Error caught in component.');
          // Error snackbar
          this._translationService.getTranslationString('There was an error in your request. Please try again.')
            .pipe(take(1))
            .subscribe((translatedString) => {
              this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
            });
          throw error;
        }
      );
  }

  // Enter event in searchbar
  selectScan(): void {
    if (this.validateSearchField(this.searchInput, 400)) {
      this._translationService.getTranslationString('Please select a scan type.')
        .pipe(take(1))
        .subscribe((translatedString) => {
          this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
        });
    }
  }

}
