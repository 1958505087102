import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'error-404',
    templateUrl: './error-404.component.html',
    styleUrls: ['./error-404.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error404Component implements OnDestroy {
    /**
     * Constructor
     * @param {MatSnackBar} _snackBar
     */
    constructor(
        private _snackBar: MatSnackBar
    ) { }

    ngOnDestroy(): void {
        // Dissmis the snackbar onDestroy
        this._snackBar.dismiss();
    }
}
