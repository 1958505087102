import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { MailConfirmComponent } from './mail-confirm.component';

@NgModule({
    declarations: [
        MailConfirmComponent
    ],
    imports: [
        RouterModule,
        MatIconModule,
        FuseSharedModule
    ]
})
export class MailConfirmModule {
}
