import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalHttpInterceptorService, } from '../app/main/services/interceptor/interceptor.service';
import { GlobalErrorHandlerService } from '../app/main/services/gobal-error-handler/global-error-handler.service';
import { AppComponent } from './app.component';
import { PagesModule } from './main/pages/pages-module';
import { UIComponentsModule } from './main/ui-components/ui-components-module';
import { AppRoutingModule } from './app-routing.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from 'app/fuse-config';
import { FuseSharedModule } from '@fuse/shared.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUiComponent } from './main/ui-components/blockUI/block-ui.component';
import { environment } from './../environments/environment';

// Firebase
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
// import { provideAppCheck, initializeAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';

const cookieConfig: NgcCookieConsentConfig = {
  'cookie': {
    'domain': window.location.hostname
  },
  'position': 'bottom',
  'theme': 'classic',
  'palette': {
    'popup': {
      'background': '#1E2129',
      'text': '#ffffff',
      'link': '#ffffff'
    },
    'button': {
      'background': '#b90b4f',
      'text': '#ffffff',
      'border': 'transparent'
    }
  },
  'type': 'info',
  'content': {
    'message': 'This website uses cookies to ensure you get the best experience on our website.',
    'dismiss': 'Got it!',
    'deny': 'Refuse cookies',
    'link': '',
    'href': '',
    'policy': 'Cookie Policy'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    BlockUiComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    // Pages
    PagesModule,

    // Ui Components
    UIComponentsModule,

    RecaptchaV3Module,
    NgcCookieConsentModule.forRoot(cookieConfig),
    BlockUIModule.forRoot({ template: BlockUiComponent }),

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,

    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // provideAppCheck(() => {
    //   const provider = new ReCaptchaV3Provider(environment.reCaptchaKeyAppCheck);
    //   return initializeAppCheck(undefined, {
    //     provider,
    //     isTokenAutoRefreshEnabled: true
    //   });
    // }),
    AngularFireAuthModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig }
  ],
  bootstrap: [AppComponent],
  entryComponents: [BlockUiComponent]
})
export class AppModule { }
