<div id="my-scans" class="page-layout carded fullwidth inner-scroll">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- HEADER -->
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center">

      <!-- APP TITLE -->
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          view_timeline
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          My Scans
        </span>
      </div>
      <!-- / APP TITLE -->

      <!-- SEARCH -->
      <div class="search-wrapper mx-32 mx-md-0">
        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>search</mat-icon>
          <input #filter placeholder="Search for a scan">
        </div>
      </div>
      <!-- / SEARCH -->

      <!-- ADD BUTTON -->
      <button mat-raised-button class="add-scan-button fuse-white mt-24 mt-md-0" routerLink="/new-scan">
        <span>NEW SCAN</span>
      </button>
      <!-- / ADD BUTTON -->

    </div>
    <!-- / HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card mt-8 mt-md-0">

      <p class="no-scans-text" *ngIf="this._myScansService.scans.length === 0">No scans have been performed. To perform scans and get
        results,
        please go to <a routerLink="/new-scan">NEW SCAN</a>.</p>

      <div [hidden]="this._myScansService.scans.length === 0" fusePerfectScrollbar>
        <mat-table class="my-scans-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
          matSortActive="date" matSortDirection="desc" matSortDisableClear>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
            <mat-cell *matCellDef="let scan">
              <p class="text-truncate">{{+scan.init_datetime*1000 | date:'MMM d'}}</p>
            </mat-cell>
          </ng-container>

          <!-- Image Column -->
          <ng-container matColumnDef="image">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>Screenshot</mat-header-cell>
            <mat-cell *matCellDef="let scan" fxHide fxShow.gt-xs>
              <img class="scan-image" *ngIf="scan.image" [alt]="scan.url" [src]="scan.image" />
              <img *ngIf="!scan.image" [src]="'assets/images/scan-image-placeholder.png'">
            </mat-cell>
          </ng-container>

          <!-- URL Column -->
          <ng-container matColumnDef="url">
            <mat-header-cell *matHeaderCellDef mat-sort-header>URL</mat-header-cell>
            <mat-cell *matCellDef="let scan">
              <p class="text-truncate">{{scan.url}}</p>
            </mat-cell>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-md>Type</mat-header-cell>
            <mat-cell *matCellDef="let scan" fxHide fxShow.gt-md>
              <p class="text-truncate">{{scan.type | titlecase}}</p>
            </mat-cell>
          </ng-container>

          <!-- Plan Column -->
          <ng-container matColumnDef="plan">
            <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-md>Plan</mat-header-cell>
            <mat-cell *matCellDef="let scan" fxHide fxShow.gt-md>
              <p class="text-truncate">{{scan.plan | titlecase}}</p>
            </mat-cell>
          </ng-container>

          <!-- Current Task Column -->
          <ng-container matColumnDef="current-task">
            <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-sm>Current Task</mat-header-cell>
            <mat-cell *matCellDef="let scan" fxHide fxShow.gt-sm>
              <p class="text-truncate">{{scan.current_task | titlecase}}</p>
            </mat-cell>
          </ng-container>

          <!-- Incidents Column -->
          <ng-container matColumnDef="incidents">
            <mat-header-cell *matHeaderCellDef>Incidents</mat-header-cell>
            <mat-cell *matCellDef="let scan" class="incidents-cell">
              <p class="text-truncate"><span class="incidents-indicator text-truncate red-500">
                </span>Hi: {{scan.incidents.high}}</p>
              <p class="text-truncate"><span class="incidents-indicator text-truncate amber-500">
                </span>Med: {{scan.incidents.medium}}</p>
              <p class="text-truncate"><span class="incidents-indicator text-truncate green-600">
                </span>Low: {{scan.incidents.low}}</p>
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef fxHide mat-sort-header fxShow.gt-xs>Status</mat-header-cell>
            <mat-cell *matCellDef="let scan" fxHide fxShow.gt-xs>
              <mat-icon *ngIf="scan.status == 'finished'" class="active-icon green-600 s-16">check</mat-icon>
              <mat-icon *ngIf="scan.status == 'stopped'" class="active-icon red-500 s-16">close</mat-icon>
              <mat-icon *ngIf="scan.status != 'finished' && scan.status != 'stopped'"
                class="active-icon amber-500 s-16">
                hourglass_empty</mat-icon>
            </mat-cell>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let scan">
              <mat-icon class="active-icon red-500 s-16" (click)="deleteScan($event, scan.scan_uid, scan.status)">delete</mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

          <mat-row *matRowDef="let scan; columns: displayedColumns; let i = index" class="scan" matRipple
            matRippleDisabled="true" [routerLink]="'/scan/'+scan.scan_uid+'/'" #ripples (mouseenter)="rippleActivation(i)"
            (mouseleave)="rippleDeactivation()">
          </mat-row>

        </mat-table>

        <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="15"
          [pageSizeOptions]="[15, 30, 50, 100]">
        </mat-paginator>
      </div>

    </div>
    <!-- / CONTENT CARD -->
  </div>
  <!-- / CENTER -->
</div>