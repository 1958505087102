import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeSearchComponent } from './main/pages/home-search/home-search.component';
import { Error404Component } from './main/pages/404/error-404.component';
import { PricingStyle3Component } from './main/pages/pricing/pricing.component';
import { FaqComponent } from './main/pages/faq/faq.component';
// import { ScanSelectComponent } from './main/pages/scan-select/scan-select.component';
import { ScanComponent } from './main/pages/scan/scan.component';
import { ScanErrorComponent } from './main/pages/scan-error/scan-error.component';
import { ExitGuard } from './main/guards/exit-guard';
import { AuthGuard } from './main/guards/auth-guard';
import { AuthProfileGuard } from './main/guards/auth-profile-guard';
import { LoginComponent } from './main/pages/login/login.component';
import { RegisterComponent } from './main/pages/register/register.component';
import { ForgotPasswordComponent } from './main/pages/forgot-password/forgot-password.component';
import { MailConfirmComponent } from './main/pages/mail-confirm/mail-confirm.component';
import { MyScansComponent } from './main/pages/my-scans/my-scans.component';
import { MyScansService } from './main/services/my-scans/my-scans.service';
import { CreditsComponent } from './main/pages/credits/credits.component';
import { ApiClientsComponent } from './main/pages/api-clients/api-clients.component';
import { AboutUsComponent } from './main/pages/about-us/about-us.component';
import { ServicesComponent } from './main/pages/services/services.component';
import { NewScanComponent } from './main/pages/new-scan/new-scan.component';
import { HomeRedirectComponent } from './main/guards/home-redirect.component';
import { ProfileComponent } from './main/pages/profile/profile.component';

const routes: Routes = [
  { path: '', component: HomeRedirectComponent, pathMatch: 'full'},
  { path: 'web-scanner', component: HomeSearchComponent },
  { path: 'web-scanner/:url', component: HomeSearchComponent },
  { path: 'plans', component: PricingStyle3Component, canActivate: [AuthProfileGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard]},
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: 'mail-confirm', component: MailConfirmComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FaqComponent },
  { path: '404', component: Error404Component },
  // { path: 'scan-select', component: ScanSelectComponent },
  { path: 'scan/:hash', component: ScanComponent, canDeactivate: [ExitGuard] },
  { path: 'scan', component: ScanComponent, canDeactivate: [ExitGuard] },
  { path: 'scan-error/:hash', component: ScanErrorComponent },
  { path: 'my-scans', component: MyScansComponent, resolve  : { data: MyScansService }, canActivate: [AuthProfileGuard] },
  { path: 'credits', component: CreditsComponent, canActivate: [AuthProfileGuard] },
  { path: 'api-clients', component: ApiClientsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'new-scan', component: NewScanComponent, canActivate: [AuthProfileGuard] },
  { path: 'new-scan/:url', component: NewScanComponent, canActivate: [AuthProfileGuard] },
  { path: 'services', component: ServicesComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthProfileGuard] },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
