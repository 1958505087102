import { Component, Inject, OnDestroy, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fileSaver from 'file-saver';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-logs-dialog',
  templateUrl: './logs-dialog.component.html',
  styleUrls: ['./logs-dialog.component.scss']
})
export class LogsDialogComponent implements OnDestroy, AfterViewInit {

  @ViewChildren(FusePerfectScrollbarDirective)
  private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;
  private _dialogContentScrollbar: FusePerfectScrollbarDirective;

  hash: string = null;
  currentLog: string = null;
  logURL: SafeResourceUrl = null;

  /**
   * Constructor
   * @param {DomSanitizer} _sanitizer 
   */
  constructor(
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data,
    private _sanitizer: DomSanitizer
  ) {
    // Brings data from scan page
    this.currentLog = data.log;
    this.hash = data.hash;

    // Generates download link
    const blob = new Blob([this.currentLog], { type: 'application/octet-stream' });
    this.logURL = this._sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  ngOnDestroy(): void {
    // Dissmis the snackbar onDestroy
    this._snackBar.dismiss();
  }

  ngAfterViewInit(): void {
    this._dialogContentScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
      return directive.elementRef.nativeElement.id === 'dialogContentScrollbar';
    });
  }

  // Downloads the log file
  downloadLogFile(hash: string, log: string): void {
    const blob = new Blob([log], { type: 'text; charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    fileSaver.saveAs(blob, hash + '-logs.txt');
  }

  // Scroll to bottom content
  scrollToBottom(): void {
    setTimeout(() => {
      if (this._dialogContentScrollbar) {
        this._dialogContentScrollbar.update();

        setTimeout(() => {
          this._dialogContentScrollbar.scrollToBottom(0, 400);
        });
      }
    });
  }
}
