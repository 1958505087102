import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthService } from '../../../app/main/services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../../../app/main/services/auth/logout-dialog/logout-dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { UiFunctionsService } from '../../../app/main//services/ui-functions/ui-functions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'app/main/services/localstorage/localstorage-service.service';
// import { MyScansComponent } from 'app/main/pages/my-scans/my-scans.component';
import { TranslationService } from 'app/main/services/translation/translation.service';
import { languagesDef } from 'app/languages/languages-def';
import { Languages } from 'app/main/models/languages';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit, OnDestroy {

    // @ViewChild(MyScansComponent) myScansComponent: MyScansComponent;

    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    isLoggedIn: boolean;
    logoutDialog: MatDialogRef<LogoutDialogComponent>;
    user: any = JSON.parse(localStorage.getItem('user')!);
    languages: Languages[];
    selectedLanguage: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {AuthService} _authService
     * @param {Router} _router
     * @param {MatDialog} _dialog
     * @param {Clipboard} _clipboard
     * @param {UiFunctionsService} _uiFunctionsService
     * @param {MatSnackBar} _snackBar
     * @param {LocalStorageService} _localStorageService
     * @param {TranslationService} _translationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        public _authService: AuthService,
        public _router: Router,
        protected _dialog: MatDialog,
        private _clipboard: Clipboard,
        private _uiFunctionsService: UiFunctionsService,
        private _snackBar: MatSnackBar,
        private _localStorageService: LocalStorageService,
        private _translationService: TranslationService
    ) {
        // Event for login out all other windows in browser
        window.addEventListener('storage', this.storageEventListener.bind(this));

        // Loads Languages
        this.languages = languagesDef;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Checks if user is logged in
        this.isLoggedIn = this._authService.isLoggedIn;

        // Event listener for storage changes
        this._localStorageService.getStorageObservable()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((key: string) => {
                if (key === 'user') {
                    this.user = JSON.parse(localStorage.getItem(key)!);
                    this._changeDetectorRef.detectChanges();
                }
            });

        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved,
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Sets the selected language
        this.selectedLanguage = this.languages.find(language => language.id === this._translationService.getCurrentLanguage());
    }

    ngOnDestroy(): void {
        // Stops login listener
        this.stopListener();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // Handles storage updates for the user
    handleStorageUpdate(event: StorageEvent) {
        if (event.key === 'user') {
            this.user = JSON.parse(event.newValue);
        }
    }

    // Go To Home and focus new scan
    // goToHome(): void {
    //     this._router.navigate(['/web-scanner'], { state: { fromMyScans: true } });
    // }

    // Logs out the user
    logout(): void {
        this.logoutDialog = this._dialog.open(LogoutDialogComponent);
        this.logoutDialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                if (result === true) {
                    this._authService.logout().then(() => {
                        this.isLoggedIn = this._authService.isLoggedIn;
                        this._changeDetectorRef.detectChanges();
                        this._router.navigate(['login'], { state: { bypassFormGuard: true } });
                        // if (this._router.url === '/my-scans') {
                        //     this._router.navigate(['web-scanner']);
                        // }
                    });
                }
            });
    }

    // Event for login all other windows in browser
    storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            if (event?.key && event.key == 'login-event') {
                console.log('login');
                this.isLoggedIn = this._authService.isLoggedIn;
                this.user = JSON.parse(localStorage.getItem('user')!);
                this._changeDetectorRef.detectChanges();
            }
        }
    }

    // Handle active listeners when onDestroy 
    stopListener(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
    }

    // Copy api key to clipboard
    copyApiKey(apiKey: string): void {
        const pending = this._clipboard.beginCopy(apiKey);
        let remainingAttempts = 5;
        const attempt = () => {
            const result = pending.copy();
            if (!result && --remainingAttempts) {
                setTimeout(attempt);
            } else {
                // Success snackbar
                this._translationService.getTranslationString('API Key copied to clipboard.')
                    .pipe(take(1))
                    .subscribe((translatedString) => {
                        this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                    });
                pending.destroy();
            }
        };
        attempt();
    }

    // Changes Language
    changeLanguage(lang: any): void {
        this.selectedLanguage = lang;
        this._translationService.changeLanguage(lang.id);
    }
}
