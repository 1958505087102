import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-scan-dialog',
  templateUrl: './new-scan-dialog.component.html',
  styleUrls: ['./new-scan-dialog.component.scss']
})
export class NewScanDialogComponent {

  type: string;
  scanPrice: number;
  differenceCredits: number;

  /**
   * Constructor
   * @param {MatDialogRef<NewScanDialogComponent>} _dialogRef
   * @param {Router} _router
   */
  constructor(
    private _dialogRef: MatDialogRef<NewScanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _router: Router
  ) {
    // Brings data from new scan page
    this.type = data.type;
    this.scanPrice = data.scanPrice;
    this.differenceCredits = data.differenceCredits;
  }

  // Dialog confirm action
  onYesClick(): void {
    this._dialogRef.close(true);
  }

  // Dialog cancel action
  onCancelClick(): void {
    this._dialogRef.close(false);
  }

  // Navigate to credits
  navigateToCredits(): void {
    this._router.navigate(['/credits']);
    this._dialogRef.close();
  }
}
