import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '@fuse/shared.module';
import { ProfileComponent } from './profile.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { FuseMaterialColorPickerModule } from '@fuse/components';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';

import { ConfirmationDialogModuleProfile } from './confirmation-dialog/confirmation-dialog.module';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';

@NgModule({
    declarations: [
        ProfileComponent,
        PersonalInformationComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        FuseSharedModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        FuseMaterialColorPickerModule,
        ConfirmationDialogModuleProfile,
        NgxMatColorPickerModule,
        MatCheckboxModule,
        MatSelectModule
    ],
    providers: [
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
    ]
})
export class ProfileModule
{
}
