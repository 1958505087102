import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Target } from '../../models/target';
import { catchError } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpenbashService {

  rootURL = environment.rootURL;
  apiURL = this.rootURL + '/openbash-api/openbash-api.php';
  progressURL = this.rootURL + '/report/';

  /**
   * Constructor
   * @param {HttpClient} _http
   */
  constructor(
    private _http: HttpClient
  ) { }

  // Add new target to database via POST
  addTarget(target: Target): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'addTarget', target: target })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Add new target that consumes credits to database via POST
  addTargetCredits(target: Target, userID: string): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'addTargetCredits', target: target, uid: userID })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Add new credits order to database via POST
  addCredits(creditObject: any): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'addCredits', creditObject: creditObject })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Add new credits order to database via POST
  addSubscription(subscriptionObject: any): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'addSubscription', subscriptionObject: subscriptionObject })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Stops the current scan
  stopScan(hash: string): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'stopTask', hash: hash })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Validates URL in backend
  checkURL(url: string): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'checkURL', url: url })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Checks for payment aprobation, consumes the order, and changes the status of a scan
  paymentApproved(orderID: string): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'paymentApproved', orderID: orderID })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Checks for credit payment aprobation, consumes the order, and changes the status of a scan
  paymentApprovedCredits(orderID: string): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'paymentApprovedCredits', orderID: orderID })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Checks for payment aprobation, consumes the order, and changes the status of a scan
  paymentApprovedSubscription(orderID: string): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'paymentApprovedSubscription', orderID: orderID })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Regenerates Api Key
  regenerateApiKey(regenerateApiKeyObject: any): Observable<any> {
    return this._http.post<any>(this.apiURL, { request: 'regenerateApiKey', regenerateApiKeyObject: regenerateApiKeyObject })
      .pipe(
        catchError((err) => {
          console.log('Error caught in Openbash service');
          console.error(err);
          return throwError(err);
        })
      );
  }
}
