import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
// import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class ExitGuard implements CanDeactivate<ComponentCanDeactivate> {

  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;

  /**
   * Constructor
   * @param {MatDialog} _dialog
   * @param {Router} _router
   * @param {AuthService} _authService
   */
  constructor(
    protected _dialog: MatDialog,
    private _router: Router,
    private _authService: AuthService,
  ) { }

  canDeactivate(
    component: ComponentCanDeactivate,
    // currentRoute: ActivatedRouteSnapshot,
    // currentState: RouterStateSnapshot,
    // nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    //  console.log(component);
    //  console.log(currentRoute);
    //  console.log(currentState);
    //  console.log(nextState);

    // return component.canDeactivate ? component.canDeactivate() : true;

    if (this._router.getCurrentNavigation()?.extras?.state?.bypassFormGuard) {
      return true;
    } else {
      if (this._authService.isLoggedIn !== true) {
        this.confirmationDialog = this._dialog.open(ConfirmationDialogComponent);
        return this.confirmationDialog.afterClosed().pipe(
          map(result => result === true)
        );
      } else {
        return true;
      }
    }
  }
}
