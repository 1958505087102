import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { GoogleAuthProvider, TwitterAuthProvider, GithubAuthProvider, signInAnonymously, getAuth } from 'firebase/auth';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { Subject, from, Subscription } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { User } from '../../models/users';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FirebaseService } from '../firebase/firebase.service';
import { LocalStorageService } from 'app/main/services/localstorage/localstorage-service.service';
import { TranslationService } from '../translation/translation.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  @BlockUI() blockUI: NgBlockUI;

  errorDialog: MatDialogRef<ErrorDialogComponent>;
  userData: any;

  // Private
  // private _unsubscribeAll: Subject<any>;
  private _userSubscription: Subscription | null = null;
  private _authSubscription: any = null;

  /**
   * Constructor
   * @param {AngularFireAuth} _afAuth
   * @param {AngularFirestore} _afs
   * @param {Router} _router
   * @param {MatDialog} _dialog
   * @param {FuseProgressBarService} _fuseProgressBarService
   * @param {FirebaseService} _firebaseService
   * @param {LocalStorageService} _localStorageService
   * @param {LocalStorageService} _translationService
   */
  constructor(
    private _afAuth: AngularFireAuth,
    private _afs: AngularFirestore,
    private _router: Router,
    protected _dialog: MatDialog,
    private _fuseProgressBarService: FuseProgressBarService,
    private _firebaseService: FirebaseService,
    private _localStorageService: LocalStorageService,
    private _translationService: TranslationService
  ) {

    // Set the private defaults
    // this._unsubscribeAll = new Subject();

    // Saving user data in localstorage when logged in and setting up null when logged out
    this.setDataLocalStorage();

    // Event for logging out all other windows in browser
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy(): void {
    // Stops logout listener
    this.stopListener();
    // Unsubscribe from all subscriptions
    // this._unsubscribeAll.next();
    // this._unsubscribeAll.complete();
    this._authSubscription?.unsubscribe();
    this._userSubscription?.unsubscribe();
  }

  // Sign Up with email and password (Register Page)
  register(email: string, password: string): Promise<void> {
    this.blockUI.start();
    this._fuseProgressBarService.show();
    return this._afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((value) => {
        this.setUserData(value.user, 'userpassword').then(() => {
          this.sendVerificationMail(email);
        });
      })
      .catch((error) => {
        this.blockUI.stop();
        this._fuseProgressBarService.hide();
        if (error.code === 'auth/email-already-in-use') {
          this._translationService.getTranslationString('The email address is already in use by another account.')
            .pipe(take(1))
            .subscribe((translatedString) => {
              error.message = translatedString;
            });
        }
        this.errorDialog = this._dialog.open(ErrorDialogComponent, {
          data: { error: error },
        });
      });
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail(email: string): Promise<void> {
    return this._afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this._router.navigateByUrl('/mail-confirm', { state: { email: email } });
      });
  }

  // Login with password and email
  login(email: string, password: string): Promise<void> {
    this.blockUI.start();
    this._fuseProgressBarService.show();
    return this._afAuth.signInWithEmailAndPassword(email, password)
      .then(value => {
        this.setUserData(value.user, 'userpassword').then(() => {
          this._firebaseService.hasScansByID(value.user.uid).then(result => {
            this.setDataLocalStorage().then(() => {
              if (result) {
                window.localStorage.setItem('login-event', Math.random().toString());
                this._router.navigate(['my-scans']);
              } else {
                window.localStorage.setItem('login-event', Math.random().toString());
                this._router.navigate(['new-scan']);
              }
            });
          })
            .catch(error => {
              this.blockUI.stop();
              this._fuseProgressBarService.hide();
              if (error.code === 'permission-denied') {
                this._translationService.getTranslationString('You need to verify your email address before logging in.')
                  .pipe(take(1))
                  .subscribe((translatedString) => {
                    error.message = translatedString;
                  });
              }
              this.errorDialog = this._dialog.open(ErrorDialogComponent, {
                data: { error: error },
              });
            });
        });
      })
      .catch(error => {
        this.blockUI.stop();
        this._fuseProgressBarService.hide();
        if (error.code === 'auth/wrong-password') {
          error.message = 'The password is invalid or the user does not have a password.';
        } else if (error.code === 'auth/user-not-found') {
          error.message = 'There is no user record corresponding to this identifier. The user may have been deleted.';
        } else if (error.code === 'auth/user-disabled') {
          error.message = 'The user is disabled. Please contact support.';
        } else if (error.code === 'auth/invalid-login-credentials') {
          error.message = 'Incorrect email, password or you need to verify your email address before logging in.';
        }
        this._translationService.getTranslationString(error.message)
          .pipe(take(1))
          .subscribe((translatedString) => {
            error.message = translatedString;
          });
        this.errorDialog = this._dialog.open(ErrorDialogComponent, {
          data: { error: error },
        });
      });
  }

  // Google Login
  googleLogin(): Promise<void> {
    const provider = new GoogleAuthProvider();
    return this.oAuthLogin(provider)
      .then(value => {
        this.blockUI.start();
        this._fuseProgressBarService.show();
        this.setUserData(value.user).then(() => {
          this._firebaseService.hasScansByID(value.user.uid).then(result => {
            if (result) {
              window.localStorage.setItem('login-event', Math.random().toString());
              this._router.navigate(['my-scans']);
            } else {
              window.localStorage.setItem('login-event', Math.random().toString());
              this._router.navigate(['new-scan']);
            }
          });
        });
      })
      .catch(error => {
        if (error.code !== 'auth/cancelled-popup-request' && error.code !== 'auth/popup-closed-by-user') {
          if (error.code === 'auth/account-exists-with-different-credential') {
            error.message = 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.';
          } else if (error.code === 'auth/user-disabled') {
            error.message = 'The user is disabled. Please contact support.';
          }
          this._translationService.getTranslationString(error.message)
            .pipe(take(1))
            .subscribe((translatedString) => {
              error.message = translatedString;
            });
          this.errorDialog = this._dialog.open(ErrorDialogComponent, {
            data: { error: error },
          });
        }
      });
  }

  // Twitter  Login
  twitterLogin(): Promise<void> {
    const provider = new TwitterAuthProvider();
    return this.oAuthLogin(provider)
      .then(value => {
        this.blockUI.start();
        this._fuseProgressBarService.show();
        this.setUserData(value.user, 'twitter').then(() => {
          this._firebaseService.hasScansByID(value.user.uid).then(result => {
            if (result) {
              window.localStorage.setItem('login-event', Math.random().toString());
              this._router.navigate(['my-scans']);
            } else {
              window.localStorage.setItem('login-event', Math.random().toString());
              this._router.navigate(['new-scan']);
            }
          });
        });
      })
      .catch(error => {
        if (error.code !== 'auth/cancelled-popup-request' && error.code !== 'auth/popup-closed-by-user') {
          if (error.code === 'auth/account-exists-with-different-credential') {
            error.message = 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.';
          } else if (error.code === 'auth/user-disabled') {
            error.message = 'The user is disabled. Please contact support.';
          }
          this._translationService.getTranslationString(error.message)
            .pipe(take(1))
            .subscribe((translatedString) => {
              error.message = translatedString;
            });
          this.errorDialog = this._dialog.open(ErrorDialogComponent, {
            data: { error: error },
          });
        }
      });
  }

  // GitHub  Login
  gitHubLogin(): Promise<void> {
    const provider = new GithubAuthProvider();
    return this.oAuthLogin(provider)
      .then(value => {
        this.blockUI.start();
        this._fuseProgressBarService.show();
        this.setUserData(value.user, 'github').then(() => {
          this._firebaseService.hasScansByID(value.user.uid).then(result => {
            if (result) {
              window.localStorage.setItem('login-event', Math.random().toString());
              this._router.navigate(['my-scans']);
            } else {
              window.localStorage.setItem('login-event', Math.random().toString());
              this._router.navigate(['new-scan']);
            }
          });
        });
      })
      .catch(error => {
        if (error.code !== 'auth/cancelled-popup-request' && error.code !== 'auth/popup-closed-by-user') {
          if (error.code === 'auth/account-exists-with-different-credential') {
            error.message = 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.';
          } else if (error.code === 'auth/user-disabled') {
            error.message = 'The user is disabled. Please contact support.';
          }
          this._translationService.getTranslationString(error.message)
            .pipe(take(1))
            .subscribe((translatedString) => {
              error.message = translatedString;
            });
          this.errorDialog = this._dialog.open(ErrorDialogComponent, {
            data: { error: error },
          });
        }
      });
  }

  // Forgot password, send wmail with password reset
  forgotPassword(email: string): Promise<void> {
    this.blockUI.start();
    this._fuseProgressBarService.show();

    return this._afAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.blockUI.stop();
        this._fuseProgressBarService.hide();

        this._translationService.getTranslationString('Success')
          .pipe(take(1))
          .subscribe((translatedTitle) => {
            this._translationService.getTranslationString('Password reset email sent, check your inbox. Don\'t forget to check your spam folder.')
              .pipe(take(1))
              .subscribe((translatedMessage) => {
                this.errorDialog = this._dialog.open(ErrorDialogComponent, {
                  data: { title: translatedTitle, error: { message: translatedMessage } },
                });
              });
          });
      })
      .catch((error) => {
        this.blockUI.stop();
        this._fuseProgressBarService.hide();

        let errorKey = 'There was an error processing your request.';
        if (error.code === 'auth/user-not-found') {
          errorKey = 'There is no user record corresponding to this identifier. The user may have been deleted.';
        }

        this._translationService.getTranslationString('Error')
          .pipe(take(1))
          .subscribe((translatedTitle) => {
            this._translationService.getTranslationString(errorKey)
              .pipe(take(1))
              .subscribe((translatedMessage) => {
                this.errorDialog = this._dialog.open(ErrorDialogComponent, {
                  data: { title: translatedTitle, error: { message: translatedMessage } },
                });
              });
          });
      });
  }
  // forgotPassword(email: string): Promise<void> {
  //   this.blockUI.start();
  //   this._fuseProgressBarService.show();
  //   return this._afAuth
  //     .sendPasswordResetEmail(email)
  //     .then(() => {
  //       this.blockUI.stop();
  //       this._fuseProgressBarService.hide();
  //       this.errorDialog = this._dialog.open(ErrorDialogComponent, {
  //         data: { title: 'Success', error: { message: 'Password reset email sent, check your inbox. Don\'t forget to check your spam folder.' } },
  //       });
  //     })
  //     .catch((error) => {
  //       this.blockUI.stop();
  //       this._fuseProgressBarService.hide();
  //       if (error.code === 'auth/user-not-found') {
  //         error.message = 'There is no user record corresponding to this identifier. The user may have been deleted.';
  //       }
  //       this.errorDialog = this._dialog.open(ErrorDialogComponent, {
  //         data: { error: error },
  //       });
  //     });
  // }


  // Logout
  logout(): Promise<void> {
    return this._afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      window.localStorage.setItem('logout-event', Math.random().toString())
      this._userSubscription?.unsubscribe();
      this._authSubscription?.unsubscribe();
    });
  }

  // Event for logging out all other windows in browser
  storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        this._router.navigate(['login'], { state: { bypassFormGuard: true } });
      }
    }
  }

  // Handle active listeners when onDestroy 
  stopListener(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  // oAuth function
  oAuthLogin(provider: any): Promise<any> {
    return this._afAuth.signInWithPopup(provider);
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  async setUserData(user: any, logintype?: string): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this._afs.doc(`users/${user.uid}`);

    try {
      const querySnapshot = await from(this._afs.collection('users', ref => ref.where('uid', '==', user.uid)).get()).toPromise();

      let displayName: string;
      let provider: string;
      let emailVerified: boolean;

      if (querySnapshot.size > 0) {
        displayName = querySnapshot.docs[0].data()['displayName'];
      } else {
        if (logintype == 'userpassword') {
          displayName = user.email.substring(0, user.email.indexOf('@'));
        } else {
          displayName = user.displayName;
        }
      }

      if (logintype == "twitter" || logintype == "github") {
        emailVerified = true;
      } else {
        emailVerified = user.emailVerified;
      }

      if (logintype == 'userpassword') {
        provider = 'openbash.com';
      } else {
        provider = user.providerData[0].providerId;
      }

      const userData: User = {
        uid: user.uid,
        email: user.email,
        displayName: displayName,
        photoURL: user.photoURL,
        emailVerified: emailVerified,
        provider: provider
      };

      await userRef.set(userData, {
        merge: true,
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }
  // setUserData(user: any, logintype?: string): Promise<void> {
  //   const userRef: AngularFirestoreDocument<any> = this._afs.doc(
  //     `users/${user.uid}`
  //   );

  //   this._afs.collection('users').doc(user.uid).get()
  //     .subscribe((doc: any) => {
  //       const currentDisplayName = doc.data().displayName;

  //       let displayName: string;
  //       let provider: string;
  //       let emailVerified: boolean;

  //       if (logintype == "twitter" || logintype == "github") {
  //         emailVerified = true;
  //       } else {
  //         emailVerified = user.emailVerified;
  //       }
  //       if (logintype == 'userpassword') {
  //         if (!currentDisplayName || currentDisplayName == '') {
  //           displayName = user.email.substring(0, user.email.indexOf('@'));
  //         } else {
  //           displayName = currentDisplayName;
  //         }
  //         provider = 'openbash.com';
  //       } else {
  //         if (!currentDisplayName || currentDisplayName == '') {
  //           displayName = user.displayName;
  //         } else {
  //           displayName = currentDisplayName;
  //         }
  //         provider = user.providerData[0].providerId;
  //       }

  //       const userData: User = {
  //         uid: user.uid,
  //         email: user.email,
  //         displayName: displayName,
  //         photoURL: user.photoURL,
  //         emailVerified: emailVerified,
  //         provider: provider
  //       };

  //       return userRef.set(userData, {
  //         merge: true,
  //       });
  //     });
  // }

  // Saving user data in localstorage when logged in and setting up null when logged out
  setDataLocalStorage(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._authSubscription = this._afAuth.authState
        .subscribe((user) => {
          if (user) {
            if (user.emailVerified == true || (user.providerData[0].providerId == 'twitter.com' || user.providerData[0].providerId == 'github.com')) {
              this._userSubscription = this._firebaseService.getUserCustomData(user.uid).subscribe((firebaseUser) => {
                if (firebaseUser[0].length !== 0) {
                  localStorage.setItem('user', JSON.stringify(user));
                  let oldUserData = JSON.parse(localStorage.getItem('user'));
                  oldUserData.apiToken = firebaseUser[0][0].api_token;
                  oldUserData.purchasedCredits = firebaseUser[0][0].purchasedCredits;
                  oldUserData.subscriptionCredits = firebaseUser[0][0].subscriptionCredits;
                  oldUserData.maxConcurrent = firebaseUser[0][0].max_concurrent;
                  oldUserData.subscription = firebaseUser[1];
                  oldUserData.telegramNick = firebaseUser[0][0].telegramNick;
                  oldUserData.phone = firebaseUser[0][0].phone;
                  oldUserData.displayName = firebaseUser[0][0].displayName;
                  oldUserData.reportPhotoURL = firebaseUser[0][0].reportPhotoURL;
                  oldUserData.photoURL = firebaseUser[0][0].photoURL;
                  oldUserData.reportColor1 = firebaseUser[0][0].reportColor1;
                  oldUserData.reportColor2 = firebaseUser[0][0].reportColor2;
                  oldUserData.newScanEnabled = firebaseUser[0][0].newScanEnabled;
                  oldUserData.defaultAuth = firebaseUser[0][0].defaultAuth;
                  oldUserData.apiEndpoint = firebaseUser[0][0].apiEndpoint;
                  oldUserData.customApiToken = firebaseUser[0][0].customApiToken;
                  oldUserData.emailNotifications = firebaseUser[0][0].emailNotifications;
                  oldUserData.telegramNotifications = firebaseUser[0][0].telegramNotifications;
                  oldUserData.whatsappNotifications = firebaseUser[0][0].whatsappNotifications;
                  oldUserData.apiNotifications = firebaseUser[0][0].apiNotifications;
                  oldUserData.companyName = firebaseUser[0][0].companyName;
                  oldUserData.companyCountry = firebaseUser[0][0].companyCountry;
                  oldUserData.companyURL = firebaseUser[0][0].companyURL;
                  oldUserData.companyAddress = firebaseUser[0][0].companyAddress;
                  oldUserData.reportLanguage = firebaseUser[0][0].reportLanguage;
                  this.userData = oldUserData;
                  localStorage.setItem('user', JSON.stringify(this.userData));
                  this._localStorageService.updateLocalStorage('user');

                  // this._userSubscription?.unsubscribe();
                  // this._authSubscription?.unsubscribe();

                  resolve({ info: 'login' });
                } else {
                  this.logout().then(() => {
                    this._router.navigate(['login'], { state: { bypassFormGuard: true } });
                    resolve(({ info: 'logout' }));
                  });
                }
              });
            }
          }
          // } else {
          //   const auth = getAuth();
          //   signInAnonymously(auth)
          //     .then((data) => {
          //       this.userData = data.user;
          //       localStorage.setItem('user', JSON.stringify(this.userData));
          //       // JSON.parse(localStorage.getItem('user')!);
          //     })
          //     .catch((error) => {
          //       this.errorDialog = this._dialog.open(ErrorDialogComponent, {
          //         data: { error: error },
          //       });
          //     });
          //   resolve(({ info: 'logout' }));
          // }
        });
    });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (user !== null) {
      if (user.providerData[0].providerId == 'twitter.com' || user.providerData[0].providerId == 'github.com') {
        return true;
      } else {
        if (user.emailVerified !== false) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
}
