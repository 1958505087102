import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { ScanErrorComponent } from './scan-error.component';

@NgModule({
    declarations: [
        ScanErrorComponent
    ],
    imports     : [
        RouterModule,
        MatIconModule,
        FuseSharedModule
    ]
})
export class ScanErrorModule
{
}
