<div id="services" class="page-layout simple full-width">

  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">

    <div class="hero-text">
      <h1>{{ 'Services' | translate | async }}</h1>
      <h3 class="mt-0">
        {{ 'We automate penetration testing processes, utilizing advanced software tools for vulnerability scanning, exploit
        development, and report generation. Our tailored scraping tools analyze attack surfaces, uncover hidden
        vulnerabilities, and enhance security. We provide AI and machine learning solutions for fraud, anomaly, and
        account takeover detection, empowering clients to proactively safeguard their systems. Our services increase
        efficiency, reduce errors, and strengthen security posture.' | translate | async }}
      </h3>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content pt-24 pb-24 pt-sm-32 pb-sm-32" fxLayout="column">

    <div class="services" fxLayout="row wrap" fxLayoutAlign="center" [@animateStagger]="{value:'50'}">
      <div class="service" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}">
        <div class="service-content" fxLayout="column" fxFlex="1 1 auto">
          <img class="service-image" src="../../../../assets/images/fraud.png">
          <p class="mat-subheading-2">{{ 'Fraud Detection' | translate | async }}</p>
          <p class="description">{{ 'We employ AI and machine learning to detect fraud by identifying unusual patterns in
            transaction data, minimizing financial losses.' | translate | async }}</p>
        </div>
      </div>
      <div class="service" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}">
        <div class="service-content" fxLayout="column" fxFlex="1 1 auto">
          <img class="service-image" src="../../../../assets/images/anomally.png">
          <p class="mat-subheading-2">{{ 'Anomaly Detection' | translate | async }}</p>
          <p class="description">{{ 'Our machine learning algorithms spot anomalies in user behavior or system activity,
            signaling potential security incidents like compromised accounts.' | translate | async }}</p>
        </div>
      </div>
      <div class="service" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}">
        <div class="service-content" fxLayout="column" fxFlex="1 1 auto">
          <img class="service-image" src="../../../../assets/images/account-takeover.png">
          <p class="mat-subheading-2">{{ 'Account Takeover Detection' | translate | async }}</p>
          <p class="description">{{ 'Detection: Using AI and machine learning, we identify suspicious activities indicating
            account takeover attempts, including unusual login locations and activity deviations.' | translate | async }}</p>
        </div>
      </div>
      <div class="service" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}">
        <div class="service-content" fxLayout="column" fxFlex="1 1 auto">
          <img class="service-image" src="../../../../assets/images/automation.png">
          <p class="mat-subheading-2">{{ 'Automation of Penetration Testing' | translate | async }}</p>
          <p class="description">{{ 'We automate penetration testing processes with advanced software tools, streamlining
            vulnerability scanning, exploit development, and reporting to enhance efficiency and reduce errors.' | translate | async }}</p>
        </div>
      </div>
      <div class="service" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}">
        <div class="service-content" fxLayout="column" fxFlex="1 1 auto">
          <img class="service-image" src="../../../../assets/images/scarping.png">
          <p class="mat-subheading-2">{{ 'Scraping Tools for Attack Surface Analysis' | translate | async }}</p>
          <p class="description">{{ 'Our specialized tools gather extensive web data for systematic vulnerability analysis,
            aiding penetration tests and strengthening security measures.' | translate | async }}</p>
        </div>
      </div>
      <div class="service" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}">
        <div class="service-content" fxLayout="column" fxFlex="1 1 auto">
          <img class="service-image" src="../../../../assets/images/custom.png">
          <p class="mat-subheading-2">{{ 'Custom Software Development' | translate | async }}</p>
          <p class="description">{{ 'We listen to client needs and create bespoke software solutions to address specific
            security challenges, meeting unique business objectives.' | translate | async }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- / CONTENT -->

</div>