<div id="new-scan" class="page-layout carded fullwidth">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- HEADER -->
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center">

      <!-- APP TITLE -->
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          add
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          New Scan
        </span>
      </div>
      <!-- / APP TITLE -->

      <!-- SEARCH -->
      <div class="search-wrapper mx-32 mx-md-0">
        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon s-24">security</mat-icon>
          <input (keyup.enter)="selectScan()" #searchinput [(ngModel)]="searchInput" placeholder="https://example.com" fxFlex />
        </div>
      </div>
      <!-- / SEARCH -->

      <!-- PURCHASE CREDITS BUTTON -->
      <button mat-raised-button class="add-scan-button fuse-white mt-24 mt-md-0" routerLink="/credits">
        <span>PURCHASE CREDITS</span>
      </button>
      <!-- / PURCHASE CREDITS BUTTON -->

    </div>
    <!-- / HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card mt-8 mt-md-0">
      <!-- ROW 2 - SCAN SELECT -->
      <div class="scans" fxLayout="row wrap" fxLayoutAlign="center" [@animateStagger]="{value:'50'}">
        <div *ngFor="let scanType of scanTypes" class="scan" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}">
          <div class="scan-content" fxLayout="column" fxFlex="1 1 auto">
            <div class="padding-content">
              <img class="scan-image" src="{{scanType.LogoIMG}}">
              <p class="mat-subheading-2">{{scanType.Name}}</p>
              <p class="description">{{scanType.ShortDescription}}</p>
            </div>
            <div class="footer-container">
              <div class="scan-footer">
                <p class="scan-price">{{scanType.PriceCredits}} credits</p>
                <a *ngIf="mobile == false" class="scan-more-info" (click)="goToURL($event, scanType.MoreInfoURL)">+ More Info</a>
              </div>
              <div class="select-scan" fxLayout="row" fxLayoutAlign="center center">
                <button mat-button color="accent" (click)="startScan(scanType)" disabled="{{!scanType.isEnabled}}">
                  SELECT SCAN
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- / ROW 2 - SCAN SELECT -->
      </div>
    </div>
  </div>

</div>