import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor() {
  }

  // Global error handler
  handleError(error: Error | HttpErrorResponse): void {
    console.log('Global Error Handler Service');
    console.error(error);
  }
}
