<div class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">

        <div class="user" fxLayout="column" *ngIf="isLoggedIn === true">
            <div class="h3 username" [innerHTML]="user.displayName ? user.displayName : user.email"></div>
            <div class="h5 apikey hint-text mt-8" *ngIf="user.apiToken" (click)="copyApiKey(user.apiToken)">API Key: {{
                user.apiToken | truncate:10 }}</div>
            <div class="h5 apikey hint-text mt-8" *ngIf="!user.apiToken">API Key: Null</div>
            <div class="h5 credits hint-text mt-8" *ngIf="user.purchasedCredits || user.subscriptionCredits">Credits: <strong
                    class="credit-strong">{{user.purchasedCredits + user.subscriptionCredits}}</strong></div>
            <div class="h5 credits hint-text mt-8" *ngIf="!user.purchasedCredits && !user.subscriptionCredits">Credits: 0</div>
            <!-- <div class="h5 credits-purchase credits hint-text mt-8" routerLink="/credits">Purchase API Credits</div> -->
            <div class="avatar-container">
                <img class="avatar" [src]="user.photoURL ? user.photoURL : '/assets/images/profile.jpg'">
            </div>
        </div>

        <div class="navbar-inner" [ngClass]="isLoggedIn ? 'logged-in' : 'not-logged-in'">

            <!-- Top Menu -->
            <div class="top-menu">
                <!-- Log in -->
                <ng-container *ngIf="isLoggedIn === false">
                    <div class="nav-item">
                        <a class="nav-link" routerLink="/login">
                            <mat-icon class="nav-link-icon">exit_to_app</mat-icon>
                            <span class="nav-link-title">Login</span>
                        </a>
                    </div>
                </ng-container>
                <!-- / Log In -->
                <!-- New Scan -->
                <ng-container>
                    <div class="nav-item">
                        <a class="nav-link" routerLink="/new-scan" [routerLinkActive]="['active', 'accent']">
                        <!-- <a class="nav-link" routerLink="/web-scanner"> -->
                            <mat-icon class="nav-link-icon">add</mat-icon>
                            <span class="nav-link-title">New Scan</span>
                        </a>
                    </div>
                </ng-container>
                <!-- / New Scan -->
                <!-- My Scans -->
                <ng-container *ngFor="let item of navigation">
                    <ng-container *ngIf="item.id == 'my-scans'">
                        <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
                        <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'" [item]="item">
                        </fuse-nav-vertical-collapsable>
                        <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>
                    </ng-container>
                </ng-container>
                <!-- / My Scans -->
                <!-- Log Out -->
                <ng-container *ngIf="isLoggedIn === true">
                    <div class="nav-item">
                        <a class="nav-link" (click)="logout()">
                            <mat-icon class="nav-link-icon">exit_to_app</mat-icon>
                            <span class="nav-link-title">Logout</span>
                        </a>
                    </div>
                </ng-container>
                <!-- / Log Out -->
            </div>
            <!-- / Top Menu -->

            <!-- Bottom Menu -->
            <div class="bottom-menu">
                <ng-container *ngFor="let item of navigation">
                    <ng-container *ngIf="item.id != 'my-scans' && item.id != 'web-scanner'">
                        <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
                        <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'" [item]="item">
                        </fuse-nav-vertical-collapsable>
                        <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>
                    </ng-container>
                </ng-container>
            </div>
            <!-- / Bottom Menu -->
        </div>
    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">
        <div class="logo-container" routerLink="/web-scanner">
            <img src="./../../../assets/images/openbash-white.svg">
        </div>

        <ng-container *ngFor="let item of navigation">
            <ng-container *ngIf="item.id != 'my-scans' && item.id != 'web-scanner'">
                <fuse-nav-horizontal-collapsable *ngIf="item.type=='group'" [item]="item">
                </fuse-nav-horizontal-collapsable>
                <fuse-nav-horizontal-collapsable *ngIf="item.type=='collapsable'" [item]="item">
                </fuse-nav-horizontal-collapsable>
                <fuse-nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-horizontal-item>
            </ng-container>
        </ng-container>

        <!-- New Scan -->
        <!-- <ng-container>
            <div class="nav-item">
                <a class="nav-link" routerLink="/new-scan" [routerLinkActive]="['active', 'accent']">
                    <mat-icon class="nav-link-icon">add</mat-icon>
                    <span class="nav-link-title">New Scan</span>
                </a>
            </div>
        </ng-container> -->
        <!-- / New Scan -->

        <!-- Log Out -->
        <!-- <ng-container *ngIf="isLoggedIn === true">
            <div class="nav-item">
                <a class="nav-link" (click)="logout()">
                    <mat-icon class="nav-link-icon">power_settings_new</mat-icon>
                    <span class="nav-link-title">Logout</span>
                </a>
            </div>
        </ng-container> -->
        <!-- / Logged Out -->

        <!-- Right Menu -->
        <div class="right-menu-container">
            <!-- New Scan -->
            <ng-container>
                <div class="nav-item">
                    <a class="nav-link" routerLink="/new-scan" [routerLinkActive]="['active', 'accent']">
                    <!-- <a class="nav-link" routerLink="/web-scanner"> -->
                        <mat-icon class="nav-link-icon">add</mat-icon>
                        <span class="nav-link-title">New Scan</span>
                    </a>
                </div>
            </ng-container>
            <!-- / New Scan -->
            <!-- My Scans -->
            <ng-container *ngFor="let item of navigation">
                <ng-container *ngIf="item.id == 'my-scans'">
                    <fuse-nav-horizontal-collapsable *ngIf="item.type=='group'" [item]="item">
                    </fuse-nav-horizontal-collapsable>
                    <fuse-nav-horizontal-collapsable *ngIf="item.type=='collapsable'" [item]="item">
                    </fuse-nav-horizontal-collapsable>
                    <fuse-nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-horizontal-item>
                </ng-container>
            </ng-container>
            <!-- / My Scans -->
            <!-- Account Menu -->
            <ng-container *ngIf="isLoggedIn === true">
                <div class="nav-item account-container">
                    <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                        <div class="center-row" fxLayout="row" fxLayoutAlign="center center">
                            <img class="avatar mr-0 mr-sm-16"
                                [src]="user.photoURL ? user.photoURL : '/assets/images/profile.jpg'">
                            <span class="username mr-12" fxHide fxShow.gt-sm
                                [innerHTML]="user.displayName ? user.displayName : user.email"></span>
                            <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                        </div>
                    </button>
                    <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                        <!-- <button mat-menu-item routerLink="/my-scans" [routerLinkActive]="['active', 'accent']">
                            <mat-icon>view_timeline</mat-icon>
                            <span>My Scans</span>
                        </button> -->
                        <button mat-menu-item (click)="copyApiKey(user.apiToken)" *ngIf="user.apiToken">
                            <mat-icon>vpn_key</mat-icon>
                            <span>API Key: {{ user.apiToken | truncate:10 }}</span>
                        </button>
                        <button mat-menu-item *ngIf="!user.apiToken">
                            <mat-icon>vpn_key</mat-icon>
                            <span>API Key: Null</span>
                        </button>
                        <button mat-menu-item *ngIf="user.purchasedCredits || user.subscriptionCredits" routerLink="/credits">
                            <mat-icon>monetization_on</mat-icon>
                            <span>Credits: <strong>{{user.purchasedCredits + user.subscriptionCredits}}</strong></span>
                        </button>
                        <button mat-menu-item *ngIf="!user.purchasedCredits && !user.subscriptionCredits" routerLink="/credits">
                            <mat-icon>monetization_on</mat-icon>
                            <span>Credits: <strong class="credit-strong">0</strong></span>
                        </button>
                        <button mat-menu-item routerLink="/credits" [routerLinkActive]="['active', 'accent']">
                            <mat-icon>shopping_cart</mat-icon>
                            <span>Purchase API Credits</span>
                        </button>
                        <button mat-menu-item class="" (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Logout</span>
                        </button>
                    </mat-menu>
                </div>
            </ng-container>
            <!-- / Account Menu -->
            <!-- Log in -->
            <ng-container *ngIf="isLoggedIn === false">
                <div class="nav-item login">
                    <a class="nav-link" routerLink="/login">
                        <mat-icon class="nav-link-icon">exit_to_app</mat-icon>
                        <span class="nav-link-title">Login</span>
                    </a>
                </div>
            </ng-container>
            <!-- / Log In -->
        </div>
        <!-- / Right Menu -->

    </ng-container>
    <!-- / Horizontal Navigation Layout -->

</div>