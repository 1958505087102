import { Component, OnInit } from '@angular/core';
import { ICreateOrderRequest } from 'ngx-paypal';
import { environment } from '../../../../environments/environment';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UiFunctionsService } from '../../services/ui-functions/ui-functions.service';
import { OpenbashService } from '../../services/openbash/openbash.service';
import { AuthService } from '../../services/auth/auth.service';
import { FirebaseService } from '../../services/firebase/firebase.service';
import { creditTypesDef } from 'app/credit-types/credit-types-def';
import { fuseAnimations } from '@fuse/animations';
import { AppComponent } from '../../../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
  animations: fuseAnimations
})
export class CreditsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  payPalConfig: any = null;
  reCaptchaToken: string = null;

  creditsQuantity: number;
  totalPrice: number;

  creditTypesDef: any;
  selectedPackIndex: number;

  newScanNavigationBypass = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
 * Constructor
 * @param {FuseProgressBarService} _fuseProgressBarService
 * @param {MatSnackBar} _snackBar
 * @param {ReCaptchaService} _recaptchaService
 * @param {UiFunctionsService} _uiFunctionsService
 * @param {OpenbashService} _openbashService
 * @param {AuthService} _authService
 * @param {FirebaseService} _firebaseService
 * @param {AppComponent} _appComponent
 * @param {Router} _router
 */

  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _snackBar: MatSnackBar,
    private _recaptchaService: ReCaptchaV3Service,
    private _uiFunctionsService: UiFunctionsService,
    private _openbashService: OpenbashService,
    private _authService: AuthService,
    private _firebaseService: FirebaseService,
    private _appComponent: AppComponent,
    private _router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the credit types
    this.creditTypesDef = creditTypesDef;
  }

  ngOnInit(): void {
    // Generates default paypal config
    this.selectedPackIndex = 2;
    this.creditsQuantity = 40;
    this.totalPrice = 30;
    this.resetPayPalConfig();
  }

  ngOnDestroy(): void {
    // Dissmis the snackbar onDestroy
    if (!this.newScanNavigationBypass) {
      this._snackBar.dismiss();
    }

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Resets paypal config
  resetPayPalConfig(): void {
    this.payPalConfig = this.initPaypalConfig(
      this.creditsQuantity,
      this.totalPrice
    );
  }

  // Paypal config
  initPaypalConfig(creditsQuantity: number, totalPrice: number): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: totalPrice.toString(),
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: totalPrice.toString()
                }
              }
            },
            items: [
              {
                name: 'OpenBash ' + creditsQuantity.toString() + ' credits.',
                quantity: creditsQuantity.toString(),
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'USD',
                  value: (totalPrice / creditsQuantity).toString(),
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'pay',
        layout: 'horizontal',
        tagline: false,
        height: 36
      },
      onInit: (data, actions) => {
      },
      onApprove: (data, actions) => {
        // console.log(
        //   'onApprove - transaction was approved, but not authorized',
        //   data,
        //   actions
        // );
        // actions.order.get().then(details => {
        //   console.log(
        //     'onApprove - you can get full order details inside onApprove: ',
        //     details
        //   );
        // });

        this._snackBar.dismiss();
        this._fuseProgressBarService.show();
        this.blockUI.start();
        this.setCredits(this._authService.userData.uid, this.creditsQuantity, data.orderID);

      },
      onClientAuthorization: data => {
        // console.log(
        //   'onClientAuthorization - you should probably inform your server about completed transaction at this point',
        //   data
        // );

        this._snackBar.dismiss();

        this._openbashService.paymentApprovedCredits(data.id)
          .pipe(
            takeUntil(this._unsubscribeAll),
            finalize(() => {
              this._fuseProgressBarService.hide();
            })
          ).subscribe(
            (response) => {
              if (response.status === 1) {
                this._firebaseService.setUserCredits(this.creditsQuantity)
                  .then(() => {
                    this.blockUI.stop();
                    // Success snackbar
                    this._uiFunctionsService.presentSnackbar(this._snackBar, 'The credits were successfully added to your account.', undefined);

                    // Navigates to new scan
                    this.newScanNavigationBypass = true;
                    this._router.navigate(['new-scan']);
                  });
              } else {
                this.blockUI.stop();
                // Error snackbar
                this._uiFunctionsService.presentSnackbar(this._snackBar, response.error, undefined);
              }
            },
            (error) => {
              this.blockUI.stop();
              console.error('Error caught in component.');
              // Error snackbar
              this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error in your request. Please try again. If your payment was charged please contact support.', undefined);
              throw error;
            }
          );

      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
        // Error snackbar
        this.blockUI.stop();
        this._uiFunctionsService.presentSnackbar(this._snackBar, 'The payment was cancelled. Please try again.', undefined);
      },
      onError: err => {
        // console.log('OnError', err);
        // Error snackbar
        this.blockUI.stop();
        this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error with the payment. Please review it and contact us or try again.', undefined);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
        // Generates Recaptcha Token
        this.generatereCaptcha();
        // Dismiss the snackbar
        this._snackBar.dismiss();
        // if (!this.reCaptchaToken) {
        //   this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error with reCAPTCHA. Please reload the page.', undefined);
        // }
      }
    };
    return this.payPalConfig;
  }

  // Generates reCaptchaToken
  generatereCaptcha(): void {
    // this._recaptchaService.reset();
    this._recaptchaService.execute('performScan')
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(
        (token) => {
          // Validates reCaptcha
          this.reCaptchaToken = token;
        },
        (error) => {
          console.error('Error caught in component.');
          // Error snackbar
          this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error with reCAPTCHA. Please try again.', undefined);
          throw error;
        }
      );
  }

  // Sets the credits order in the openbash database
  setCredits(userID: string, creditsQuantity: number, orderID: string): void {

    let creditObject = {
      userID: userID,
      creditsQuantity: creditsQuantity,
      orderID: orderID,
      token: this.reCaptchaToken
    }

    this._openbashService.addCredits(creditObject)
      .pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(
        (response) => {
          if (response.status !== 1) {
            // this.blockUI.stop();
            // this._fuseProgressBarService.hide();
            // Error snackbar
            this._uiFunctionsService.presentSnackbar(this._snackBar, response.error, undefined);
          }
        },
        (error) => {
          // this.blockUI.stop();
          // this._fuseProgressBarService.hide();
          console.error('Error caught in component.');
          // Error snackbar
          this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error in your request. Please try again. If your payment was charged please contact support.', undefined);
          throw error;
        }
      );
  }


  // Selects the pack
  selectPack(quantity: number, totalPrice: number, index: number): void {
    this.selectedPackIndex = index;
    this.creditsQuantity = quantity;
    this.totalPrice = totalPrice;
    this.resetPayPalConfig();
    setTimeout(() => {

      this._appComponent.scrollToElement('ngx-paypal', -400);
    }, 400);
  }

}
