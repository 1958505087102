import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
    selector: 'mail-confirm',
    templateUrl: './mail-confirm.component.html',
    styleUrls: ['./mail-confirm.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MailConfirmComponent {

    @BlockUI() blockUI: NgBlockUI;

    email: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {Router} _router
     * @param {FuseProgressBarService} _fuseProgressBarService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        public _router: Router,
        private _fuseProgressBarService: FuseProgressBarService,
    ) {
        // Stops block ui and fusebar
        this.blockUI.stop();
        this._fuseProgressBarService.hide();

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // State from register page (auth component)
        if (history.state.hasOwnProperty('email')) {
            this.email = history.state.email;
        } else {
            this._router.navigate(['web-scanner']);
        }
    }
}
