<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<block-ui [template]="blockTemplate" id="main">
    <!-- HEADER -->
    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <!-- NAVBAR: Top -->
    <ng-container>
        <ng-container *ngTemplateOutlet="topNavbar"></ng-container>
    </ng-container>
    <!-- / NAVBAR: Top -->
    <!-- / HEADER -->

    <div id="container-1" class="container">

        <!-- NAVBAR: Left -->
        <ng-container>
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container">

            <div id="container-3" class="container" fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true, wheelSpeed: 2}">

                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->

            </div>
        </div>

    </div>

    <!-- FOOTER -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above-fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- / FOOTER -->
</block-ui>


<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden" fxHide.gt-md
        [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background : fuseConfig.layout.toolbar.position">
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- TOP NAVBAR -->
<ng-template #topNavbar>
    <navbar variant="horizontal-style-1" class="top-navbar" fxHide fxShow.gt-md
        *ngIf="!fuseConfig.layout.navbar.hidden">
    </navbar>
</ng-template>
<!-- / TOP NAVBAR -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded"
        *ngIf="!fuseConfig.layout.navbar.hidden">
        <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- FOOTER -->
<ng-template #footer>
    <footer *ngIf="!fuseConfig.layout.footer.hidden"
        [ngClass]="fuseConfig.layout.footer.customBackgroundColor === true ? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background : fuseConfig.layout.footer.position">
    </footer>
</ng-template>
<!-- / FOOTER -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- / @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->