<div id="no-login-dialog">
  <div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
      <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">{{ 'Warning' | translate | async }}</span>
        <button mat-icon-button mat-dialog-close aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content id="dialogContentScrollbar" class="mat-typography p-16 pb-0 m-0">
      <p>{{ 'You are not logged in. You will only be able to access the scan through the link, the scan will not be saved, nor will you be able to see it in the my scans table and it will be deleted in 30 days.' | translate | async }}</p>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
    </div>
  </div>
</div>