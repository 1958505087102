import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  title: string;

  /**
   * Constructor
   * 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { error: any, title?: string }
  ) { 
    if (data.title) {
      this.title = data.title;
    } else {
      this.title = 'Error';
    }
  }

}
