import { CreditTypes } from './../main/models/credits';

export const creditTypesDef: CreditTypes[] = [
    {
        pricePerCredit: 1,
        options: [
            {
                slug: '5-credits',
                quantity: 5,
                totalPrice: 5
            },
            {
                slug: '10-credits',
                quantity: 10,
                totalPrice: 10
            },
            {
                slug: '40-credits',
                quantity: 40,
                totalPrice: 30
            },
            {
                slug: '75-credits',
                quantity: 75,
                totalPrice: 60
            },
            {
                slug: '125-credits',
                quantity: 125,
                totalPrice: 100
            },
            {
                slug: '250-credits',
                quantity: 250,
                totalPrice: 200
            }
        ]
    }
];
