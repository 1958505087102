import { NgModule } from '@angular/core';

import { FooterModule } from './footer/footer.module';
import { NavbarModule } from './navbar/navbar.module';
import { ContentModule } from './content/content.module';
import { ToolbarModule } from './toolbar/toolbar.module';

@NgModule({
    imports: [
        FooterModule,
        NavbarModule,
        ContentModule,
        ToolbarModule
    ],
    exports: [
        FooterModule,
        NavbarModule,
        ContentModule,
        ToolbarModule
    ]
})
export class UIComponentsModule
{

}
