import { Component, OnDestroy, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { MatSnackBar } from '@angular/material/snack-bar';

import { navigation } from 'app/navigation/navigation';

import { Router } from '@angular/router';

import { TranslationService } from 'app/main/services/translation/translation.service';
import { languagesDef } from 'app/languages/languages-def';
import { Languages } from 'app/main/models/languages';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    navigation: any;
    languages: Languages[];
    selectedLanguage: any;
    // userStatusOptions: any[];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     * @param {Router} _router
     * @param {TranslationService} _translationService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _translationService: TranslationService
        // private _translateService: TranslateService
    ) {
        // Set the defaults
        // this.userStatusOptions = [
        //     {
        //         title: 'Online',
        //         icon : 'icon-checkbox-marked-circle',
        //         color: '#4CAF50'
        //     },
        //     {
        //         title: 'Away',
        //         icon : 'icon-clock',
        //         color: '#FFC107'
        //     },
        //     {
        //         title: 'Do not Disturb',
        //         icon : 'icon-minus-circle',
        //         color: '#F44336'
        //     },
        //     {
        //         title: 'Invisible',
        //         icon : 'icon-checkbox-blank-circle-outline',
        //         color: '#BDBDBD'
        //     },
        //     {
        //         title: 'Offline',
        //         icon : 'icon-checkbox-blank-circle-outline',
        //         color: '#616161'
        //     }
        // ];

        // Loads Languages
        this.languages = languagesDef;

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Sets the selected language
        this.selectedLanguage = this.languages.find(language => language.id === this._translationService.getCurrentLanguage());   
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._snackBar.dismiss();
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    // Navigates to home page
    goToHome(): void {
        this._router.navigate(['web-scanner']);
    }

    // Changes Language
    changeLanguage(lang: any): void {
        this.selectedLanguage = lang;
        this._translationService.changeLanguage(lang.id);
    }

    // /**
    //  * Search
    //  *
    //  * @param value
    //  */
    // search(value): void
    // {
    //     // Do your search here...
    //     console.log(value);
    // }
}
