<div id="register" fxLayout="column">

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="register-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/openbash-color.svg">
            </div>

            <div class="title">{{ 'CREATE AN ACCOUNT' | translate | async }}</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate (ngSubmit)="submitRegisterForm()">

                <!-- <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>
                        Name is required
                    </mat-error>
                </mat-form-field> -->

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Email' | translate | async }}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        {{ 'Email is required' | translate | async }}
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        {{ 'Please enter a valid email address' | translate | async }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Password' | translate | async }}</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('password').hasError('required')">
                        {{ 'Password is required' | translate | async }}
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('password').hasError('required') && registerForm.get('password').hasError('minlength')">
                        {{ 'Password should be at least 6 characters' | translate | async }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Password (Confirm)' | translate | async }}</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                        {{ 'Password confirmation is required' | translate | async }}
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                       registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        {{ 'Passwords must match' | translate | async }}
                    </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="I read and accept" formControlName="termsAndConditions">
                        <span>{{ 'I accept the' | translate | async }} </span>
                    </mat-checkbox>
                    <a (click)="openTermsDialog()">{{ 'Terms and Conditions' | translate | async }}</a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="CREATE AN ACCOUNT"
                        [disabled]="registerForm.invalid">
                    {{ 'CREATE AN ACCOUNT' | translate | async }}
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">{{ 'Already have an account?' | translate | async }}</span>
                <a class="nav-link" routerLink="/login">{{ 'Login' | translate | async }}</a>
            </div>

        </div>

    </div>

</div>
