import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FuseSharedModule } from '@fuse/shared.module';
import { HomeSearchComponent } from './home-search.component';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        HomeSearchComponent
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,
        MatSnackBarModule,
        CrystalLightboxModule,
        RouterModule
    ]
})
export class HomeSearchModule
{
}
