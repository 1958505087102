import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from 'app/main/services/translation/translation.service';


@Component({
    selector: 'faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit, OnDestroy {
    baseFaqs: any;
    faqs: any;
    faqsFiltered: any;
    step: number;
    searchInput: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     * @param {MatSnackBar} _snackBar
     * @param {TranslationService} _translationService
     *
     */
    constructor(
        private _snackBar: MatSnackBar,
        private _translationService: TranslationService
    ) {
        // Set the defaults
        this.searchInput = new FormControl('');
        this.step = 0;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // FAQS Object
        this.baseFaqs = [
            {
                'question': 'How does free trial work?',
                'answer': 'Enter the target you want to analyze, select the scan type and, when choosing the plan, select the free trial option.'
            },
            {
                'question': 'Can I cancel any time?',
                'answer': 'In general, there is nothing to cancel. You pay per use. In an enterprise plan, you can always cancel the service at any time.'
            },
            {
                'question': 'What are the limitations of the free trial?',
                'answer': 'Only ports 80 and 443 are scanned. Surface scan is limited to 5 subdomains. The discovery dictionary is 100 words long.'
            },
            {
                'question': 'What if the scanner fails to scan?',
                'answer': 'You must contact us supplying the ID of the scanner that failed. We will manually verify the problem. We will refund the money if applicable.'
            },
            {
                'question': 'What happens after I buy a scanner?',
                'answer': 'You will receive an email from our team indicating the purchase. This email contains a scanner ID and a link where you can access your results.'
            },
            {
                'question': 'What if I don´t understand the results?',
                'answer': 'Contact our support with the scanner ID. We offer paid support to summarize the main milestones and risks detected. This is a tool designed specifically for pentesters, and it can generate extremely detailed data.'
            },
            {
                'question': 'What happens to my scan data after I use it?',
                'answer': 'In the public versions of the scanner, the scan data is deleted after 15 days.'
            },
            {
                'question': 'Can I access the scans I have done in the past?',
                'answer': 'Only in the enterprise type service. You must contact our sales team to assess your needs.'
            },
            {
                'question': 'Why doesn´t the system have user registration?',
                'answer': 'We try not to handle user data. Only in enterprise plans do we keep a record.'
            },
            {
                'question': 'What payment methods do you accept?',
                'answer': 'In public plans we accept paypal. In enterprise plans we accept bitcoin, bank transfer or the payment method that we agree on.'
            },
            {
                'question': 'What does a vulnerability scanner do?',
                'answer': 'Vulnerability scanners are automated tools that allow organizations to check if their networks, systems and applications have security weaknesses that could expose them to attacks.'
            },
            {
                'question': 'What are the types of vulnerability scans?',
                'answer': 'Vulnerability scanners can be categorized into 5 types based on the type of assets they scan.\n1. Network-based scanners.\n2. Host-based scanners.\n3. Wireless scanners.\n4. Application scanners.\n5. Database scanners.\nOur tool covers host, network and application scanner types.'
            },
            {
                'question': 'Which is a common web server vulnerability?',
                'answer': 'The Top 10 security vulnerabilities as per OWASP Top 10 are:\nOWASP Top 10\nInjection Flaws\nBroken Authentication\nSensitive Data Exposure\nMissing Function Level Access Control\nSecurity Misconfiguration\nCross-Site Scripting XSS\nInsecure Direct Object References\nCross-Site Request Forgery\nUsing Components with Known Vulnerabilities\nUnvalidated Redirects & Forwards'
            },
            {
                'question': 'How do hackers hack servers?',
                'answer': 'There are three primary ways a server may be compromised: The attacker has guessed the password of a user on the server. This may be an email, ftp, or ssh user. The attacker has gained access through a security hole in a web application (or its addons/plugins) such as WordPress, Joomla, Drupal, etc. The attacker has gained access through a server missconfiguration. We evaluate all of them.'
            },
            {
                'question': 'What are application security best practices?',
                'answer': 'Pentesting is one of the best web application security practices.It means that you will ask professionals to "attack" your app to find out its vulnerabilities.'
            },
            {
                'question': 'When should I secure my web application?',
                'answer': 'Many developers think of security as an afterthought. In reality, it should be part of the development process from the very early stages of development.'
            },
            {
                'question': 'What is web application security?',
                'answer': 'A Web application or web app is a website, in other words. So easily said, web application security is website security. Website security involves protecting websites by detecting, preventing and responding to attacks.'
            },
            {
                'question': 'Why do we need web application security?',
                'answer': 'Web security is important to keeping hackers and cyber-thieves from accessing sensitive information. Without a proactive security strategy, businesses risk the spread and escalation of malware, attacks on other websites, networks, and other IT infrastructures.'
            },
            {
                'question': 'How long does a vulnerability scan take?',
                'answer': '1-3 hours.\nAt a high level, scanning tools run a series of if-then scenarios on your networks (also known as a vulnerability scan), which may take 1-3 hours for a quick scan or 10+ hours for a larger scan. It´s important to remember that scan times will vary depending on your environment.'
            },
            {
                'question': 'How often should you run a vulnerability scan?',
                'answer': 'An industry best practice is to perform vulnerability scanning at least once per quarter. Quarterly vulnerability scans tend to catch any major security holes that need to be assessed, but depending on your unique organizational needs, you may end up performing scans monthly or even weekly.'
            },
            {
                'question': 'What is an external vulnerability scan?',
                'answer': 'An external vulnerability scan is an assessment that´s performed without access to the network that´s being scanned. External scans target external IP addresses in your network, identify vulnerabilities as well as all the ports that can be accessed from the internet.'
            },
            {
                'question': 'Why is it important to continue conducting vulnerability scans on a periodic basis?',
                'answer': 'Experts recommend that vulnerability scans should be performed at least quarterly, especially after new equipment is loaded or the network undergoes significant changes. The scans will detect if your equipment is compromised, such as missing patches and outdated protocols, certificates, and services.'
            },
            {
                'question': 'What is a Pentesting tool?',
                'answer': 'Penetration testing tools are used as part of a penetration test (Pen Test) to automate certain tasks, improve testing efficiency and discover issues that might be difficult to find using manual scan techniques alone. Two common penetration testing tools are static scan tools and dynamic scan tools.'
            },
            {
                'question': 'How often should you Pentest?',
                'answer': 'Penetration testing should be performed on a regular basis (at least once a year) to ensure more consistent IT and network security management by revealing how newly discovered threats (0-days, 1-days) or emerging vulnerabilities might be exploited by malicious hackers.'
            },
            {
                'question': 'What does a Pentester do?',
                'answer': 'Pen testers design and plan simulations and security assessments designed to probe existing cybersecurity measures for potential weaknesses. They also document their findings in reports and present them to their clients, employers, and superiors.'
            },
            {
                'question': 'Is Pentesting a difficult task?',
                'answer': 'Generally speaking, pen testing is difficult on many levels. First, you need to know exactly how everything works. This includes keeping up to date with the latest inventions and applications for your chosen area of expertise.'
            },
            {
                'question': 'When should security testing be done?',
                'answer': 'In general, a pen test should be done right before a system is put into production, once the system is no longer in a state of constant change. It is ideal to test any system or software before it is put into production.'
            },
            {
                'question': 'What are credits and how do they work?',
                'answer': 'Credits are a form of virtual currency that you can purchase and use to access our cybersecurity scanning services through our APIs. Each credit is equivalent to $1, and you can choose from a variety of credit packages to fit your needs and budget. Simply purchase the desired number of credits, and then use them to pay for the API calls associated with our scanning services.'
            },
            {
                'question': 'How do I purchase credits?',
                'answer': 'To purchase credits, visit our "Purchase Credits" page, select the credit package that best suits your needs, and complete the checkout process using your preferred payment method.'
            },
            {
                'question': 'Can I get a discount when purchasing credits?',
                'answer': 'Yes, we offer volume discounts and bonus credits when purchasing larger credit packages. The more credits you buy, the more you save. The exact discount and bonus structure can be found on our "Purchase Credits" page.'
            },
            {
                'question': 'Do credits expire?',
                'answer': 'Our credits do not have an expiration date. You can use them at any time to access our security scanning services.'
            },
            {
                'question': 'Can I buy a custom number of credits?',
                'answer': 'Currently, it is not possible to purchase a custom number of credits directly through our "Purchase Credits" page. However, if you require a specific number of credits, you can submit a request through our support page, and our team will be happy to assist you.'
            },
            {
                'question': 'How do I use credits to access your cybersecurity scanning services?',
                'answer': 'Once you have purchased credits, you can use them to pay for the API calls associated with our cybersecurity scanning services. Each API call will consume a specific number of credits based on the scanning service being used. As you consume the API services, the corresponding number of credits will be deducted from your account balance.'
            },
            {
                'question': 'Can I get a refund for unused credits?',
                'answer': 'Unfortunately, we do not offer refunds for unused credits. However, since our credits do not expire, you can use them for future security scanning needs.'
            },
            {
                'question': 'Can I transfer credits to another user?',
                'answer': 'Currently, we do not support the transfer of credits between users. Credits are tied to the account that made the purchase and cannot be shared with other users.'
            },
            {
                'question': 'Is my payment information secure when purchasing credits?',
                'answer': 'Yes, we take your privacy and security very seriously. All transactions are processed using industry-standard encryption and security measures to protect your personal and financial information.'
            },
            {
                'question': 'What should I do if I encounter a problem with my credits or scanning services?',
                'answer': 'If you experience any issues or have concerns related to your credits or our scanning services, please don´t hesitate to contact our support team. They are available to help you resolve any problems and ensure a smooth experience using our platform.'
            }
        ];

        this.faqs = this.baseFaqs;
        this.faqsFiltered = this.faqs;

        // Inicializar los FAQs con las traducciones del idioma por defecto
        this.getTranslatedFaqs()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(translatedFaqs => {
                this.faqs = translatedFaqs;
                this.faqsFiltered = translatedFaqs;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._faqService.onFaqsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(response => {
        //         this.faqs = response;
        //         this.faqsFiltered = response;
        //     });

        // Escuchar el cambio de idioma
        this._translationService.currentLang$
            .pipe(
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(lang => {
                this.getTranslatedFaqs()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(translatedFaqs => {
                        this.faqs = translatedFaqs;
                        this.faqsFiltered = translatedFaqs;
                    });
            });



        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe((searchText: any) => {
                this.faqsFiltered = FuseUtils.filterArrayByString(this.faqs, searchText);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Dissmis the snackbar onDestroy
        this._snackBar.dismiss();

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set step
     *
     * @param {number} index
     */
    setStep(index: number): void {
        this.step = index;
    }

    /**
     * Next step
     */
    nextStep(): void {
        this.step++;
    }

    /**
     * Previous step
     */
    prevStep(): void {
        this.step--;
    }

    getTranslatedFaqs() {
        const translations = this.baseFaqs.map(faq => {
            return forkJoin({
                question: this._translationService.getTranslationString(faq.question, true),
                answer: this._translationService.getTranslationString(faq.answer, true)
            });
        });

        return forkJoin(translations);
    }
}
