<div id="about-us" class="page-layout simple full-width">

  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">

    <div class="hero-text">
      <h1>{{ 'About Us' | translate | async }}</h1>
      <h3 class="mt-0">
        {{ 'We are an independent software development company specializing in cybersecurity. Our team is composed of
        diverse professionals who operate from different corners of the globe, embodying the concept of a truly digital
        and borderless company. We believe in the potential of remote work, freeing ourselves from the constraints of a
        physical office and embracing the flexibility and diversity that comes with a global workforce.' | translate | async }}
      </h3>
    </div>
  </div>
  <!-- / HEADER -->

  <div class="content" fxLayout="column">
    <div class="option" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center center">
      <div class="container text-container" fxFlex="50%" fxFlex.xs="100%" fxLayoutAlign="center center">
        <div class="first-column">
          <h3 class="mat-headline"><b>{{ 'Our Expertise' | translate | async }}</b></h3>
          <p class="mat-subheading-2">
            {{ 'Our team\'s expertise is grounded in technical certifications widely recognized in the offensive
            cybersecurity industry, such as OSCP (Offensive Security Certified Professional) and CREST (Council of
            Registered Ethical Security Testers). This knowledge base, combined with our hands-on experience in the
            field, ensures that our software solutions are of the highest standard and tailored to the specific needs of
            our clients.' | translate | async }}
          </p>
        </div>
      </div>

      <div class="container image-container">
        <img class="sample-report-img" src="./assets/images/laptop.png">
      </div>
    </div>

    <div id="option-2" class="option" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
      fxLayoutAlign="center center">
      <div class="container image-container">
        <img class="sample-report-img" src="./assets/images/mobile.png">
      </div>

      <div class="container text-container" fxFlex="50%" fxFlex.xs="100%" fxLayoutAlign="center center">
        <div class="first-column">
          <h3 class="mat-headline"><b>{{ 'We excel in several areas, including:' | translate | async }}</b></h3>
          <ul class="mat-subheading-2">
            <li>{{ 'Automation of penetration testing processes.' | translate | async }}</li>
            <li>{{ 'Integration of cybersecurity analysis results.' | translate | async }}</li>
            <li>{{ 'Implementation of machine learning tools to enhance attacks.' | translate | async }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div id="option-3" class="option" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
      fxLayoutAlign="center center">
      <div class="inner-content text-container">
        <h3 class="mat-headline"><b>{{ 'Our Mission' | translate | async }}</b></h3>
        <p class="mat-subheading-2">
          {{ 'Our mission is to provide software services to penetration testing teams who often lack the time to program
          the tools they need for their tasks. We understand the challenges faced by analysts in the field and aim to
          alleviate these by offering intuitive and effective software solutions.' | translate | async }}
        </p>
      </div>
    </div>

    <div id="option-4" class="option" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
      fxLayoutAlign="center center">
      <div class="inner-content text-container">
        <h3 class="mat-headline"><b>{{ 'Our Vision' | translate | async }}</b></h3>
        <p class="mat-subheading-2">
          {{ 'Going beyond the technical need to automate processes, we also recognize the emerging necessity of
          integrating cybersecurity tools with machine learning and AI techniques. With internationally recognized
          certifications in these areas as well, we aim to stay at the forefront of this intersection between
          cybersecurity and artificial intelligence. Our vision is to create a seamless blend of these technologies,
          providing our clients with innovative solutions that not only address their immediate needs but also prepare
          them for the future of cybersecurity.' | translate | async }}
        </p>
      </div>
    </div>

  </div>
</div>