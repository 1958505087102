<div id="scan-error" fxLayout="column" fxLayoutAlign="center center">

  <div class="content" fxLayout="column" fxLayoutAlign="center center">

      <div class="message">This scan does not exits or you do not have permission to view it</div>

      <!-- <div class="search" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon s-24">search</mat-icon>
          <input placeholder="Search for something" fxFlex>
      </div> -->

      <a class="back-link" routerLink="/web-scanner">Go back to home</a>

  </div>

</div>
