import { Component, ViewEncapsulation, ViewChild, OnDestroy, OnInit, Inject, forwardRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
// import { UiFunctionsService } from '../../services/ui-functions/ui-functions.service';
import { takeUntil, finalize } from 'rxjs/operators';
// import { scanTypesDef } from '../../../scan-types/scan-types-def';
// import { OpenbashService } from '../../services/openbash/openbash.service';
import { AppComponent } from '../../../app.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FirebaseService } from 'app/main/services/firebase/firebase.service';
import { environment } from '../../../../environments/environment';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class HomeSearchComponent implements OnDestroy, OnInit {

  // @ViewChild('searchinput') searchinputField;

  @BlockUI() blockUI: NgBlockUI;

  // selectedscanType: string = null;
  // searchInput: string = null;
  // scanTypesDef: any;
  scanTypes = [];
  plans = [];
  loadedPlans = false;
  loadedScans = false;
  mobile: boolean = environment.mobile;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   * @param {Router} _router
   * @param {MatSnackBar} _snackBar
   * @param {UiFunctionsService} _uiFunctionsService
   * @param {OpenbashService} _openbashService
   * @param {AppComponent} _appComponent
   * @param {FuseProgressBarService} _fuseProgressBarService
   * @param {ActivatedRoute} _activatedRoute
   * @param {FirebaseService} _firebaseService
   */
  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    // private _uiFunctionsService: UiFunctionsService,
    // private _openbashService: OpenbashService,
    @Inject(forwardRef(() => AppComponent))
    private _appComponent: AppComponent,
    private _fuseProgressBarService: FuseProgressBarService,
    // private _activatedRoute: ActivatedRoute,
    private _firebaseService: FirebaseService
  ) {
    // Stops blockUI from login/register
    this.blockUI.stop();

    // State from URL
    // if (this._activatedRoute.snapshot.paramMap.get('url')) {
    //   this.searchInput = this._activatedRoute.snapshot.paramMap.get('url');
    // }

    // State from pricing page or my scans page
    // if (history.state.hasOwnProperty('selectedscanType') || history.state.fromMyScans === true) {
    //   if (history.state.hasOwnProperty('selectedscanType')) {
    //     this.selectedscanType = history.state.selectedscanType;
    //   }
    //   // this.validateSearchField(this.searchInput, 0);
    // }

    // Sets the scanTypesDef
    // this.scanTypesDef = scanTypesDef;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Fix for New Scan from menu in same page
  ngOnInit(): void {
    this._router.onSameUrlNavigation = 'reload';
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;

    // Gets the scan types from Firebase
    this.getScanTypes();
    // Gets the plans from Firebase
    this.getPlans();
  }

  // Dissmis the snackbar onDestroy
  ngOnDestroy(): void {
    this._snackBar.dismiss();

    // Fix for New Scan from menu in same page
    this._router.onSameUrlNavigation = 'ignore';
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Gets the scan types from Firebase
  getScanTypes(): void {
    this._fuseProgressBarService.show();
    this._firebaseService.getScanTypesFirebase()
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(scanTypes => {
        this.scanTypes = scanTypes;
        this.loadedScans = true;
        this.scanTypes.sort((a, b) => {
          return a.DisplayPosition - b.DisplayPosition;
        });
        this._fuseProgressBarService.hide();
      });
  }

  // Gets the plans from Firebase
  getPlans(): void {
    this._fuseProgressBarService.show();
    this._firebaseService.getPlansFirebase()
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(plans => {
        this.plans = plans;
        this.loadedPlans = true;
        this.plans.sort((a, b) => {
          return a.DisplayPosition - b.DisplayPosition;
        });
        this._fuseProgressBarService.hide();
      });
  }

  // Prevents Default and go to URL
  goToURL(event: any, url: string): void {
    event.stopPropagation();
    window.open(url, '_blank');
  }

  // Scrolls to top and focus search input
  // selectScan(scanType: string): void {
  //   this.selectedscanType = scanType;
  //   const validSearchField = this.validateSearchField(this.searchInput, 400);
  //   if (validSearchField) {
  //     this._fuseProgressBarService.show();
  //     this._openbashService.checkURL(this.searchInput)
  //       .pipe(
  //         takeUntil(this._unsubscribeAll),
  //         finalize(() => this._fuseProgressBarService.hide())
  //       ).subscribe(
  //         (response) => {
  //           if (response.status === 1) {
  //             this._router.navigateByUrl('/scan-select', { state: { url: this.searchInput, selectedscanType: this.selectedscanType } });
  //           } else {
  //             // console.log(response);
  //             // Focus search field
  //             this.scrollFocusSearchField(400);
  //             // Error snackbar
  //             this._uiFunctionsService.presentSnackbar(this._snackBar, 'Please enter a valid URL.', undefined);
  //           }
  //         },
  //         (error) => {
  //           console.error('Error caught in component.');
  //           // Error snackbar
  //           this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error in your request. Please try again.', undefined);
  //           throw error;
  //         }
  //       );
  //   }
  // }

  // Starts the scan
  // startScan(): void {
  //   const validSearchField = this.validateSearchField(this.searchInput, 400);
  //   if (validSearchField) {
  //     if (this.selectedscanType) {
  //       this._fuseProgressBarService.show();
  //       this._openbashService.checkURL(this.searchInput)
  //         .pipe(
  //           takeUntil(this._unsubscribeAll),
  //           finalize(() => this._fuseProgressBarService.hide())
  //         ).subscribe(
  //           (response) => {
  //             if (response.status === 1) {
  //               // Go to scan-select section
  //               this._router.navigateByUrl('/scan-select', { state: { url: this.searchInput, selectedscanType: this.selectedscanType } });
  //             } else {
  //               // console.log(response);
  //               // Focus search field
  //               this.scrollFocusSearchField(400);
  //               // Error snackbar
  //               this._uiFunctionsService.presentSnackbar(this._snackBar, 'Please enter a valid URL.', undefined);
  //             }
  //           },
  //           (error) => {
  //             console.error('Error caught in component.');
  //             // Error snackbar
  //             this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error in your request. Please try again.', undefined);
  //             throw error;
  //           }
  //         );
  //     } else {
  //       // Snackbar with button scroll to select scan
  //       this._uiFunctionsService.presentSnackbar(this._snackBar, 'Please select a valid type of scan.', undefined);
  //       // Scrolls to scan select section
  //       this._appComponent.scrollToElement('.price-tables');
  //     }
  //   }
  // }

  // Validates and focus the search field
  // validateSearchField(searchField: string, focusSpeed: number): boolean {
  //   // console.log(this._uiFunctionsService.isValidUrl(searchField));
  //   if (searchField && this._uiFunctionsService.isValidUrl(searchField)) {
  //     return true;
  //   } else {
  //     // Focus search field
  //     this.scrollFocusSearchField(focusSpeed);

  //     // Snackbar enter url (check if validation is done here)
  //     this._uiFunctionsService.presentSnackbar(this._snackBar, 'Please enter a valid URL.', undefined);
  //   }
  // }

  // Scrolls and focus search field
  // scrollFocusSearchField(focusSpeed: number): void {
  //   this._appComponent.scrollToElement('.content');
  //   setTimeout(() => {
  //     this.searchinputField.nativeElement.focus();
  //   }, focusSpeed);
  // }

  // Dismisses the snackbar
  dismissSnackbar(): void {
    this._snackBar.dismiss();
  }
}
