import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'scan-error',
    templateUrl: './scan-error.component.html',
    styleUrls: ['./scan-error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScanErrorComponent implements OnDestroy {
    /**
     * Constructor
     * @param {MatSnackBar} _snackBar
     */
    constructor(
        private _snackBar: MatSnackBar
    ) { }

    ngOnDestroy(): void {
        // Dissmis the snackbar onDestroy
        this._snackBar.dismiss();
    }
}
