import { Component, ViewEncapsulation, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { LocalStorageService } from 'app/main/services/localstorage/localstorage-service.service';
import { Subject } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { FirebaseService } from 'app/main/services/firebase/firebase.service';
import { User } from 'app/main/models/users';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UiFunctionsService } from 'app/main/services/ui-functions/ui-functions.service';
import { TranslationService } from 'app/main/services/translation/translation.service';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProfileComponent implements OnInit, OnDestroy {

  user = JSON.parse(localStorage.getItem('user')!);

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
     *
     * @param {LocalStorageService} _localStorageService
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseProgressBarService} _fuseProgressBarService
     * @param {FirebaseService} _firebaseService
     * @param {MatSnackBar} _snackBar
     * @param {UiFunctionsService} _uiFunctionsService
     * @param {TranslationService} _translationService
     */
  constructor(
    private _localStorageService: LocalStorageService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseProgressBarService: FuseProgressBarService,
    private _firebaseService: FirebaseService,
    private _snackBar: MatSnackBar,
    private _uiFunctionsService: UiFunctionsService,
    private _translationService: TranslationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    // Event listener for storage changes
    this._localStorageService.getStorageObservable()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((key: string) => {
      if (key === 'user') {
        this.user = JSON.parse(localStorage.getItem(key)!);
        this._changeDetectorRef.detectChanges();
      }
    });

    // this._translationService.changeLanguage('es');
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Change File
  onFileSelected(fileInput: File) {
    if (fileInput) {
      if (fileInput.size < 5242880) {
        this._fuseProgressBarService.show();
        this._firebaseService.uploadImage(this.user.uid, 'profile', fileInput)
          .then(url => {
            const userData: User = {
              uid: this.user.uid,
              email: this.user.email,
              photoURL: url
            }

            this._firebaseService.updateUser(userData)
              .then(() => {
                // Success snackbar
                this._translationService.getTranslationString('Profile picture updated succesfully.')
                  .pipe(take(1))
                  .subscribe((translatedString) => {
                    this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                  });
                this._fuseProgressBarService.hide();
              })
              .catch((error) => {
                this._translationService.getTranslationString(error)
                  .pipe(take(1))
                  .subscribe((translatedString) => {
                    this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                  });
                this._fuseProgressBarService.hide();
              });
          })
          .catch((error) => {
            this._translationService.getTranslationString(error)
              .pipe(take(1))
              .subscribe((translatedString) => {
                this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
              });
            this._fuseProgressBarService.hide();
          });
      } else {
        this._translationService.getTranslationString('File size cannot exceed 5MB.')
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
      }
    }
  }
}
