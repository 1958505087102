import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { OpenbashService } from '../../services/openbash/openbash.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LogsDialogComponent } from './logs-dialog/logs-dialog.component';
import { Subject, Observable } from 'rxjs';
import { takeUntil, takeWhile, finalize, take } from 'rxjs/operators';
import { UiFunctionsService } from '../../services/ui-functions/ui-functions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { ScanTypes } from '../../models/scan-types';
import { ComponentCanDeactivate } from '../../guards/exit-guard';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from '../../../../environments/environment';
import { FirebaseService } from '../../services/firebase/firebase.service';
import { StopScanDialogComponent } from './stop-scan-dialog/stop-scan-dialog.component';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
  animations: [
    // Define the custom animation trigger
    trigger('animateText', [
      // Defining the hidden state
      state('hidden', style({
        opacity: 0
      })),
      // Defining the visible state
      state('visible', style({
        opacity: 1
      })),
      // Defining the animation for state changes
      transition('hidden <=> visible', [
        animate('0.5s ease')
      ])
    ])
  ]
})
export class ScanComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  @BlockUI() blockUI: NgBlockUI;

  hash: string = null;
  url: string = null;
  currentScanStatus: string = null;
  currentScanType: ScanTypes = {
    slug: null,
    title: null,
    subtitle: null,
    shortDescription: null,
    description: null,
    cssElevationClass: null,
    price: null,
    versions: [],
    activationText: null
  };
  currentPercentage = '0';
  currentLog: string = null;
  dialogRef: MatDialogRef<LogsDialogComponent>;
  title = 'Scan in progress...';
  lastLine = 'Retrieving information...';
  tempLastLine: string = null;
  loading = true;
  currentStateAnimation = 'hidden';
  valueFromRoute = false;
  invalidHash = false;
  reportToken: string = null;
  mobile: boolean = environment.mobile;

  stopScanDialog: MatDialogRef<StopScanDialogComponent>;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   * @param {OpenbashService} _openbashService
   * @param {UiFunctionsService} _uiFunctionsService
   * @param {MatSnackBar} _snackBar
   * @param {MatDialog} _dialog
   * @param {Clipboard} _clipboard
   * @param {ActivatedRoute} _activatedRoute
   * @param {Router} _router
   * @param {FuseProgressBarService} _fuseProgressBarService 
   * @param {AuthService} _authService
   * @param {FirebaseService} _firebaseService
   */
  constructor(
    private _openbashService: OpenbashService,
    private _uiFunctionsService: UiFunctionsService,
    private _snackBar: MatSnackBar,
    protected _dialog: MatDialog,
    private _clipboard: Clipboard,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _fuseProgressBarService: FuseProgressBarService,
    private _firebaseService: FirebaseService
  ) {
    // this._fuseSplashScreenService.show();
    // this.blockUI.start();
    // State from URL
    if (this._activatedRoute.snapshot.paramMap.get('hash')) {
      this.hash = this._activatedRoute.snapshot.paramMap.get('hash');
      this.valueFromRoute = true;
    } else {
      // Puts invalidHash as true and then redirects to home
      this.invalidHash = true;
      this._router.navigate(['404'], { state: { bypassFormGuard: true } });
    }

    // Dismiss snackbar when starting navigation to other page, dismisses before dialog load
    _router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this._snackBar.dismiss();
        }
      });

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Gets initial progress
  ngOnInit(): void {
    // Value from route
    if (this.valueFromRoute) {
      this.getTarget();
    }

    this._firebaseService.getScanByHash(this.hash)
      .pipe(
        takeUntil(this._unsubscribeAll),
        takeWhile(() => (Number(this.currentPercentage) < 100) && this.currentScanStatus != 'stopped', true)
      )
      .subscribe(scan => {
        // console.log('subscribe');
        // console.log(scan);
        if (scan.length > 0) {
          if (+scan[0].progress === 99) {
            // Get scan token
            this.reportToken = scan[0].report_token;
            this.currentPercentage = '100';
            this.title = 'Scan complete';
          } else {
            const progress = scan[0].progress.replace('%', '');
            this.currentPercentage = progress;
            // If target was stopped (status = 'finished'|'stopped' && progress != 99)
            if (this.currentScanStatus === 'stopped') {
              this.title = 'Scan stopped.';
            }
          }
          if (scan[0].log) {
            this.updateProgressLogs(scan[0].log);
          }
        }

        // this.blockUI.stop();
      });
  }

  ngOnDestroy(): void {
    // Dissmis the snackbar onDestroy
    this._snackBar.dismiss();

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Adds % to percentage
  formatPercentage(): string {
    return this.currentPercentage + '%';
  }

  // Updates scan logs
  updateProgressLogs(log: string): void {
    this.currentLog = log.replaceAll('\\n', '\n');
    const previousLastLine = this.lastLine;
    this.tempLastLine = this.getLastLine(this.currentLog);
    if (previousLastLine !== this.tempLastLine) {
      this.currentStateAnimation = 'hidden';
    }
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.currentLog = this.currentLog;
      if (previousLastLine !== this.tempLastLine) {
        this.dialogRef.componentInstance.scrollToBottom();
      }
    }
  }


  // Opens the logs dialog
  openLogDialog(): void {
    // Dissmis current snackbar
    this._snackBar.dismiss();
    this.dialogRef = this._dialog.open(LogsDialogComponent, {
      data: {
        log: this.currentLog,
        hash: this.hash
      }
    });
    this.dialogRef.afterOpened()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this.dialogRef && this.dialogRef.componentInstance) {
          this.dialogRef.componentInstance.scrollToBottom();
        }
      });
  }

  // Gets the las line of a string
  getLastLine(text: string): string {
    return text.substring(text.substring(0, text.length - 1).lastIndexOf('\n') + 1, text.length - 1);
  }

  // Animation for last line change 
  lastLineAnimationFinished(event: AnimationEvent): void {
    if (event.fromState === 'void' && event.toState === 'hidden') {
      this.currentStateAnimation = 'visible';
    } else if (event.fromState === 'visible' && event.toState === 'hidden') {
      this.lastLine = this.tempLastLine;
      this.currentStateAnimation = 'visible';
    }
  }

  // Stop scan
  stopScan(): void {
    this.stopScanDialog = this._dialog.open(StopScanDialogComponent);
    this.stopScanDialog.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result === true) {
          // Stops the scan
          this._fuseProgressBarService.show();
          this.blockUI.start();
          this._openbashService.stopScan(this.hash)
            .pipe(
              takeUntil(this._unsubscribeAll),
              finalize(() => {
                this._fuseProgressBarService.hide();
                this.blockUI.stop();
              })
            ).subscribe(
              (response) => {
                if (response.status === 1) {
                  // console.log(response);
                  // Updates firebase scan table with current task and stopped
                  this._firebaseService.stopScan(response.info.hash)
                    .then(() => {
                      this.currentScanStatus = 'stopped';
                      this.title = 'Scan stopped.';
                      // Success snackbar
                      this._uiFunctionsService.presentSnackbar(this._snackBar, 'You stopped the scan.', undefined);
                    })
                } else {
                  // console.log(response);
                  // Error snackbar
                  this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error in your request. Please try again.', undefined);
                }
              },
              (error) => {
                console.error('Error caught in component.');
                // Error snackbar
                this._uiFunctionsService.presentSnackbar(this._snackBar, 'There was an error in your request. Please try again.', undefined);
                throw error;
              }
            );
        }
      });
  }

  // Gets current url with hash
  getHashURL(): string {
    return this._openbashService.rootURL + '/scan/' + this.hash;
  }

  // Gets target with hash and updates url and scan type
  getTarget(): void {
    this._firebaseService.getScanByHash(this.hash)
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
          this.blockUI.stop();
        })
      ).subscribe(
        (scan) => {
          // console.log('gettarget');
          // console.log(scan);
          if (scan.length > 0) {
            this.url = scan[0].url;
            this.currentScanStatus = scan[0].status;
            let scanID = Number(scan[0].scanner_id);
            if (scanID != 9 && scanID != 11 && scanID != 10 && scanID != 12 && scanID != 13 ) {
              this._firebaseService.getScanTypesFirebase()
              .pipe(
                takeUntil(this._unsubscribeAll)
              )
              .subscribe(scanTypes => {
                this.currentScanType.title = scanTypes.find(scanType => scanType.ID === scanID).Name;
                this.currentScanType.description = scanTypes.find(scanType => scanType.ID === scanID).ShortDescription;
              });
            } else {
              this.setScanType(Number(scan[0].scanner_id));
            }

          } else {
            // Puts invalidHash as true if hash not exists and then redirects to home
            this.invalidHash = true;
            this._router.navigateByUrl('/scan-error/' + this.hash, { state: { bypassFormGuard: true } });
          }
        }
      );
  }

  // Copy to clipboard with snackbar
  copyHashURL(hashURL: string): void {
    const pending = this._clipboard.beginCopy(hashURL);
    let remainingAttempts = 5;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        // Success snackbar
        this._uiFunctionsService.presentSnackbar(this._snackBar, 'URL copied to clipboard.', undefined);
        pending.destroy();
      }
    };
    attempt();
  }

  // Sets the current Scan Type
  setScanType(id: number): void {
    this.currentScanType = this._uiFunctionsService.setScanTypeByID(id);
  }

  // CanDeactivate for page unload
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    if (!this.mobile) {
      if (!this.invalidHash) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

}
