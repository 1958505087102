<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/openbash-color.svg">
            </div>

            <div class="title">{{ 'RECOVER YOUR PASSWORD' | translate | async }}</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate (ngSubmit)="submitForgotPasswordForm()">

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Email' | translate | async }}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        {{ 'Email is required' | translate | async }}
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        {{ 'Please enter a valid email address' | translate | async }}
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent" aria-label="SEND RESET LINK"
                    [disabled]="forgotPasswordForm.invalid">
                    {{ 'SEND RESET LINK' | translate | async }}
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="nav-link" routerLink="/login">{{ 'Go back to login' | translate | async }}</a>
            </div>

        </div>

    </div>

</div>