import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MyScansService } from 'app/main/services/my-scans/my-scans.service';
import { FirebaseService } from 'app/main/services/firebase/firebase.service';
import { ConfirmationDialogComponent } from '../../my-scans/confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UiFunctionsService } from 'app/main/services/ui-functions/ui-functions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthService } from 'app/main/services/auth/auth.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { User } from '../../../models/users';
import { PasswordConfirmationComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { LocalStorageService } from 'app/main/services/localstorage/localstorage-service.service';
import { environment } from 'environments/environment';
import { Color } from '@angular-material-components/color-picker';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { OpenbashService } from 'app/main/services/openbash/openbash.service';
import * as countries from "i18n-iso-countries";
import { TranslationService } from 'app/main/services/translation/translation.service';

@Component({
  selector: 'profile-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class PersonalInformationComponent implements OnInit, OnDestroy {

  @ViewChild('fileInput') fileInputRef!: ElementRef;
  imagePreview: string | null = null;
  mobile: boolean = environment.mobile;
  user: any = JSON.parse(localStorage.getItem('user')!);
  myScans = [];
  plans = [];
  currentPlan: string = '';
  totalScans = 0;
  updatePersonalInformationForm: FormGroup;
  updateCompanyInformationForm: FormGroup;
  updateReportInformationForm: FormGroup;
  updateAdvancedInformationForm: FormGroup;
  reportLanguages = [{ language: 'English', code: 'EN' }, { language: 'Spanish', code: 'ES' }];
  colorCtr1: AbstractControl = new FormControl(null);
  colorCtr1Subscription: Subscription;
  colorCtr2Subscription: Subscription;
  colorCtr2: AbstractControl = new FormControl(null);
  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;
  passwordConfirmationDialog: MatDialogRef<PasswordConfirmationComponent>;
  countryList: { code: string; name: string }[] = [];
  private langSubscription!: Subscription;
  reportImageName: string = 'Select image (512x512 - Max filesize: 5MB)';
  customApiTokenVisible: string = '';
  customApiTokenReal: string = '';
  // Private
  private _unsubscribeAll: Subject<any>;

  // purchasedCredits
  // subscriptionCredits
  // subscription
  // maxConcurrent

  // Stats
  // total scans

  /**
   * Constructor
   *
   * @param {MyScansService} _myScans
   * @param {FirebaseService} _firebaseService
   * @param {FuseProgressBarService} _fuseProgressBarService
   * @param {MatDialog} _dialog
   * @param {UiFunctionsService} _uiFunctionsService
   * @param {MatSnackBar} _snackBar
   * @param {Clipboard} _clipboard
   * @param {FormBuilder} _formBuilder
   * @param {LocalStorageService} _localStorageService
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {ReCaptchaService} _recaptchaService
   * @param {OpenbashService} _openbashService
   * @param {TranslationService} _translationService
   */
  constructor(
    // private _profileService: ProfileService
    private _myScans: MyScansService,
    private _firebaseService: FirebaseService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _dialog: MatDialog,
    private _uiFunctionsService: UiFunctionsService,
    private _snackBar: MatSnackBar,
    private _clipboard: Clipboard,
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _localStorageService: LocalStorageService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _recaptchaService: ReCaptchaV3Service,
    private _openbashService: OpenbashService,
    private _translationService: TranslationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Gets Scans
    this.getScans();

    // Get Plans
    this.getPlans();

    // Defines countries select langugage y traduce el report image placeholder
    this.langSubscription = this._translationService.currentLang$.
      subscribe(lang => {
        this.loadCountries(lang);
        this._translationService.getTranslation(this.reportImageName)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(response => {
            if (response?.data?.translations) {
              this.updateReportInformationForm.get('reportImage')?.setValue(response.data.translations[0].translatedText);
            }
          });
      });

    // Cargar países inicialmente
    this.loadCountries(this._translationService.getCurrentLanguage());

    // Event listener for storage changes
    this._localStorageService.getStorageObservable()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((key: string) => {
        if (key === 'user') {
          this.user = JSON.parse(localStorage.getItem(key)!);
          this.buidForms('update');
          this.maskCustomApiToken();
          this._changeDetectorRef.detectChanges();
        }
      });

    // Forms
    this.buidForms('init');

    // Custom API Token Mask
    this.customApiTokenReal = this.user.customApiToken;
    this.maskCustomApiToken();
  }

  loadCountries(lang: string) {
    countries.registerLocale(require(`i18n-iso-countries/langs/${lang}.json`)); // Carga la traducción del idioma
    this.countryList = Object.entries(countries.getNames(lang, { select: 'official' }))
      .map(([code, name]) => ({ code, name }));
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }

    this._snackBar.dismiss();
  }

  // Builds the form
  buidForms(operation): void {
    if (operation == 'init') {
      this.updatePersonalInformationForm = this._formBuilder.group({
        displayName: [this.user.displayName, [Validators.required, Validators.pattern(/^(?!.*\s{2})[a-zA-Z0-9\s]+$/)]],
        telegramNick: [this.user.telegramNick, [Validators.pattern(/^(?!\d)[a-zA-Z0-9_]{5,32}$/)]],
        phone: [this.user.phone, [Validators.pattern(/^\+?[0-9]+$/)]]
      });
    } else {
      if (!this.updatePersonalInformationForm.dirty) {
        this.updatePersonalInformationForm.setValue({
          displayName: this.user.displayName ?? null,
          telegramNick: this.user.telegramNick ?? null,
          phone: this.user.phone ?? null
        });
      }
    }

    if (operation == 'init') {
      this.updateCompanyInformationForm = this._formBuilder.group({
        companyName: [this.user.companyName, [Validators.pattern(/^(?!.*\s{2})[a-zA-Z0-9\s]+$/)]],
        companyCountry: [this.user.companyCountry],
        companyURL: [this.user.companyURL, [Validators.pattern(/^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/[^\s]*)?$/)]],
        companyAddress: [this.user.companyAddress, [Validators.pattern(/^(?!.*\s{2})[a-zA-Z0-9\s]+$/)]]
      });
    } else {
      if (!this.updateCompanyInformationForm.dirty) {
        this.updateCompanyInformationForm.setValue({
          companyName: this.user.companyName ?? null,
          companyCountry: this.user.companyCountry ?? null,
          companyURL: this.user.companyURL ?? null,
          companyAddress: this.user.companyAddress ?? null
        });
      }
    }

    // let reportImageName = 'Select image (512x512 - Max filesize: 5MB)';
    if (this.user.reportPhotoURL) {
      const segments = this.user.reportPhotoURL.split('/');
      this.reportImageName = decodeURIComponent(segments.pop().split('?')[0]).split('/')[2];
    }
    if (operation == 'init') {
      this.updateReportInformationForm = this._formBuilder.group({
        reportImage: [this.reportImageName],
        reportLanguage: this.user.reportLanguage,
        reportColor1: this.colorCtr1,
        reportColor2: this.colorCtr2,
        newScanEnabled: [this.user.newScanEnabled],
        defaultAuth: [this.user.defaultAuth]
      });
    } else {
      if (!this.updateReportInformationForm.dirty) {
        this.updateReportInformationForm.setValue({
          reportImage: this.reportImageName ?? null,
          reportLanguage: this.user.reportLanguage ?? null,
          reportColor1: this.colorCtr1,
          reportColor2: this.colorCtr2,
          newScanEnabled: this.user.newScanEnabled ?? null,
          defaultAuth: this.user.defaultAuth ?? null
        });
      }
    }

    if (!this.updateReportInformationForm.dirty) {
      if (this.user.reportColor1) {
        const tempColor1 = this._uiFunctionsService.hexToRgb(this.user.reportColor1);
        this.colorCtr1 = new FormControl(null);
        this.colorCtr1.setValue(new Color(tempColor1.r, tempColor1.g, tempColor1.b));
        this.colorCtr1Subscription = this.colorCtr1.valueChanges.subscribe((color) => {
          this.updateReportInformationForm.markAsDirty();
        });
      } else {
        this.colorCtr1 = new FormControl(null);
        this.colorCtr1Subscription = this.colorCtr1.valueChanges.subscribe((color) => {
          this.updateReportInformationForm.markAsDirty();
        });
      }
      if (this.user.reportColor2) {
        const tempColor2 = this._uiFunctionsService.hexToRgb(this.user.reportColor2);
        this.colorCtr2 = new FormControl(null);
        this.colorCtr2.setValue(new Color(tempColor2.r, tempColor2.g, tempColor2.b));
        this.colorCtr2Subscription = this.colorCtr2.valueChanges.subscribe((color) => {
          this.updateReportInformationForm.markAsDirty();
        });
      } else {
        this.colorCtr2 = new FormControl(null);
        this.colorCtr2Subscription = this.colorCtr2.valueChanges.subscribe((color) => {
          this.updateReportInformationForm.markAsDirty();
        });
      }
    }

    if (operation == 'init') {
      this.updateAdvancedInformationForm = this._formBuilder.group({
        apiEndpoint: [this.user.apiEndpoint, [Validators.pattern(/^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/[^\s]*)?$/)]],
        customApiToken: [this.customApiTokenReal, [Validators.pattern(/^[a-zA-Z0-9\-_.~+/=]+$/)]],
        emailNotifications: [this.user.emailNotifications],
        telegramNotifications: [this.user.telegramNotifications],
        whatsappNotifications: [this.user.whatsappNotifications],
        apiNotifications: [this.user.apiNotifications]
      });
    } else {
      if (!this.updateAdvancedInformationForm.dirty) {
        this.updateAdvancedInformationForm.setValue({
          apiEndpoint: this.user.apiEndpoint ?? null,
          customApiToken: this.customApiTokenVisible ?? null,
          emailNotifications: this.user.emailNotifications ?? null,
          telegramNotifications: this.user.telegramNotifications ?? null,
          whatsappNotifications: this.user.whatsappNotifications ?? null,
          apiNotifications: this.user.apiNotifications ?? null
        });
      }
    }
  }

  // Sets the preview gradient
  get gradientStyle() {
    let reportColor1 = null;
    let reportColor2 = null;
    if (this.colorCtr1.value?.hex) {
      reportColor1 = '#' + this.colorCtr1.value?.hex;
    }
    if (this.colorCtr2.value?.hex) {
      reportColor2 = '#' + this.colorCtr2.value?.hex;
    }
    return {
      'background': `linear-gradient(60deg, ${reportColor1}, ${reportColor2})`
    };
  }

  // Gets Scans
  getScans(): void {
    this._myScans.getLastScans().then(
      (myScans) => {
        myScans.sort((a, b) => {
          return new Date(b.init_datetime).getTime() - new Date(a.init_datetime).getTime();
        });
        this.myScans = myScans;
        this.getTotalScans();
      }
    );
  }

  // Gets Plans
  getPlans(): void {
    this._firebaseService.getPlansFirebase()
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(plans => {
        this.plans = plans;
        this.currentPlan = this.plans.find(plan => plan.planID === this.user.subscription[0].planId).title;
      });
  }

  // Gets Total Scans
  getTotalScans(): void {
    this._firebaseService.getTotalScans()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(querySnapshot => {
        this.totalScans = querySnapshot.size;
      });
  }

  // Checks provider data
  checkProvider(): Boolean {
    if (this.user.providerData.some(providerData => providerData.providerId === 'openbash.com') || this.user.providerData.some(providerData => providerData.providerId === 'password')) {
      return true;
    } else {
      return false;
    }
  }

  // Deletes a scan
  deleteScan(event, id: string, status: string): void {
    this._snackBar.dismiss();
    event.stopPropagation();
    this.confirmationDialog = this._dialog.open(ConfirmationDialogComponent);
    this.confirmationDialog.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result === true) {
          this._fuseProgressBarService.show();
          this._firebaseService.deleteScan(id, status).then(() => {
            this.getScans();
          });
        }
      });
  }

  // Copy api key to clipboard
  copyApiKey(apiKey: string): void {
    const pending = this._clipboard.beginCopy(apiKey);
    let remainingAttempts = 5;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        // Success snackbar
        this._translationService.getTranslationString('API Key copied to clipboard.')
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
        pending.destroy();
      }
    };
    attempt();
  }

  // Send forgot password email for reset
  resetPassword(): void {
    this._snackBar.dismiss();
    this.passwordConfirmationDialog = this._dialog.open(PasswordConfirmationComponent, {
      data: {
        title: 'Reset Password',
        description: 'Are you sure you want to reset your password?',
      }
    });
    this.passwordConfirmationDialog.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result === true) {
          this._authService.forgotPassword(this.user.email);
        }
      });
  }

  // Submits update personal information form
  submitUpdatePersonalInformationForm(): void {
    const userData: User = {
      uid: this.user.uid,
      email: this.user.email,
      displayName: this.updatePersonalInformationForm.get('displayName').value,
      telegramNick: this.updatePersonalInformationForm.get('telegramNick').value,
      phone: this.updatePersonalInformationForm.get('phone').value
    }
    this._fuseProgressBarService.show();
    this._firebaseService.updateUser(userData)
      .then(() => {
        // Success snackbar
        this.updatePersonalInformationForm.markAsPristine();
        this._translationService.getTranslationString('Account information updated succesfully.')
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
        this._fuseProgressBarService.hide();
      })
      .catch((error) => {
        this._translationService.getTranslationString(error)
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
        this._fuseProgressBarService.hide();
      });
  }

  // Submits update company information form
  submitUpdateCompanyInformationForm(): void {
    const userData: User = {
      uid: this.user.uid,
      email: this.user.email,
      companyName: this.updateCompanyInformationForm.get('companyName').value,
      companyCountry: this.updateCompanyInformationForm.get('companyCountry').value,
      companyURL: this.updateCompanyInformationForm.get('companyURL').value,
      companyAddress: this.updateCompanyInformationForm.get('companyAddress').value
    }
    this._fuseProgressBarService.show();
    this._firebaseService.updateUser(userData)
      .then(() => {
        // Success snackbar
        this.updateCompanyInformationForm.markAsPristine();
        this._translationService.getTranslationString('Company information updated succesfully.')
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
        this._fuseProgressBarService.hide();
      })
      .catch((error) => {
        this._translationService.getTranslationString(error)
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
        this._fuseProgressBarService.hide();
      });
  }

  // Submits update report form
  submitUpdateReportInformationForm(fileInput: File): void {
    this._fuseProgressBarService.show();
    let reportColor1 = null;
    let reportColor2 = null;
    if (this.colorCtr1.value?.hex) {
      reportColor1 = '#' + this.colorCtr1.value?.hex;
    }
    if (this.colorCtr2.value?.hex) {
      reportColor2 = '#' + this.colorCtr2.value?.hex;
    }
    if (!fileInput) {
      const userData: User = {
        uid: this.user.uid,
        email: this.user.email,
        reportLanguage: this.updateReportInformationForm.get('reportLanguage').value,
        reportColor1: reportColor1,
        reportColor2: reportColor2,
        newScanEnabled: this.updateReportInformationForm.get('newScanEnabled').value,
        defaultAuth: this.updateReportInformationForm.get('defaultAuth').value
      }
      this._firebaseService.updateUser(userData)
        .then(() => {
          if (this.colorCtr1Subscription) {
            this.colorCtr1Subscription.unsubscribe();
          }
          if (this.colorCtr2Subscription) {
            this.colorCtr2Subscription.unsubscribe();
          }
          this.updateReportInformationForm.markAsPristine();
          // Success snackbar
          this._translationService.getTranslationString('Report information updated succesfully.')
            .pipe(take(1))
            .subscribe((translatedString) => {
              this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
            });
          this._fuseProgressBarService.hide();
        })
        .catch((error) => {
          this._translationService.getTranslationString(error)
            .pipe(take(1))
            .subscribe((translatedString) => {
              this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
            });
          this._fuseProgressBarService.hide();
        });

    } else {
      this._firebaseService.uploadImage(this.user.uid, 'report', fileInput)
        .then(url => {
          const userData: User = {
            uid: this.user.uid,
            email: this.user.email,
            reportLanguage: this.updateReportInformationForm.get('reportLanguage').value,
            reportPhotoURL: url,
            reportColor1: reportColor1,
            reportColor2: reportColor2,
            newScanEnabled: this.updateReportInformationForm.get('newScanEnabled').value,
            defaultAuth: this.updateReportInformationForm.get('defaultAuth').value
          }

          this._firebaseService.updateUser(userData)
            .then(() => {
              if (this.colorCtr1Subscription) {
                this.colorCtr1Subscription.unsubscribe();
              }
              if (this.colorCtr2Subscription) {
                this.colorCtr2Subscription.unsubscribe();
              }
              this.updateReportInformationForm.markAsPristine();
              // Success snackbar
              this._translationService.getTranslationString('Report information updated succesfully.')
                .pipe(take(1))
                .subscribe((translatedString) => {
                  this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                });
              this._fuseProgressBarService.hide();
            })
            .catch((error) => {
              this._translationService.getTranslationString(error)
                .pipe(take(1))
                .subscribe((translatedString) => {
                  this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                });
              this._fuseProgressBarService.hide();
            });
        })
        .catch((error) => {
          this._translationService.getTranslationString(error)
            .pipe(take(1))
            .subscribe((translatedString) => {
              this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
            });
          this._fuseProgressBarService.hide();
        });
    }
  }

  // Submits update advanced information form
  submitUpdateAdvancedInformationForm(): void {
    const userData: User = {
      uid: this.user.uid,
      email: this.user.email,
      apiEndpoint: this.updateAdvancedInformationForm.get('apiEndpoint').value,
      customApiToken: this.updateAdvancedInformationForm.get('customApiToken').value,
      emailNotifications: this.updateAdvancedInformationForm.get('emailNotifications').value,
      telegramNotifications: this.updateAdvancedInformationForm.get('telegramNotifications').value,
      whatsappNotifications: this.updateAdvancedInformationForm.get('whatsappNotifications').value,
      apiNotifications: this.updateAdvancedInformationForm.get('apiNotifications').value
    }
    this._fuseProgressBarService.show();
    this._firebaseService.updateUser(userData)
      .then(() => {
        // Success snackbar
        this.customApiTokenVisible = this.customApiTokenReal;
        const tokenControl = this.updateAdvancedInformationForm.get('customApiToken');
        tokenControl.setValue(this.customApiTokenReal);
        tokenControl.markAsDirty();
        tokenControl.markAsTouched();
        tokenControl.updateValueAndValidity();
        this.updateAdvancedInformationForm.markAsPristine();    
        this._translationService.getTranslationString('Advanced information updated succesfully.')
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
        this._fuseProgressBarService.hide();
      })
      .catch((error) => {
        this._translationService.getTranslationString(error)
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
        this._fuseProgressBarService.hide();
      });
  }

  // Change File
  onFileSelected(fileInput: File) {
    if (fileInput) {
      if (fileInput.size < 5242880) {
        const reader = new FileReader();

        reader.onload = (event: any) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            if (img.width === 512 && img.height === 512) {
              this.updateReportInformationForm.markAsDirty();
              this.updateReportInformationForm.controls['reportImage'].setValue(fileInput.name);

              // Image Preview
              this.imagePreview = event.target.result;
            } else {
              this.fileInputRef.nativeElement.value = '';
              this._translationService.getTranslationString('Image must be exactly 512x512 pixels.')
                .pipe(take(1))
                .subscribe((translatedString) => {
                  this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                });
            }
          };
        };

        reader.readAsDataURL(fileInput);
      } else {
        this.fileInputRef.nativeElement.value = '';
        this._translationService.getTranslationString('File size cannot exceed 5MB.')
          .pipe(take(1))
          .subscribe((translatedString) => {
            this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
          });
      }
    }
  }

  // Generates reCaptchaToken
  generatereCaptcha(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._recaptchaService.execute('performScan')
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (token) => {
            // Validates reCaptcha
            resolve(token);
          },
          (error) => {
            console.error('Error caught in component.');
            // Error snackbar
            this._translationService.getTranslationString('There was an error with reCAPTCHA. Please try again.')
              .pipe(take(1))
              .subscribe((translatedString) => {
                this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
              });
            reject(error);
          }
        );
    });
  }

  // Regenerates the Api Key
  regenerateApiKey(): void {
    this._snackBar.dismiss();
    this.passwordConfirmationDialog = this._dialog.open(PasswordConfirmationComponent, {
      data: {
        title: 'Regenerate Api Key',
        description: 'Are you sure you want to regenerate your api key?',
      }
    });
    this.passwordConfirmationDialog.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
        if (result === true) {
          this.generatereCaptcha().then((token) => {
            const regenerateApiKeyObject = {
              uid: this.user.uid,
              token: token
            }

            this._fuseProgressBarService.show();
            this._openbashService.regenerateApiKey(regenerateApiKeyObject)
              .pipe(
                takeUntil(this._unsubscribeAll)
              ).subscribe(
                (response) => {
                  if (response.status !== 1) {
                    this._fuseProgressBarService.hide();
                    // Error snackbar
                    this._translationService.getTranslationString(response.error)
                      .pipe(take(1))
                      .subscribe((translatedString) => {
                        this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                      });
                  } else {
                    this._fuseProgressBarService.hide();
                    this._translationService.getTranslationString('Api Key updated succesfully.')
                      .pipe(take(1))
                      .subscribe((translatedString) => {
                        this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                      });
                  }
                },
                (error) => {
                  this._fuseProgressBarService.hide();
                  console.error('Error caught in component.');
                  // Error snackbar
                  this._translationService.getTranslationString('There was an error in your request. Please try again.')
                    .pipe(take(1))
                    .subscribe((translatedString) => {
                      this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                    });
                  throw error;
                }
              );
          });
        }
      });
  }

  // Restores Report information form to defaults
  restoreDefaults(): void {
    this._snackBar.dismiss();
    this.passwordConfirmationDialog = this._dialog.open(PasswordConfirmationComponent, {
      data: {
        title: 'Restore Defaults',
        description: 'Are you sure you want to restore to defaults report information?',
      }
    });
    this.passwordConfirmationDialog.afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result === true) {
          const userData: User = {
            uid: this.user.uid,
            email: this.user.email,
            reportLanguage: null,
            reportPhotoURL: null,
            reportColor1: null,
            reportColor2: null,
            newScanEnabled: null,
            defaultAuth: null
          }
          this._firebaseService.updateUser(userData)
            .then(() => {
              if (this.colorCtr1Subscription) {
                this.colorCtr1Subscription.unsubscribe();
              }
              if (this.colorCtr2Subscription) {
                this.colorCtr2Subscription.unsubscribe();
              }
              this.reportImageName = 'Select image (512x512 - Max filesize: 5MB)';
              this._translationService.getTranslation(this.reportImageName)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(response => {
                  if (response?.data?.translations) {
                    this.fileInputRef.nativeElement.value = '';
                    this.imagePreview = null;
                    this.updateReportInformationForm.controls['reportImage'].setValue(response.data.translations[0].translatedText);
                  }
                });
              this.updateReportInformationForm.markAsPristine();
              // Success snackbar
              this._translationService.getTranslationString('Report information updated succesfully.')
                .pipe(take(1))
                .subscribe((translatedString) => {
                  this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                });
              this._fuseProgressBarService.hide();
            })
            .catch((error) => {
              this._translationService.getTranslationString(error)
                .pipe(take(1))
                .subscribe((translatedString) => {
                  this._uiFunctionsService.presentSnackbar(this._snackBar, translatedString, undefined);
                });
              this._fuseProgressBarService.hide();
            });
        }
      });
  }

  // Masked token functions
  maskCustomApiToken(): void {
    if (this.customApiTokenReal.length > 10) {
      this.customApiTokenVisible = this.customApiTokenReal.substring(0, 10) + '*'.repeat(this.customApiTokenReal.length - 10);
    } else {
      this.customApiTokenVisible = this.customApiTokenReal;
    }
  }
  onTokenChange(event: any): void {
    const tokenValue = event.target.value;
    this.customApiTokenVisible = tokenValue;
    this.customApiTokenReal = tokenValue;
    const tokenControl = this.updateAdvancedInformationForm.get('customApiToken');
    tokenControl.setValue(tokenValue);
    tokenControl.markAsDirty();
    tokenControl.markAsTouched();
    tokenControl.updateValueAndValidity();
  }
  showRealToken(): void {
    this.customApiTokenVisible = this.customApiTokenReal;
    this.updateAdvancedInformationForm.get('customApiToken')?.setValue(this.customApiTokenReal);
  }
}
