import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private storageObservable = new Subject<string>();

  constructor() { }

  getStorageObservable() {
    return this.storageObservable.asObservable();
  }

  updateLocalStorage(key: string) {
    // localStorage.setItem(key, JSON.stringify(value));
    this.storageObservable.next(key);
  }

  // updateLocalStorage(key: string, value: any) {
  //   localStorage.setItem(key, JSON.stringify(value));
  //   this.storageObservable.next(key);
  // }
}
