import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScanComponent } from './scan.component';
import { ExitGuard } from '../../guards/exit-guard';

@NgModule({
  imports: [
    FuseSharedModule,
    NgCircleProgressModule.forRoot({
      backgroundOpacity: 1,
      radius: 60,
      space: -12,
      startFromZero: false,
      maxPercent: 100,
      outerStrokeGradient: true,
      outerStrokeWidth: 12,
      outerStrokeColor: '#bf0d57',
      outerStrokeGradientStopColor: '#bf0d57',
      outerStrokeLinecap: 'square',
      innerStrokeColor: '#F5F5F5',
      innerStrokeWidth: 12,
      subtitleColor: '#000000',
      subtitleFontSize: '30',
      subtitleFontWeight: '500',
      animateTitle: false,
      animationDuration: 1000,
      showTitle: false,
      showUnits: false,
      showBackground: false,
      responsive: true,
      showZeroOuterStroke: false,
      lazy: true
    }),
    MatButtonModule,
    MatDialogModule,
    BrowserAnimationsModule
  ],
  declarations: [ScanComponent],
  providers: [ExitGuard]
})
export class ScanModule { }
