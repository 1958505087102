import { Injectable, OnDestroy } from '@angular/core';
import { MyScan } from '../../models/my-scans';
import { Subject, combineLatest, Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { takeUntil } from 'rxjs/operators';
import { OpenbashService } from './../openbash/openbash.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
// import { ScanTypes } from '../../models/scan-types';
import { UiFunctionsService } from '../../services/ui-functions/ui-functions.service';
import { User } from 'app/main/models/users';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService implements OnDestroy {

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {AngularFirestore} _afs
   * @param {OpenbashService} _openbashService
   * @param {FuseProgressBarService} _fuseProgressBarService,
   * @param {UiFunctionsService} _uiFunctionsService
   */
  constructor(
    private _afs: AngularFirestore,
    private _openbashService: OpenbashService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _uiFunctionsService: UiFunctionsService,
    private _firebaseStorage: AngularFireStorage
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /* Setting up user scan in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserScan(id: string, scanner_id: number, url: string, credits: Boolean, shortDescription?: string): Promise<void> {

    let currentScanType: any = null;
    if (credits) {
      currentScanType = {
        shortDescription: shortDescription,
        plan: 'pro'
      }
    } else {
      let currentScanTypeTemp = this._uiFunctionsService.setScanTypeByID(scanner_id);
      currentScanType = {
        shortDescription: currentScanTypeTemp.shortDescription,
        plan: currentScanTypeTemp.versions.find(version => version.id === scanner_id).type
      }
    }

    let currentTask = '';
    let status = '';
    if (scanner_id == 11 || scanner_id == 12) {
      currentTask = 'Pending';
      status = 'pending';
    } else {
      currentTask = 'Syncing';
      status = 'running';
    }
    const scanRef: AngularFirestoreDocument<any> = this._afs.doc(
      `scans/${id}`
    );
    const user = JSON.parse(localStorage.getItem('user')!);
    const scanData: MyScan = {
      scan_uid: id,
      user_uid: user.uid,
      scanner_id: scanner_id,
      current_task: currentTask,
      url: url,
      incidents: {
        low: 0,
        medium: 0,
        high: 0
      },
      init_datetime: Date.now() / 1000,
      type: currentScanType.shortDescription,
      plan: currentScanType.plan,
      status: status,
      progress: '0'
    };
    // console.log(scanData);
    return scanRef.set(scanData, {
      merge: true,
    });
  }

  /* Setting up user credits in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserCredits(creditsQuantity: number): Promise<void> {
    const user = JSON.parse(localStorage.getItem('user')!);
    const userRef: AngularFirestoreDocument<any> = this._afs.doc(`users/${user.uid}`);

    return new Promise((resolve, reject) => {
      this._afs.collection('users').doc(user.uid).get().subscribe((doc: any) => {
        const credits = doc.data().purchasedCredits;
        const userData = {
          uid: user.uid,
          purchasedCredits: credits + creditsQuantity
        };

        userRef.set(userData, {
          merge: true,
        }).then(() => {
          resolve();
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    });
  }

  /* Retrieves scans ids for the current user uid in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  getUserScans(): any {
    const user = JSON.parse(localStorage.getItem('user')!);
    return this._afs.collection('scans', ref => ref.where('user_uid', '==', user.uid)).valueChanges();
  }

  /* Retrieves last scans ids for the current user uid in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  getUserLastScans(): any {
    const user = JSON.parse(localStorage.getItem('user')!);
    return this._afs.collection('scans', ref =>
      ref.where('user_uid', '==', user.uid)
        .orderBy('init_datetime', 'desc')
        .limit(5)
    ).valueChanges();
  }

  /* Retrieves total scans quantity for the current user uid in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  getTotalScans(): any {
    const user = JSON.parse(localStorage.getItem('user')!);
    return this._afs.collection('scans', ref => ref.where('user_uid', '==', user.uid)).get();
  }

  /* Retrieves scans ids for a given user uid in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  hasScansByID(id: string): Promise<boolean> {
    const getUserScansByIDQuery = this._afs.collection('scans', ref => ref.where('user_uid', '==', id)).valueChanges();

    return new Promise((resolve, reject) => {
      getUserScansByIDQuery
        .subscribe((myScans: any) => {
          if (myScans.length !== 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, reject);
    });
  }

  /* Stops the scan if its running and then deletes the scan */
  deleteScan(id: string, status: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (status !== 'finished' && status !== 'stopped') {
        this._openbashService.stopScan(id)
          .pipe(
            takeUntil(this._unsubscribeAll)
          ).subscribe(() => {
            this.deleteFromFirebaseScan(id);
            resolve(true);
          });
      } else {
        this.deleteFromFirebaseScan(id);
        resolve(true);
      }
    });
  }

  /* Deletes the scan in firebase, this fires a trigger with a cloud function onDelete to create a new document in the deletedScans table */
  deleteFromFirebaseScan(id: string): void {
    const scanRef: AngularFirestoreDocument<any> = this._afs.doc(
      `scans/${id}`
    );
    scanRef.delete().then(() => {
      this._fuseProgressBarService.hide();
    });
  }

  /* Retrieves a scan by hash in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  getScanByHash(hash: string): any {
    const user = JSON.parse(localStorage.getItem('user')!);
    return this._afs.collection('scans', ref => ref
      .where('scan_uid', '==', hash)
      .where('user_uid', '==', user.uid)
    ).valueChanges();
  }

  /* Sets the stopped status and task in firebase when stopping a scan */
  stopScan(id: string): Promise<void> {
    const scanRef: AngularFirestoreDocument<any> = this._afs.doc(
      `scans/${id}`
    );
    const scanData: MyScan = {
      current_task: 'Stopped!',
      status: 'stopped'
    };
    return scanRef.set(scanData, {
      merge: true,
    });
  }

  /* Updates a user */
  updateUser(userData: User): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this._afs.doc(
      `users/${userData.uid}`
    );
    return userRef.set(userData, {
      merge: true,
    });
  }

  /* Gets User Custom Data */
  getUserCustomData(id: string): any {
    // return this._afs.collection('users', ref => ref
    //   .where('uid', '==', id)
    // ).valueChanges();
    const userData = this._afs.collection('users', ref => ref
      .where('uid', '==', id)
    ).valueChanges();
    const subscriptionData = this._afs.collection('subscriptions', ref => ref
      .where('userId', '==', id)
    ).valueChanges();
    return combineLatest([userData, subscriptionData]);
  }

  /* Retrieves scan types and data */
  getScanTypesFirebase(): any {
    return this._afs.collection('scanTypes').valueChanges();
  }

  /* Retrieves plans and data */
  getPlansFirebase(): any {
    return this._afs.collection('plans').valueChanges();
  }

  /* Updates user subscription with new data in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  updateSubscription(planID: number): Promise<void> {
    const user = JSON.parse(localStorage.getItem('user')!);
    const today = new Date();
    const rechargeDate = new Date(today);
    rechargeDate.setDate(rechargeDate.getDate() + 31);
    const subscriptionsCollection = this._afs.collection('subscriptions');
    const query = subscriptionsCollection.ref.where('userId', '==', user.uid);

    return new Promise((resolve, reject) => {
      query.get().then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const docSnapshot = querySnapshot.docs[0];
          const subscriptionData = {
            duration: 'Monthly',
            isPaid: true,
            nextRechargeDate: rechargeDate.toISOString(),
            planId: planID,
            startDate: today.toISOString()
          };
          docSnapshot.ref.update(subscriptionData);
          resolve();
        } else {
          console.log('No subscription for current user.');
          reject('No subscription for current user.');
        }
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  }

  /* Setting up user credits in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserSubscriptionCredits(creditsQuantity: number, concurrence: number): Promise<void> {
    const user = JSON.parse(localStorage.getItem('user')!);
    const userRef: AngularFirestoreDocument<any> = this._afs.doc(`users/${user.uid}`);

    return new Promise((resolve, reject) => {
      this._afs.collection('users').doc(user.uid).get().subscribe((doc: any) => {
        // const credits = doc.data().subscriptionCredits;
        const userData = {
          uid: user.uid,
          subscriptionCredits: creditsQuantity,
          max_concurrent: concurrence
        };

        userRef.set(userData, {
          merge: true,
        }).then(() => {
          resolve();
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    });
  }

  // Uploads image to firebase
  uploadImage(uid: string, imageName: string, file: File): Promise<string> {
    const extension = file.name.split('.').pop();
    const path = `images/${uid}/${imageName}.${extension}`;
    const ref = this._firebaseStorage.ref(path);
    const task = ref.put(file);

    return new Promise((resolve, reject) => {
      task.then(() => {
        ref.getDownloadURL().subscribe(url => {
          resolve(url);
        })
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    })
  }
}
