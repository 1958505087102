// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mobile: false,
  rootURL: 'https://www2.openbash.com',
  paypalClientId: 'AR6w_FqBZ0sJU_1YQ-RCm15TtrHyujavq2y-7MkpGc4hbRNfmC_YSDY6PfTK5dP3FuuIO5url0xuNyme',
  reCaptchaKey: '6Lfo4_shAAAAAEgfFkDp8QYe8hMmJrFNEu7LegmQ',
  // reCaptchaKeyAppCheck: '6LeLO6wUAAAAAIQ9IAGk08ANs-aasrOtr1Wqp7Rb',
  firebaseConfig: {
    apiKey: 'AIzaSyCC_kF_2Q4E7X0eSruBriaCf6ywAk8pTeM',
    authDomain: 'openbash-e7742.firebaseapp.com',
    databaseURL: 'https://openbash-e7742-default-rtdb.firebaseio.com',
    projectId: 'openbash-e7742',
    storageBucket: 'openbash-e7742.appspot.com',
    messagingSenderId: '135939823808',
    appId: '1:135939823808:web:e0761313a563464cb23525',
    measurementId: 'G-NS14JT61Z3'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
