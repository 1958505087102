import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FirebaseService } from 'app/main/services/firebase/firebase.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'profile-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SubscriptionComponent implements OnInit {

  user: any = JSON.parse(localStorage.getItem('user')!);
  plans = [];
  currentPlan: any = {};
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
 * Constructor
 *
 * @param {FirebaseService} _firebaseService
 * @param {MatSnackBar} _snackBar
 * 
 */
  constructor(
    private _firebaseService: FirebaseService,
    private _snackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    // Get Plans
    this.getPlans();
  }

  /**
 * On destroy
 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this._snackBar.dismiss();
  }

  // Gets Plans
  getPlans(): void {
    this._firebaseService.getPlansFirebase()
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(plans => {
        this.plans = plans;
        this.currentPlan = this.plans.find(plan => plan.planID === this.user.subscription[0].planId);
      });
  }

}
