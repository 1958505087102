<div id="credits" class="page-layout simple full-width">

  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">

    <div class="hero-text">
      <h1>{{ 'Purchase API Credits' | translate | async }}</h1>
      <h3 class="mt-0">
        {{ 'Find the perfect credit package for your business or personal needs, and enhance your digital security with our
        reliable and efficient scanning services.' | translate | async }}
      </h3>
    </div>

  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content pt-24 pt-sm-24 pt-sm-32" fxLayout="column">

    <div class="credits" fxLayout="row wrap" fxLayoutAlign="center" [@animateStagger]="{value:'50'}">
      <div class="credit" fxFlex="100" fxFlex.gt-xs="50" *ngFor="let option of creditTypesDef[0].options; let i = index"
        fxFlex.gt-sm="33" [@animate]="{value:'*',params:{y:'100%'}}" [ngClass]="{ 'selected': i === selectedPackIndex, 'not-selected': i !== selectedPackIndex }">
        <div class="credit-content" fxLayout="column" fxFlex="1 1 auto">
          <div class="header" fxLayout="row" fxLayoutAlign="center center">
            <div class="pack-title" fxFlex>
              {{option.quantity}}-Credits Pack
            </div>
          </div>
          <div class="content" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 auto">
            <div class="price-container">
              <div class="currency">$</div>
              <div class="price">{{option.totalPrice}}</div>
            </div>
            <div class="quantity"><strong>{{option.quantity}}</strong> {{ 'credits.' | translate | async }}</div>
            <div *ngIf="option.quantity != option.totalPrice" class="free-credits"><strong>{{option.quantity - option.totalPrice}}</strong> {{ 'free credits.' | translate | async }}</div>
            <div *ngIf="option.slug == '40-credits'" class="badge warn">{{ 'BEST OPTION' | translate | async }}</div>
          </div>
          <div class="footer" fxLayout="row" fxLayoutAlign="center center">
            <button mat-button color="accent" (click)="selectPack(option.quantity, option.totalPrice, i)">
              {{ 'SELECT PACK' | translate | async }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <ngx-paypal [config]="payPalConfig" enableStandardCardFields></ngx-paypal>

    <div class="faq" fxLayout="row wrap">
      <div class="title" fxFlex="100">{{ 'Frequently Asked Questions' | translate | async }}</div>

      <div class="item" fxFlex="100" fxFlex.gt-xs="50">
          <div class="question">{{ 'What are credits and how do they work?' | translate | async }}</div>
          <div class="answer">
            {{ 'Credits are a form of virtual currency that you can purchase and use to access our cybersecurity scanning services through our APIs. Each credit is equivalent to $1, and you can choose from a variety of credit packages to fit your needs and budget. Simply purchase the desired number of credits, and then use them to pay for the API calls associated with our scanning services.' | translate | async }}
          </div>
      </div>

      <div class="item" fxFlex="100" fxFlex.gt-xs="50">
          <div class="question">{{ 'How do I purchase credits?' | translate | async }}</div>
          <div class="answer">
            {{ 'To purchase credits, visit our "Purchase Credits" page, select the credit package that best suits your needs, and complete the checkout process using your preferred payment method.' | translate | async }}
          </div>
      </div>

      <div class="item" fxFlex="100" fxFlex.gt-xs="50">
          <div class="question">{{ 'Can I get a discount when purchasing credits?' | translate | async }}</div>
          <div class="answer">
            {{ 'Yes, we offer volume discounts and bonus credits when purchasing larger credit packages. The more credits you buy, the more you save. The exact discount and bonus structure can be found on our "Purchase Credits" page.' | translate | async }}
          </div>
      </div>

      <div class="item" fxFlex="100" fxFlex.gt-xs="50">
          <div class="question">{{ 'Do credits expire?' | translate | async }}</div>
          <div class="answer">
            {{ 'Our credits do not have an expiration date. You can use them at any time to access our security scanning services.' | translate | async }}
          </div>
      </div>

      <div class="item-link" fxFlex="100">
          <a class="nav-link" routerLink="/faq">{{ 'View all' | translate | async }}</a>
      </div>
  </div>

  </div>
  <!-- / CONTENT -->

</div>