import { Languages } from "app/main/models/languages";

export const languagesDef: Languages[] = [
    {
        id   : 'en',
        title: 'English',
        flag : 'us'
    },
    {
        id   : 'es',
        title: 'Español',
        flag : 'es'
    },
    {
        id   : 'pt',
        title: 'Português',
        flag : 'br'
    }
];
