<div id="api-clients" class="page-layout simple full-width">

  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">

    <div class="hero-text">
      <h1>{{ 'API Clients' | translate | async }}</h1>
      <h3 class="mt-0">
        {{ 'To access and consume our cybersecurity scanning services through our API, we offer two convenient options: a
        plugin for Burp Suite and a Telegram bot. Both options require you to configure your API key and have sufficient
        API credits in your account.' | translate | async }}
      </h3>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content" fxLayout="column">
    <div class="option" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center center">
      <div class="container text-container" fxFlex="50%" fxFlex.xs="100%" fxLayoutAlign="center center">
        <div class="first-column">
          <h3 class="mat-headline"><b>{{ 'Option 1: Burp Suite Plugin' | translate | async }}</b></h3>
          <p class="mat-subheading-2">
            {{ 'Our custom plugin for Burp Suite allows you to seamlessly integrate our cybersecurity scanning services into
            your existing workflow.' | translate | async }}
          </p>
          <p class="mat-subheading-2">
            <b>{{ 'Direct integration:' | translate | async }}</b> {{ 'The plugin integrates directly into Burp Suite, a widely-used tool by security
            professionals, making it easy to access our scanning services without switching platforms.' | translate | async }}<br>
            <b>{{ 'Efficient workflow:' | translate | async }}</b> {{ 'By using the plugin in Burp Suite, users can maintain an efficient workflow while
            performing their security tasks without having to switch between different tools.' | translate | async }}<br>
            <b>{{ 'Consistency in the working environment:' | translate | async }}</b> {{ 'Using Burp Suite as the primary platform allows users to
            maintain
            consistency in their working environment and leverage existing features and capabilities of Burp Suite.' | translate | async }}
          </p>
          <p class="mat-subheading-2">
            {{ 'To use the plugin, follow these steps:' | translate | async }}
          </p>
          <ol class="mat-subheading-2">
            <li>
              {{ 'Download the plugin from our GitHub repository:' | translate | async }} <a target="{{mobile == true ? '_self' : '_blank'}}"
                href="https://github.com/lostsurface/openBurp">https://github.com/lostsurface/openBurp</a>
            </li>
            <li>
              {{ 'Install the plugin in your Burp Suite instance.' | translate | async }}
            </li>
            <li>{{ 'Configure the plugin with your API key, which can be found in your account settings.' | translate | async }}</li>
            <li>{{ 'Ensure you have enough API credits in your account to perform the desired scans.' | translate | async }}</li>
          </ol>
        </div>
      </div>

      <div class="container image-container" fxFlex="50%" fxFlex.xs="100%">
        <img class="sample-report-img burpsuite" src="./assets/images/burpsuite.png">
      </div>
    </div>

    <div id="option-2" class="option" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
      fxLayoutAlign="center center">
      <div class="container image-container" fxFlex="50%" fxFlex.xs="100%">
        <img class="sample-report-img telegram" src="./assets/images/telegram.png">
      </div>

      <div class="container text-container" fxFlex="50%" fxFlex.xs="100%" fxLayoutAlign="center center">
        <div class="first-column">
          <h3 class="mat-headline"><b>{{ 'Option 2: Telegram Bot' | translate | async }}</b></h3>
          <p class="mat-subheading-2">
            {{ 'Our public Telegram bot, named "lostsurface_bot", offers another convenient way to access our cybersecurity
            scanning services through our API.' | translate | async }}
          </p>
          <p class="mat-subheading-2">
            <b>{{ 'Accessibility:' | translate | async }}</b> {{ 'The Telegram bot enables users to access cybersecurity scanning services from any
            device with the Telegram app installed, increasing accessibility and convenience.' | translate | async }}<br>
            <b>{{ 'Ease of use:' | translate | async }}</b> {{ 'Users can interact with the Telegram bot through an easy-to-use chat interface and
            simple commands, making it easy to communicate with the API.' | translate | async }}<br>
            <b>{{ 'No additional software required:' | translate | async }}</b> {{ 'By using Telegram, a popular messaging app, users do not need to
            install or configure additional software to access scanning services.' | translate | async }}
          </p>
          <p class="mat-subheading-2">
            {{ 'To use the bot, follow these steps:' | translate | async }}
          </p>
          <ol class="mat-subheading-2">
            <li>
              {{ 'Search for the "lostsurface_bot" in the Telegram app and start a conversation with the bot.' | translate | async }}
            </li>
            <li>
              {{ 'Provide the bot with your API key, which can be found in your account settings. The bot will use this key
              to authenticate your requests.' | translate | async }}
            </li>
            <li>{{ 'Ensure you have enough API credits in your account to perform the desired scans.' | translate | async }}</li>
            <li>{{ 'Once you have completed these steps, you can use the Telegram bot to interact with our scanning
              services. The bot will automatically deduct the corresponding number of API credits from your account
              balance for each scan performed. This option allows for increased accessibility and convenience, as it can
              be used from any device with the Telegram app installed.' | translate | async }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>