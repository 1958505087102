<div id="price-tables" class="page-layout simple fullwidth">
    <!-- HEADER -->
    <div class="header" fxLayout="row" fxLayoutAlign="start start">
        <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>
            <div class="h1">Start Now!</div>
            <div class="h3">
                The most advanced pentest tools with a simple and affordable price. And you can always try a demo for
                free!
            </div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" fxLayout="column">
        <!-- PRICE TABLES -->
        <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="center center" [@animateStagger]="{value:'50'}">
            <div *ngFor="let plan of plans, let i = index" class="price-table style-3 mat-elevation-z4"
                [ngClass]="{ 'selected': plan.planID === selectedPlan, 'not-selected': plan.planID !== selectedPlan }"
                fxLayout="column" [@animate]="{value:'*',params:{y:'100%'}}">
                <div *ngIf="plan.planID == 1" class="badge warn">FREE</div>
                <div *ngIf="currentSubscription(plan.planID)" class="badge accent">ACTIVE SUBSCRIPTION</div>
                <div class="package-type">
                    <div class="title">{{plan.title}}</div>
                    <div class="subtitle">{{plan.subtitle}}</div>
                </div>
                <div class="price" [ngClass]="plan.cssPriceClass" fxLayout="row" fxLayoutAlign="center start">
                    <div class="currency">{{plan.currencyText}}</div>
                    <div fxLayout="row" fxLayoutAlign="center end">
                        <div class="value">{{plan.monthlyValue}}</div>
                        <div class="period">monthly</div>
                    </div>
                </div>
                <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                    <ng-container *ngFor="let feature of plan.features">
                        <div class="term">{{feature}}</div>
                    </ng-container>
                </div>
                <button *ngIf="plan.planID != 1" mat-raised-button class="cta-button" color="accent"
                    (click)="selectPlan(plan.planID)"
                    [disabled]="plan.planID === selectedPlan || currentSubscription(plan.planID)">SELECT</button>
                <!-- <button *ngIf="plan.planID === selectedPlan && plan.planID === 1" mat-raised-button class="cta-button get-plan-button" color="accent" (click)="getStarterPlan()">GET PLAN</button> -->
                <ngx-paypal [config]="payPalConfig" enableStandardCardFields
                    *ngIf="plan.planID === selectedPlan && plan.planID != 1"></ngx-paypal>
                <div class="note">{{plan.activationText}}</div>
            </div>
        </div>
        <!-- / PRICE TABLES -->

        <div class="faq" fxLayout="row wrap" *ngIf="loadedPlans">
            <div class="title" fxFlex="100">Frequently Asked Questions</div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">How does free trial work?</div>
                <div class="answer">
                    Just like the paid version. Enter the target you want to analyze, select the scan type and, when
                    choosing the plan, select the free trial option. Best of all, you don´t need to be registered.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">Can I cancel any time?</div>
                <div class="answer">
                    In general, there is nothing to cancel. You pay per use. In an enterprise plan, you can always
                    cancel the service at any time.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">What are the limitations of the free trial?</div>
                <div class="answer">
                    Only ports 80 and 443 are scanned. Surface scan is limited to 5
                    subdomains. The discovery dictionary is 100 words long. No email
                    leaked credentials.
                </div>
            </div>

            <div class="item" fxFlex="100" fxFlex.gt-xs="50">
                <div class="question">What if the scanner fails to scan?</div>
                <div class="answer">
                    You must contact us supplying the ID of the scanner that failed. We
                    will manually verify the problem. We will refund the money if
                    applicable.
                </div>
            </div>

            <div class="item-link" fxFlex="100">
                <a class="nav-link" routerLink="/faq">View all</a>
            </div>
        </div>
    </div>
    <!-- / CONTENT -->
</div>