import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationCacheService {
  private cache = new Map<string, Map<string, BehaviorSubject<string>>>();

  getTranslation(lang: string, text: string): BehaviorSubject<string> | null {
    return this.cache.has(lang) && this.cache.get(lang)!.has(text)
      ? this.cache.get(lang)!.get(text)!
      : null;
  }

  setTranslation(lang: string, text: string, translation: string): void {
    if (!this.cache.has(lang)) {
      this.cache.set(lang, new Map<string, BehaviorSubject<string>>());
    }

    if (!this.cache.get(lang)!.has(text)) {
      this.cache.get(lang)!.set(text, new BehaviorSubject<string>(translation));
    } else {
      this.cache.get(lang)!.get(text)!.next(translation);
    }
  }

  getCachedOrFetchTranslation(lang: string, text: string, fetchFn: () => Observable<string>): Observable<string> {
    const cachedTranslation = this.getTranslation(lang, text);
    
    if (cachedTranslation) {
      // Hacer que el BehaviorSubject emita nuevamente el valor cacheado, incluso si ya está en caché
      cachedTranslation.next(cachedTranslation.value);
      return cachedTranslation.asObservable();
    }

    return fetchFn().pipe(
      tap(translation => this.setTranslation(lang, text, translation))
    );
  }
}
