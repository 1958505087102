import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FuseSharedModule } from '@fuse/shared.module';
import { PricingStyle3Component } from './pricing.component';
import { NgxPayPalModule } from 'ngx-paypal';

@NgModule({
    declarations: [
        PricingStyle3Component
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        FuseSharedModule,
        NgxPayPalModule
    ]
})
export class PricingModule
{
}
