import { NgModule } from '@angular/core';
import { NoLoginDialogComponent } from './no-login-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  imports: [
    MatDialogModule,
    MatButtonModule,
    FuseSharedModule,
    MatToolbarModule,
    MatIconModule
  ],
  declarations: [NoLoginDialogComponent]
})
export class NoLoginDialogModule { }
