<div class="block-ui-template">
  <img class="loading-logo" src="../../../../assets/images/circle-progress-charging.gif">
  <!-- Material Design Spinner -->
  <!-- <div class="spinner-wrapper">
    <div class="spinner">
      <div class="inner">
        <div class="gap"></div>
        <div class="left">
          <div class="half-circle"></div>
        </div>
        <p>asdasdasdasdasd</p>
        <div class="right">
          <div class="half-circle"></div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- / Material Design Spinner -->
</div>