<div id="mail-confirm" fxLayout="column">

    <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="mail-confirm-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <mat-icon class="mat-accent s-96">email</mat-icon>
            </div>

            <div class="title">{{ 'Confirm your email address!' | translate | async }}</div>

            <div class="subtitle">
                <p>
                    {{ 'A confirmation e-mail has been sent to' | translate | async }} <b>{{email}}</b>.
                </p>
                <p>
                    {{ 'Check your inbox and click on the "Confirm my email" link to confirm your email address.' | translate | async }}
                </p>
            </div>

            <div class="message">
                <a class="link" routerLink="/login">{{ 'Go back to login page' | translate | async }}</a>
            </div>

        </div>

    </div>

</div>