<div id="personal-information" class="p-24" fxLayout="row wrap">

  <div class="personal-information-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">

    <!-- Personal Information -->
    <div class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">{{ 'Personal Information' | translate | async }}</div>
      </header>
      <div class="content">
        <form name="updatePersonalInformationForm" [formGroup]="updatePersonalInformationForm" novalidate
          (ngSubmit)="submitUpdatePersonalInformationForm()" fxLayout="column" fxFlex="100">
          <div class="personal-information-container" fxLayout="row" fxFlex="100">
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">{{ 'Display Name' | translate | async }}</div>
                <mat-form-field color="accent" floatLabel="never">
                  <input matInput formControlName="displayName"
                    placeholder="{{ 'Please enter display name' | translate | async }}">
                  <mat-error *ngIf="updatePersonalInformationForm.get('displayName').hasError('required')">
                    {{ 'Display Name is required' | translate | async }}
                  </mat-error>
                  <mat-error *ngIf="updatePersonalInformationForm.get('displayName').hasError('pattern')">
                    {{ 'No double blank spaces or special characters allowed' | translate | async }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="info-line">
                <div class="title">{{ 'Email' | translate | async }}</div>
                <mat-form-field color="accent">
                  <input matInput value="{{user.email}}" disabled>
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">{{ 'Telegram Nickname (Optional)' | translate | async }}</div>
                <mat-form-field color="accent" floatLabel="never" subscriptSizing="dynamic">
                  <input matInput formControlName="telegramNick"
                    placeholder="{{ 'Please enter Telegram Nick' | translate | async }}">
                  <mat-error *ngIf="updatePersonalInformationForm.get('telegramNick').hasError('pattern')">
                    {{ 'Telegram usernames can only contain letters, numbers, and underscores (_), and must be between 5
                    and 32 characters long. They cannot start with a number' | translate | async }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="info-line">
                <div class="title">{{ 'Phone (Optional)' | translate | async }}</div>
                <mat-form-field color="accent" floatLabel="never">
                  <input matInput formControlName="phone" placeholder="{{ 'Please enter phone' | translate | async }}">
                  <mat-error *ngIf="updatePersonalInformationForm.get('phone').hasError('pattern')">
                    {{ 'No blank spaces, letters or special characters allowed' | translate | async }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="buttons-container personal-information">
            <button mat-raised-button color="accent" class="submit-button mr-16" aria-label="SAVE"
              [disabled]="updatePersonalInformationForm.invalid || !updatePersonalInformationForm.dirty">
              {{ 'UPDATE' | translate | async }}
            </button>
            <button type="button" mat-raised-button class="password-reset" color="accent" aria-label="RESET PASSWORD"
              *ngIf="checkProvider()" (click)="resetPassword()">
              {{ 'RESET PASSWORD' | translate | async }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Fin Personal Information -->

    <!-- Company Information -->
    <div class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">{{ 'Company Information' | translate | async }}</div>
      </header>
      <div class="content">
        <form name="updateCompanyInformationForm" [formGroup]="updateCompanyInformationForm" novalidate
          (ngSubmit)="submitUpdateCompanyInformationForm()" fxLayout="column" fxFlex="100">
          <div class="personal-information-container" fxLayout="row" fxFlex="100">
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">{{ 'Company Name (Optional)' | translate | async }}</div>
                <mat-form-field color="accent" floatLabel="never">
                  <input matInput formControlName="companyName"
                    placeholder="{{ 'Please enter company name' | translate | async }}">
                  <mat-error *ngIf="updateCompanyInformationForm.get('companyName').hasError('pattern')">
                    {{ 'No double blank spaces or special characters allowed' | translate | async }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">{{ 'Company Country (Optional)' | translate | async }}</div>
                <mat-form-field color="accent" floatLabel="never">
                  <mat-select formControlName="companyCountry"
                    placeholder="{{ 'Please enter company country' | translate | async }}">
                    <mat-option *ngFor="let country of countryList" [value]="country.code">
                      {{ country.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="personal-information-container" fxLayout="row" fxFlex="100">
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">{{ 'Company URL (Optional)' | translate | async }}</div>
                <mat-form-field color="accent" floatLabel="never">
                  <input matInput formControlName="companyURL"
                    placeholder="{{ 'Please enter company url' | translate | async }}">
                  <mat-error *ngIf="updateCompanyInformationForm.get('companyURL').hasError('pattern')">
                    {{ 'API endpoint must be in URL format (https://***.***)(http://***.***)' | translate | async }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">{{ 'Company Address (Optional)' | translate | async }}</div>
                <mat-form-field color="accent" floatLabel="never">
                  <input matInput formControlName="companyAddress"
                    placeholder="{{ 'Please enter company address' | translate | async }}">
                  <mat-error *ngIf="updateCompanyInformationForm.get('companyAddress').hasError('pattern')">
                    {{ 'No double blank spaces or special characters allowed' | translate | async }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="buttons-container">
            <button mat-raised-button color="accent" class="submit-button mr-16" aria-label="SAVE"
              [disabled]="!updateCompanyInformationForm.dirty || updateCompanyInformationForm.invalid">
              {{ 'UPDATE' | translate | async }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Fin Company Information -->

    <!-- Report Information -->
    <div class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">{{ 'Report Information' | translate | async }}</div>
      </header>
      <div class="content">
        <form name="updateReportInformationForm" [formGroup]="updateReportInformationForm" novalidate fxLayout="column"
          fxFlex="100">
          <div fxLayout="row" fxFlex="100" fxLayoutGap="16px" class="colors-row" fxLayoutAlign="center end"
            fxLayoutAlign.xs="center normal" fxLayoutAlign.sm="center normal">
            <div fxFlex="100" fxLayoutAlign="center end">
              <img width="70" class="image-preview" [src]="imagePreview || user.reportPhotoURL"
                *ngIf="(imagePreview || user.reportPhotoURL) != null && (imagePreview || user.reportPhotoURL) != ''">
              <div class="info-line" fxFlex="100">
                <div class="title">{{ 'Company Logo (Optional)' | translate | async }}</div>
                <mat-form-field color="accent">
                  <button mat-icon-button matPrefix (click)="fileInput.click()">
                    <mat-icon>attach_file</mat-icon>
                  </button>
                  <input type="text" readonly matInput formControlName="reportImage" />
                  <input type="file" hidden #fileInput accept="image/png, image/jpeg"
                    (change)="onFileSelected(fileInput.files[0])" />
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="100">
              <div class="info-line">
                <div class="title">{{ 'Report Language (Optional)' | translate | async }}</div>
                <mat-form-field color="accent" floatLabel="never">
                  <mat-select formControlName="reportLanguage"
                    placeholder="{{ 'Please enter report language' | translate | async }}">
                    <mat-option *ngFor="let reportLanguage of reportLanguages" [value]="reportLanguage.code">
                      {{ reportLanguage.language | translate | async }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutGap="16px" class="colors-row">
            <div class="info-line" fxFlex="100">
              <div class="title">{{ 'Primary Color (Optional)' | translate | async }}</div>
              <mat-form-field color="accent" floatLabel="never">
                <input matInput [ngxMatColorPicker]="picker1" [formControl]="colorCtr1" formControlName="reportColor1"
                  [readonly]="true" placeholder="{{ 'Please select color' | translate | async }}">
                <ngx-mat-color-toggle matPrefix [for]="picker1"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #picker1 touchUi="{{mobile == true ? true : false}}"></ngx-mat-color-picker>
              </mat-form-field>
            </div>
            <div class="info-line" fxFlex="100">
              <div class="title">{{ 'Secondary Color (Optional)' | translate | async }}</div>
              <mat-form-field color="accent" floatLabel="never">
                <input matInput [ngxMatColorPicker]="picker2" [formControl]="colorCtr2" formControlName="reportColor2"
                  [readonly]="true" placeholder="{{ 'Please select color' | translate | async }}">
                <ngx-mat-color-toggle matPrefix [for]="picker2"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #picker2 touchUi="{{mobile == true ? true : false}}"></ngx-mat-color-picker>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="column" fxFlex="100" class="report-header-preview-row"
            *ngIf="colorCtr1.value != null && colorCtr2.value != null">
            <p class="preview-text">{{ 'Report Header Preview' | translate | async }}</p>
            <div class="report-header-preview" fxFlex="100" [ngStyle]="gradientStyle">
              <h4 class="card-title">Executive Report</h4>
              <p class="card-category">Findings and recommendations.</p>
            </div>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutGap="16px" class="report-info-row">
            <div class="info-line mb-0" fxFlex="100">
              <div class="title">{{ 'Default Authentication (Optional)' | translate | async }}</div>
              <mat-form-field color="accent" floatLabel="never">
                <mat-label>{{ 'Please select authentication type' | translate | async }}</mat-label>
                <mat-select formControlName="defaultAuth">
                  <mat-option value="private">{{ 'Private' | translate | async }}</mat-option>
                  <mat-option value="public">{{ 'Public' | translate | async }}</mat-option>
                  <mat-option value="password">{{ 'Password' | translate | async }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="info-line" fxFlex="100">
              <mat-checkbox formControlName="newScanEnabled">
                {{ 'New Scan Enabled' | translate | async }}
              </mat-checkbox>
            </div>
          </div>
          <div class="buttons-container personal-information">
            <button mat-raised-button color="accent" class="submit-button mr-16" aria-label="SAVE"
              [disabled]="updateReportInformationForm.invalid || !updateReportInformationForm.dirty"
              (click)="submitUpdateReportInformationForm(fileInput.files[0])">
              {{ 'UPDATE' | translate | async }}
            </button>
            <button type="button" mat-raised-button class="restore-defaults" color="accent"
              aria-label="RESTORE DEFAULTS" (click)="restoreDefaults()">
              {{ 'RESTORE DEFAULTS' | translate | async }}
            </button>
          </div>

          <div class="buttons-container">
          </div>
        </form>
      </div>
    </div>
    <!-- Fin Report Information -->

    <!-- Advanced Information -->
    <div class="profile-box info-box general" fxLayout="column">
      <header class="accent">
        <div class="title">{{ 'Advanced Information' | translate | async }}</div>
      </header>
      <div class="content">
        <form name="updateAdvancedInformationForm" [formGroup]="updateAdvancedInformationForm" novalidate
          (ngSubmit)="submitUpdateAdvancedInformationForm()" fxLayout="column" fxFlex="100">
          <div class="advanced-information-container" fxLayout="column" fxFlex="100">
            <div class="info-line">
              <div class="title">{{ 'API Endpoint Callback (Optional)' | translate | async }}</div>
              <mat-form-field color="accent" floatLabel="never">
                <input matInput formControlName="apiEndpoint"
                  placeholder="{{ 'Please enter custom API endpoint' | translate | async }}">
                <mat-error *ngIf="updateAdvancedInformationForm.get('apiEndpoint').hasError('pattern')">
                  {{ 'API endpoint must be in URL format (https://***.***)(http://***.***)' | translate | async }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="info-line">
              <div class="title">{{ 'Custom API Token' | translate | async }}</div>
              <mat-form-field color="accent" floatLabel="never">
                <input matInput formControlName="customApiToken" [value]="customApiTokenVisible"
                  (input)="onTokenChange($event)" (focus)="showRealToken()" (blur)="maskCustomApiToken()"
                  placeholder="{{ 'Please enter custom API token' | translate | async }}">
                <mat-error *ngIf="updateAdvancedInformationForm.get('customApiToken').invalid">
                  {{ 'Bearer API Tokens are usually Base64-encoded and may include letters (A-Z, a-z), numbers (0-9),
                  and special characters (- _ . ~ + / =)' | translate | async }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="info-line notifications" fxLayout="column">
              <div class="title">{{ 'Notifications (Optional)' | translate | async }}</div>
              <div fxLayout="row" fxLayoutGap="16px" class="first-row">
                <div fxLayout="column">
                  <mat-checkbox formControlName="emailNotifications">
                    {{ 'Email Notifications' | translate | async }}
                  </mat-checkbox>
                  <mat-checkbox formControlName="telegramNotifications">
                    {{ 'Telegram Notifications' | translate | async }}
                  </mat-checkbox>
                </div>
                <div fxLayout="column">
                  <mat-checkbox formControlName="whatsappNotifications">
                    {{ 'Whatsapp Notifications' | translate | async }}
                  </mat-checkbox>
                  <mat-checkbox formControlName="apiNotifications">
                    {{ 'API Notifications' | translate | async }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-container">
            <button mat-raised-button color="accent" class="submit-button mr-16" aria-label="SAVE"
              [disabled]="updateAdvancedInformationForm.invalid || !updateAdvancedInformationForm.dirty">
              {{ 'UPDATE' | translate | async }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Fin Advanced Information -->
  </div>

  <div class="personal-information-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">
    <!-- Account Information -->
    <div class="profile-box info-box account-info" fxLayout="column">
      <header class="accent">
        <div class="title">{{ 'Account Information' | translate | async }}</div>
      </header>
      <div class="content">
        <div class="info-line">
          <div class="title">{{ 'Current Subscription Plan' | translate | async }} - <a routerLink="/plans"
              class="inline">{{ 'Upgrade' | translate | async }}</a></div>
          <div class="info">{{ currentPlan }}</div>
        </div>
        <div class="info-line">
          <div class="title">{{ 'Credits' | translate | async }} - <a routerLink="/credits" class="inline">{{ 'Purchase
              API Credits' | translate | async }}</a></div>
          <div class="info">{{user.purchasedCredits + user.subscriptionCredits}}</div>
        </div>
        <div class="info-line">
          <div class="title">Api Key</div>
          <div class="info">{{ user.apiToken | truncate:10 }}<mat-icon
              (click)="copyApiKey(user.apiToken)">file_copy</mat-icon><mat-icon
              (click)="regenerateApiKey()">refresh</mat-icon></div>
        </div>
        <div class="info-line">
          <div class="title">{{ 'Total Scans' | translate | async }}</div>
          <div class="info">{{totalScans}}</div>
        </div>
        <div class="info-line">
          <div class="title">{{ 'Last Login' | translate | async }}</div>
          <div class="info">{{user.lastLoginAt | date:'h:mm:ss a - MMM d, YYYY'}}</div>
        </div>
        <div class="info-line">
          <div class="title">{{ 'Auth Providers' | translate | async }}</div>
          <a class="info" target="{{mobile == true ? '_self' : '_blank'}}" *ngFor="let provider of user.providerData"
            href="{{provider.providerId == 'password' ? 'http://openbash.com' : 'http://'+provider.providerId}}">{{provider.providerId
            == 'password' ? 'openbash.com' : provider.providerId}}</a>
        </div>
        <div class="info-line">
          <div class="title">{{ 'Created At' | translate | async }}</div>
          <div class="info">{{user.createdAt | date:'MMM d, YYYY'}}</div>
        </div>
      </div>
    </div>
    <!-- Fin Account Information -->


    <!-- Recent Scans -->
    <div class="profile-box groups" fxLayout="column">
      <header class="accent" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">{{ 'Last Scans' | translate | async }}</div>
        <div class="more secondary-text">
          <a routerLink="/my-scans" target="{{mobile == true ? '_self' : '_blank'}}" class="view-all">{{ 'View all...' |
            translate | async }}</a>
        </div>
      </header>
      <div class="content">
        <div *ngIf="myScans.length == 0">{{ 'No scans have been performed. To perform scans and get results, please go
          to' | translate | async }}
          <a routerLink="/new-scan">{{ 'NEW SCAN' | translate | async }}</a>.
        </div>
        <div class="group" *ngFor="let myScan of myScans.slice(0, 5)" fxLayout="row"
          fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <!-- <div class="logo"><img [src]="myScan.image ? myScan.image : '/assets/images/openbash-white.svg'"></div> -->
            <div class="logo"><img src="/assets/images/openbash-white.svg"></div>
            <div>
              <div class="name">{{myScan.url}}</div>
              <div class="category">{{myScan.type}}</div>
              <div class="members">{{+myScan.init_datetime*1000 | date:'MMM d'}}</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-icon *ngIf="myScan.status == 'finished'" class="active-icon green-600 s-16">check</mat-icon>
            <mat-icon *ngIf="myScan.status == 'stopped'" class="active-icon red-500 s-16">close</mat-icon>
            <mat-icon *ngIf="myScan.status != 'finished' && myScan.status != 'stopped'"
              class="active-icon amber-500 s-16">
              hourglass_empty
            </mat-icon>
            <mat-menu class="recent-scans-menu" #menu="matMenu" xPosition="before">
              <a class="mat-button" [routerLink]="'/scan/' + myScan.scan_uid + '/'"
                target="{{mobile == true ? '_self' : '_blank'}}" mat-menu-item>{{ 'View' | translate | async }}</a>
              <button mat-menu-item (click)="deleteScan($event, myScan.scan_uid, myScan.status)">{{ 'Delete' | translate
                | async }}</button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Recent Scans -->
  </div>
</div>