<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>

        <p class="copyright-text">&#169; {{ getCurrentYear() }}, openbash.com</p>

        <div class="socials-container" fxLayout="row" fxLayoutAlign="start center">
            <a href="https://github.com/opnbash" target="{{mobile == true ? '_self' : '_blank'}}"><img class="brand-icon"
                    src="./assets/icons/brands-icons/github.svg"></a>
            <a href="https://twitter.com/opnbash" target="{{mobile == true ? '_self' : '_blank'}}"><img class="brand-icon"
                    src="./assets/icons/brands-icons/twitter.svg"></a>
        </div>

        <div id="dummy-div" fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
        </div>

    </div>

</mat-toolbar>