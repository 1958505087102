<div id="home-search" class="page-layout simple fullwidth">

  <!-- CONTENT -->
  <div class="content" fxLayout="column">

    <!-- ROW 1  - SEARCH-->
    <div id="home-search-row-1" fxLayout="column" fxLayoutAlign="center center">
      <div class="container" fxLayout="column" fxLayoutAlign="center center">
        <h1 class="home-title mat-display-3">{{ 'Offensive Security Scanner' | translate | async }}</h1>
        <h3 class="home-subtitle mat-headline">
          {{ 'Discover hidden attack vectors.' | translate | async }}
        </h3>
        <!-- <div class="search" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon s-24">security</mat-icon>
          <input #searchinput [(ngModel)]="searchInput" placeholder="https://example.com" fxFlex
            (keyup.enter)="startScan()" />
        </div> -->
        <!-- <button mat-raised-button class="cta-button" color="accent" (click)="startScan()" (keyup.enter)="startScan()">
          Start Scan
        </button> -->
        <div class="mt-48 desc fuse-card">
          <div class="line-height-1.75 h3">
            {{ 'Web applications have become the weakest part of IT security. OpenBash built cloud-based Penetration Testing
            Tools provides a convenient way to handle website penetration testing. It is a cloud-based service that
            provides automated crawling and testing of custom web applications to identify vulnerabilities. See your
            business as attackers see it.' | translate | async }}
          </div>
          <!-- <p class="desc-links">
            <a href="#"><span class="text-nowrap">Sample Report</span></a>
            <span class="m-12">|</span>
            <a href="#"><span class="text-nowrap">Use Cases</span></a>
            <span class="m-12">|</span>
            <a href="#"><span class="text-nowrap">Technical Details</span></a>
          </p> -->
        </div>
        <!-- <a class="back-link">Go back to dashboard</a> -->
      </div>
    </div>
    <!-- / ROW 1 - SEARCH-->

    <!-- ROW 2 - SAMPLE REPORT-->
    <div id="home-search-row-2" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center center">
      <div class="container text-container" fxFlex="50%" fxFlex.xs="100%" fxLayoutAlign="center center">
        <div>
          <h3 class="mat-headline"><b>{{ 'Live Interactive Report' | translate | async }}</b></h3>
          <p class="mat-subheading-2">
            {{ 'Unique features:' | translate | async }}
          </p>
          <ul class="mat-subheading-2">
            <li>
              <b>{{ 'Node explorer:' | translate | async }}</b> {{ 'Clearly visualize the technology used and explore it.' | translate | async }}
            </li>
            <li>
              <b>{{ 'Command line Helper:' | translate | async }}</b> {{ 'Explore beyond the report.' | translate | async }}
            </li>
            <li><b>{{ 'Advanced statistics:' | translate | async }}</b> {{ 'A view of the target´s main characteristics.' | translate | async }}</li>
            <li><b>{{ 'Screenshot Discovery:' | translate | async }}</b> {{ 'Visual display of the findings.' | translate | async }}</li>
          </ul>
          <p class="mat-subheading-2">
            <b><a class="custom-link" href="/reports/e9b3b9b393f6/" target="{{mobile == true ? '_self' : '_blank'}}">{{ 'View Demo Report' | translate | async }}</a></b>
          </p>
        </div>
      </div>

      <div class="container image-container">
        <img class="sample-report-img" src="./assets/images/sample-report.png" lightbox [simpleMode]="true"
          backgroundOpacity="0.7" (click)="dismissSnackbar()" />
      </div>
    </div>
    <!-- / ROW 2 - SAMPLE REPORT-->

    <!-- ROW 3 - SCAN OPTIONS -->
    <div id="home-search-row-3" fxLayout="column" fxLayoutAlign="center center">
      <div class="container" fxLayout="column" fxLayoutAlign="center center">
        <!-- HEADER -->
        <div class="header" fxLayout="row" fxLayoutAlign="start start">
          <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>
            <div class="h1">{{ 'Security Scans: From Surface to Core' | translate | async }}</div>
            <div class="h3">
              {{ 'From quick checks to deep analysis, our scans are tailored to meet the evolving challenges of modern
              security.' | translate | async }}
            </div>
          </div>
        </div>
        <!-- / HEADER -->
        <!-- <div class="scans" fxLayout="row wrap" fxLayoutAlign="center" [@animateStagger]="{value:'50'}"> -->
        <div class="scans" fxLayout="row wrap" fxLayoutAlign="center" [@animateStagger]="{value:'50'}">
          <!-- <div *ngFor="let scanType of scanTypes" class="scan" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33"
            [@animate]="{value:'*',params:{y:'100%'}}" routerLink="/new-scan"> -->
          <div *ngFor="let scanType of scanTypes | slice:0:6" class="scan" fxFlex="100" fxFlex.gt-xs="50"
            fxFlex.gt-sm="33">
            <div class="scan-content" fxLayout="column" fxFlex="1 1 auto" [@animate]="{value:'*',params:{y:'100%'}}">
              <div class="padding-content">
                <img class="scan-image" src="{{scanType.LogoIMG}}">
                <p class="mat-subheading-2">{{scanType.Name}}</p>
                <p class="description">{{ scanType.ShortDescription | translate | async }}</p>
              </div>
              <div class="footer-container">
                <div class="scan-footer">
                  <p class="scan-price">{{scanType.PriceCredits}} {{ 'credits' | translate | async }}</p>
                  <a *ngIf="mobile == false" class="scan-more-info" (click)="goToURL($event, scanType.MoreInfoURL)">+
                    {{ 'More
                    Info' | translate | async }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a *ngIf="loadedScans" routerlink="/new-scan" class="nav-link view-all" ng-reflect-router-link="/new-scan"
        href="/new-scan">{{ 'View all scans' | translate | async }}</a>
      </div>
    </div>
    <!-- / ROW 3 - SCAN OPTIONS -->


    <!-- ROW 4  - PRICING-->
    <div id="home-search-row-4" fxLayout="column" fxLayoutAlign="center center">
      <div class="container" fxLayout="column" fxLayoutAlign="center center">
        <!-- HEADER -->
        <div class="header" fxLayout="row" fxLayoutAlign="start start">
          <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>
            <div class="h1">{{ 'Check out our plans!' | translate | async }}</div>
            <div class="h3">
              {{ 'From Starter to Red Team, each plan offers a unique credit allocation. Harness the full power of our
              platform, tailored to your usage.' | translate | async }}
            </div>
          </div>
        </div>
        <!-- / HEADER -->
        <div fxLayout="column">
          <!-- PRICE TABLES -->
          <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="center center" [@animateStagger]="{value:'50'}">
            <div *ngFor="let plan of plans | slice:0:4, let i = index" class="price-table style-3"
              [ngClass]="plan.cssElevationClass" fxLayout="column" [@animate]="{value:'*',params:{y:'100%'}}">
              <div *ngIf="plan.planID == 1" class="badge warn">FREE</div>
              <div class="package-type">
                <div class="title">{{plan.title}}</div>
                <div class="subtitle">{{plan.subtitle}}</div>
              </div>
              <div class="price" [ngClass]="plan.cssPriceClass" fxLayout="row" fxLayoutAlign="center start">
                <div class="currency">{{ plan.currencyText | translate | async }}</div>
                <div fxLayout="row" fxLayoutAlign="center end">
                  <div class="value">{{plan.monthlyValue}}</div>
                  <div class="period">{{ 'monthly' | translate | async }}</div>
                </div>
              </div>
              <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                <ng-container *ngFor="let feature of plan.features">
                  <div class="term">{{ feature | translate | async }}</div>
                </ng-container>
              </div>
              <!-- <button mat-raised-button class="cta-button" color="accent"
                (click)="selectScan(plan.planID)" disabled>SELECT</button> -->
              <div class="note">{{ plan.activationText | translate | async }}</div>
            </div>
          </div>
          <!-- / PRICE TABLES -->
          <a *ngIf="loadedPlans" routerlink="/plans" class="nav-link view-all" ng-reflect-router-link="/plans"
            href="/plans">{{ 'View all plans' | translate | async }}</a>
        </div>
      </div>
    </div>
    <!-- / ROW 4 - PRICING -->

    <!-- ROW 4  - PRICING-->
    <!-- <div id="home-search-row-4" fxLayout="column" fxLayoutAlign="center center">
      <div class="container" fxLayout="column" fxLayoutAlign="center center"> -->
    <!-- HEADER -->
    <!-- <div class="header" fxLayout="row" fxLayoutAlign="start start">
          <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>
            <div class="h1">Start Now!</div>
            <div class="h3">
              The most advanced pentest tools with a simple and affordable price. And you can always try a demo for free!
            </div>
          </div> -->
    <!-- </div> -->
    <!-- / HEADER -->
    <!-- <div fxLayout="column"> -->
    <!-- PRICE TABLES -->
    <!-- <div class="price-tables" fxLayout="row wrap" fxLayoutAlign="center center">
            <div *ngFor="let scanTypeDef of scanTypesDef, let i = index" class="price-table style-3"
              [ngClass]="scanTypeDef.cssElevationClass" fxLayout="column">
              <div *ngIf="scanTypeDef.price.hasFreeVersion" class="badge warn">FREE TRIAL</div>
              <div class="package-type">
                <div class="title">{{scanTypeDef.title}}</div>
                <div class="subtitle">{{scanTypeDef.subtitle}}</div>
              </div>
              <div class="price" [ngClass]="scanTypeDef.price.cssClass" fxLayout="row" fxLayoutAlign="center start">
                <ng-container *ngIf="i == 0 || i == 1">
                  <div class="value">Free</div>
                </ng-container>
                <ng-container *ngIf="i != 0 && i != 1">
                  <div class="currency">{{scanTypeDef.price.currencyText}}</div>
                  <div fxLayout="row" fxLayoutAlign="center end">
                    <div class="value">{{scanTypeDef.price.value}}</div>
                    <div class="period">{{scanTypeDef.price.period}}</div>
                  </div>
                </ng-container>
              </div>
              <div class="terms" fxLayout="column" fxLayoutAlign="start center">
                <ng-container *ngFor="let version of scanTypeDef.versions">
                  <ng-container *ngIf="version.type == 'pro'">
                    <ng-container *ngFor="let feature of version.features; first as isFirst">
                      <div *ngIf="!isFirst" class="term">{{feature}}</div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="version.type != 'pro'">
                    <ng-container *ngFor="let feature of version.features">
                      <div class="term">{{feature}}</div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
              <button *ngIf="scanTypeDef.slug != 'enterprise'" mat-raised-button class="cta-button" color="accent"
                (click)="selectScan(scanTypeDef.slug)">SELECT</button>
              <a *ngIf="scanTypeDef.slug == 'enterprise'" href="mailto:support@openbash.zendesk.com" mat-raised-button
                class="cta-button" color="accent">CONTACT SALES</a>
              <div class="note">{{scanTypeDef.activationText}}</div>
            </div>
          </div> -->
    <!-- / PRICE TABLES -->
    <!-- </div>
      </div>
    </div> -->
    <!-- / ROW 4 - PRICING -->

  </div>
  <!-- / CONTENT -->

</div>