import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-stop-scan-dialog',
  templateUrl: './stop-scan-dialog.component.html',
  styleUrls: ['./stop-scan-dialog.component.scss']
})
export class StopScanDialogComponent {

  /**
   * Constructor
   * @param {MatDialogRef<StopScanDialogComponent>} _dialogRef
   */
  constructor(
    private _dialogRef: MatDialogRef<StopScanDialogComponent>
  ) { }

  // Dialog confirm action
  onYesClick(): void {
    this._dialogRef.close(true);
  }

  // Dialog cancel action
  onCancelClick(): void {
    this._dialogRef.close(false);
  }
}

